
import React from 'react';
import { Card, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { overlayStyleCircle, overlayStyleCircleIcon, circleText, } from './styles';
import WebAssets from "../Assets/WebAssets";
const { icon1, icon2, icon3, icon4 } = WebAssets;

export const ProgressSteps = ({ circleStyles }) => {

    return (
        <>

            <div class="circle" style={{ alignSelf: 'center', justifySelf: 'center', height: '111px' }}>
                <div class="circle" style={{ height: '65px', minWidth: isMobile ? '85vw' : '25vw', display: 'flex', justifyContent: 'space-around' }}>

                    <Card sx={circleStyles} style={overlayStyleCircle}>
                        <img src={icon1} id='icon1' style={overlayStyleCircleIcon} />
                    </Card>

                    <Card sx={circleStyles} style={overlayStyleCircle}>
                        <img src={icon2} id='icon2' style={{ filter: 'grayscale(1)', ...overlayStyleCircleIcon }} />
                    </Card>

                    <Card sx={circleStyles} style={overlayStyleCircle}>
                        <img src={icon3} id='icon3' style={{ filter: 'grayscale(1)', ...overlayStyleCircleIcon }} />
                    </Card>

                    <Card sx={circleStyles} style={overlayStyleCircle}>
                        <img src={icon4} id='icon4' style={{ filter: 'grayscale(1)', ...overlayStyleCircleIcon }} />
                    </Card>
                </div>

                <div class="circleText" style={{ height: '45px', minWidth: '25vw', display: 'flex', justifyContent: 'space-around' }}>
                    <div>
                        <Typography variant="h1" gutterBottom style={circleText}> Paso 1</Typography>
                    </ div>
                    <div>
                        <Typography variant="h1" gutterBottom style={circleText}> Paso 2</Typography>
                    </ div>
                    <div>
                        <Typography variant="h1" gutterBottom style={circleText}> Paso 3</Typography>
                    </ div>
                    <div>
                        <Typography variant="h1" gutterBottom style={circleText}> Paso 4</Typography>
                    </ div>

                </div>

                <div class="circleLines" style={{ height: '45px', minWidth: '23vw', position: 'relative', top: '-100px', display: 'flex', justifyContent: 'center' }}>

                    <hr id='icon_line_1'
                        style={{
                            borderTop: '5px solid white',
                            minWidth: '25%',
                            opacity: 'unset',
                            zIndex: '1',
                        }}
                    />
                    <hr id='icon_line_2'
                        style={{
                            borderTop: '5px solid white',
                            minWidth: '25%',
                            opacity: 'unset',
                            zIndex: '1',
                        }}
                    />
                    <hr id='icon_line_3'
                        style={{
                            borderTop: '5px solid white',
                            minWidth: '25%',
                            opacity: 'unset',
                            zIndex: '1',
                        }}
                    />
                </div>
            </div>

        </>
    );
}

export const ProgressStepsAr = ({ circleStyles }) => {

    return (
        <>

            <div class="circle" style={{ alignSelf: 'center', justifySelf: 'center', height: '111px' }}>
                <div class="circle" style={{ height: '65px', minWidth: isMobile ? '85vw' : '25vw', display: 'flex', justifyContent: 'space-around' }}>

                    <Card sx={circleStyles} style={overlayStyleCircle}>
                        <img src={icon1} id='icon1' style={overlayStyleCircleIcon} />
                    </Card>

                    <Card sx={circleStyles} style={overlayStyleCircle}>
                        <img src={icon2} id='icon2' style={{ filter: 'grayscale(1)', ...overlayStyleCircleIcon }} />
                    </Card>

                    <Card sx={circleStyles} style={overlayStyleCircle}>
                        <img src={icon4} id='icon3' style={{ filter: 'grayscale(1)', ...overlayStyleCircleIcon }} />
                    </Card>
                </div>

                <div class="circleText" style={{ height: '45px', minWidth: '25vw', display: 'flex', justifyContent: 'space-around' }}>
                    <div>
                        <Typography variant="h1" gutterBottom style={circleText}> Paso 1</Typography>
                    </ div>
                    <div>
                        <Typography variant="h1" gutterBottom style={circleText}> Paso 2</Typography>
                    </ div>
                    <div>
                        <Typography variant="h1" gutterBottom style={circleText}> Paso 3</Typography>
                    </ div>

                </div>

                <div class="circleLines" style={{ height: '45px', minWidth: '23vw', position: 'relative', top: '-100px', display: 'flex', justifyContent: 'center' }}>

                    <hr id='icon_line_1'
                        style={{
                            borderTop: '5px solid white',
                            minWidth: '33%',
                            opacity: 'unset',
                            zIndex: '1',
                        }}
                    />
                    <hr id='icon_line_2'
                        style={{
                            borderTop: '5px solid white',
                            minWidth: '33%',
                            opacity: 'unset',
                            zIndex: '1',
                        }}
                    />
                </div>
            </div>

        </>
    );
}

export const ProgressVoucher = ({ circleStyles }) => {

    return (
        <>

            <div class="circle" style={{ alignSelf: 'center', justifySelf: 'center', height: '111px' }}>
                <div class="circle" style={{ height: '65px', minWidth: isMobile ? '85vw' : '25vw', display: 'flex', justifyContent: 'space-around' }}>

                    <Card sx={circleStyles} style={overlayStyleCircle}>
                        <img src={icon1} id='icon1' style={overlayStyleCircleIcon} />
                    </Card>

                    <Card sx={circleStyles} style={overlayStyleCircle}>
                        <img src={icon4} id='icon2' style={{ filter: 'grayscale(1)', ...overlayStyleCircleIcon }} />
                    </Card>
                </div>

                <div class="circleText" style={{ height: '45px', minWidth: '25vw', display: 'flex', justifyContent: 'space-around' }}>
                    <div>
                        <Typography variant="h1" gutterBottom style={circleText}> Paso 1</Typography>
                    </ div>
                    <div>
                        <Typography variant="h1" gutterBottom style={circleText}> Paso 2</Typography>
                    </ div>
                </div>

                <div class="circleLines" style={{ height: '45px', minWidth: '23vw', position: 'relative', top: '-100px', display: 'flex', justifyContent: 'center' }}>

                    <hr id='icon_line_1'
                        style={{
                            borderTop: '5px solid white',
                            minWidth: '40%',
                            opacity: 'unset',
                            zIndex: '1',
                        }}
                    />
                </div>
            </div>

        </>
    );
}







