import React from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';


function ModalHandler({ setModalDeleteUser, data, setUsrs }) {
    const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`, } };
    const onSubmit = e => {
        e.preventDefault();
        axios.delete(`${process.env.REACT_APP_API_URL}/v1/auth/users/${data.id}/`, config).then((response) => {
            toast.success('Deleted from the Database successfully', { theme: 'dark' });
            axios.get(`${process.env.REACT_APP_API_URL}/v1/auth/users/`, config).then((re) => {
                setUsrs(re.data);
                setModalDeleteUser(false);
            });
        }).catch((error) => {
            console.error(error);
        });
    };

    return (
        <>
            <Modal show={true} onHide={() => setModalDeleteUser(false)}>
                <Modal.Header closeButton> <span>DELETE USER</span> </Modal.Header>
                <Modal.Body>

                    <h3>You want to delete this User?</h3>
                    <h3> {data.email} </h3>
                </Modal.Body>

                <Modal.Footer className="footer">
                    <Button variant="secondary" onClick={() => setModalDeleteUser(false)} id="cancelBtn"> Cancel </Button>
                    <Button variant="primary" style={{ backgroundColor: 'red' }} onClick={e => onSubmit(e)}> Delete </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalHandler;
