import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

import Modal from '../../Components/Modal/Modals/Modal';
import { LoadingOverlay } from '../Common/Common';

import { Card, Col, Row, Button } from 'react-bootstrap';
import { AiOutlineEdit, AiOutlinePlusSquare, AiOutlineDelete } from 'react-icons/ai';
import { Box, Paper, TextField } from "@mui/material";


function Providers() {
	const [isLoading, setIsLoading] = useState(false);
	const [dataInfo, setDataInfo] = useState([]);
	const [modalAdd, setModalAdd] = useState(false);
	const [modalEdit, setModalEdit] = useState(false);
	const [modalDelete, setModalDelete] = useState(false);
	const [idSelected, setIdSelected] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');


	const config = {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `JWT ${localStorage.getItem('access')}`,
		}
	};

	const config2 = {
		headers: {
			'Content-Type': 'multipart/form-data',
			'Authorization': `JWT ${localStorage.getItem('access')}`,
		}
	};

	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
			try {
				const [paymentProvidersRes] = await Promise.all(
					[axios.get(`${process.env.REACT_APP_API_URL}/v1/payment-methods/payment-providers/`, config),]
				);
				setDataInfo(paymentProvidersRes.data);
				await delay(500);
				setIsLoading(false);
			}
			catch (error) {
				console.log(error);
				toast.error(`There is no available data to show at the moment`, { theme: 'dark' });
				await delay(500);
				setIsLoading(false);
			}
		};
		fetchData();
	}, [setDataInfo]);

	const SaveData = (formData) => {
		setIsLoading(true)
		axios
			.post(`${process.env.REACT_APP_API_URL}/v1/payment-methods/payment-providers/`, formData, config2)
			.then((res) => {
				if (res.status >= 400) {
					toast.error(`Server did not respond: Error ${res.status}`, { theme: 'dark' });
				} else {
					axios
						.get(`${process.env.REACT_APP_API_URL}/v1/payment-methods/payment-providers/`, config)
						.then((resp) => {
							setDataInfo(resp.data);
							setModalAdd(false);
							setIsLoading(false);
							toast.success(`${formData.customer_name} added to the database successfully`, { theme: 'dark' });
						});
				}
			}
			)
			.catch((err) => {
				console.log(err);
				setModalAdd(false);
				setIsLoading(false);
			});
	};

	const updateData = (formData) => {

		setIsLoading(true);

		const data = {
			'customer_name': formData.customer_name,
			'customer_description': formData.customer_description,
			'image': formData.image,
			'active': formData.active,
		}

		axios
			.patch(`${process.env.REACT_APP_API_URL}/v1/payment-methods/payment-providers/${formData.customer_id}/`, data, config2)
			.then(res => {
				if (res.status >= 400) {
					toast.error(`Server did not respond: Error ${res.status}`, { theme: 'dark' });
				} else {
					axios.get(`${process.env.REACT_APP_API_URL}/v1/payment-methods/payment-providers/`, config)
						.then(resp => {
							setDataInfo(resp.data);
							setModalEdit(false);
							setIsLoading(false);
							toast.success(`${data.customer_name} Updated successfully`, { theme: 'dark' });
						});
				}

			})
			.catch(err => {
				console.log(err);
				setModalEdit(false);
				setIsLoading(false);
			});
	};

	const deleteData = (formData) => {

		fetch(`${process.env.REACT_APP_API_URL}/v1/payment-methods/payment-providers/` + formData.customer_id + '/', {
			method: "DELETE",
			headers: new Headers({ 'Authorization': `JWT ${localStorage.getItem('access')}` })
		})
			.then(res => {
				if (res.status >= 400) {
					toast.error(`Server did not respond : Error ${res.status}`, { theme: 'dark', });
				} else {
					axios
						.get(`${process.env.REACT_APP_API_URL}/v1/payment-methods/payment-providers/`, config)
						.then(resp => {
							setDataInfo(resp.data)
							setModalDelete(false);
							toast.success(`The Customer ${formData.customer_name} has been removed from the Database!`, { theme: "dark" });
						});
				}
			})
			.catch(err => {
				console.log(err)
				setModalDelete(false);
			});
	}

	const onEdit = (data) => {
		setIdSelected(data);
		setModalEdit(true);
	};

	const onDelete = (data) => {
		setIdSelected(data);
		setModalDelete(true);
	};

	const handleSearch = (event) => {
		setSearchTerm(event.target.value);
	};

	const filteredDataInfo = dataInfo.filter((data) =>
		data.customer_name.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const createFields = {
		0: {
			FormField: {
				fieldName: 'customer_name',
				fieldLabel: 'Provider Name',
			}
		},
		1: {
			FormField: {
				fieldName: 'customer_description',
				fieldLabel: 'Provider Description',
				typeField: 'as',
				typeValue: 'textarea',
				rowsQ: 5,
			}
		},
		2: {
			FormImage: {
			}
		},
		3: {
			FormActive: {
			}
		},
	};

	const formConfig = {
		formTitle: 'Create Provider',
		setModal: setModalAdd,
		setInfoData: setDataInfo,
		SaveData: SaveData,
		createFields: createFields
	};

	const removeFields = (indexToRemove, data) => {
		const copy = { ...data };

		if (copy[indexToRemove]) {
			delete copy[indexToRemove];
		}

		const updatedFields = {};
		let newIndex = 0;

		Object.keys(copy).forEach((oldIndex) => {
			if (copy[oldIndex]) {
				updatedFields[newIndex++] = copy[oldIndex];
			}
		});

		return updatedFields
	};

	const formConfigEdit = {
		formTitle: 'Edit Provider',
		setModal: setModalEdit,
		setInfoData: setDataInfo,
		SaveData: updateData,
		initialData: idSelected,
		// createFields: removeFields( 2, createFields ),
		createFields: createFields,
	};

	const formConfigDelete = {
		formTitle: 'Delete Provider',
		setModal: setModalDelete,
		setInfoData: setDataInfo,
		SaveData: deleteData,
		initialData: idSelected,
		method: 'DELETE'
	};

	return (
		<>
			{modalAdd && <Modal formConfig={formConfig} />}
			{modalEdit && <Modal formConfig={formConfigEdit} />}
			{modalDelete && <Modal formConfig={formConfigDelete} />}

			<style>{`.MuiInputBase-root {max-height: 46px;}`}</style>
			<style>{`.icon:hover {color: blue;cursor: pointer;}`}</style>

			{isLoading && <LoadingOverlay />}

			<Box sx={{ overflowX: 'hidden' }}>
				<Row style={{ height: 'calc(100vh - 90px)' }}>
					<Col lg={12} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
						<Row xs={1} md={10} lg={10} style={{ position: 'sticky', top: '0', zIndex: 2, marginBottom: '10px' }}>

							<Paper style={{ marginBottom: '10px' }}>
								<Row className="p-3 align-items-center">

									<Col lg={8}>
										<h2 className="text-center" style={{ marginTop: '0.5rem' }}>
											{`${process.env.REACT_APP_WEB_NAME}`} PROVIDERS
										</h2>
									</Col>

									<Col lg={4} className="text-end">
										<TextField style={{ marginRight: '10px' }} value={searchTerm} onChange={handleSearch} placeholder="Find..." />
										<Button variant="outline-success" size="md" onClick={() => setModalAdd(true)}>
											<AiOutlinePlusSquare className="icon" size="2em" />
										</Button>
									</Col>

								</Row>
							</Paper>
						</Row>

						<div className="scroll-container" style={{ overflowY: 'auto', flex: 1, margin: '15px', overflowX: 'hidden' }}>
							<Row xs={1} md={4} className="g-3 p-1">
								{filteredDataInfo?.map((data) => (
									<Col className="align-self-stretch" key={data.customer_id} style={{ paddingLeft: '-0.5rem' }}>
										<Card className="d-flex h-100">
											<div className={`pb-2 w-100 rounded-top ${data.active === false ? 'bg-danger' : 'bg-success'}`} />
											<Card.Header>
												<Row className="text-center">

													<Col lg={6} xs={6} className="border-end">
														<AiOutlineDelete className="icon" onClick={() => onDelete(data)} />
													</Col>

													<Col lg={6} xs={6}>
														<AiOutlineEdit className="icon" onClick={() => onEdit(data)} />
													</Col>

												</Row>
											</Card.Header>

											<Link className="text-decoration-none" to={String(data.customer_id)}>
												<Card.Img
													className="rounded mx-auto p-2"
													style={{ display: 'block', height: '170px', objectFit: 'cover' }}
													thumbnail
													// src={data.image}
													src={data.image}
												/>
											</Link>

											<Card.Body className="d-flex flex-column">
												<Link className="text-decoration-none" to={String(data.customer_id)}>
													<Card.Title className="mb-auto p-2 bd-highlight text-center">{data.customer_name}</Card.Title>
												</Link>
												<Card.Text className="mb-auto p-2 bd-highlight text-center">
													<small className="text-muted">{data.customer_description}</small>
												</Card.Text>
											</Card.Body>

											<Card.Footer className="text-center">
												<small className="text-muted">{new Date(data.created_date).toDateString()}</small>
											</Card.Footer>

										</Card>
									</Col>
								))}
							</Row>
						</div>
					</Col>
				</Row>
			</Box>
		</>
	);
}

export default Providers;
