
import React, { useState } from 'react';
import { TextField, Grid } from '@mui/material';
import { mw50, mw100, mw75 } from '../Common/styles';
import { isMobile } from 'react-device-detect';
import { EmailButton, VocuherButton } from '../Common/EmailButton';



import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Switch from 'react-bootstrap/Switch'

import { toast } from 'react-toastify';
import { Form, Button } from 'react-bootstrap';

const FormField = ({ typeField = 'type', typeValue = 'text', fieldLabel, fieldName, fieldValue, onChange, error }) => {
    const dynamicAttributes = {
        [typeField]: (
            (fieldName === 'amount' || fieldName === 'docNumber') ? 'number' : (
                (fieldName === 'phone') ? 'number' : (
                    (fieldName === 'email') ? 'email' : typeValue)
                )
            ),
        placeholder: fieldLabel,
        name: fieldName,
        value: fieldValue,
        required: true,
        onChange
    };

    if (fieldName === 'active') {
        return (
            <Form.Group controlId="Switchcontrol" className="mb-3" key={fieldName}>
                <Form.Label>{fieldLabel}</Form.Label>
                <br />
                <Switch checked={fieldValue} onChange={onChange} />
            </Form.Group>
        );
    }

    return (
        <Form.Group className="mb-3" key={fieldName} style={{ textAlign: 'left' }}>
            <Form.Label style={{ fontSize: '12px' }}>{fieldLabel}</Form.Label>
            <Form.Control {...dynamicAttributes} isInvalid={!!error} />
            <Form.Control.Feedback type="invalid">
                {error}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

const ReturnForm = ({ DictConf }) => {

    const handleChange = (fieldName) => (e) => {
        const value = e.target.value;
        if (fieldName === 'amount') {
            DictConf.setFormData({ ...DictConf.formData, [e.target.name]: parseFloat(value).toFixed(2) });
        } else {
            DictConf.setFormData({ ...DictConf.formData, [fieldName]: value });
        }
        const error = DictConf.validateField(fieldName, value);
        DictConf.setErrors({ ...DictConf.errors, [fieldName]: error });
    };

    const labels = {
        amount: "Monto a Pagar",
        firstName: "Nombre",
        lastName: "Apellido",
        docNumber: "Número de Documento / CUIT",
        email: "Email",
        phone: "Teléfono",
        address: "Dirección"
    };

    const entries = Object.entries(DictConf.formData);

    return (
        <Form>
            <Row>
                {entries.map(([key, value], index) => (
                    <Col md={6} key={index}>
                        <FormField
                            fieldLabel={labels[key]}
                            fieldName={key}
                            fieldValue={value}
                            onChange={handleChange(key)}
                            error={DictConf.errors[key]}
                        />
                    </Col>
                ))}
            </Row>
        </Form>
    );
};




export const EmailField = ({ DictConf }) => {

    const handleAmountChange = (e) => {
        let value = e.target.value;
        if (value) {
            // value = parseFloat(value).toFixed(2);
            console.log(parseFloat(value).toFixed(2))
        };
        DictConf.setFormData({ ...DictConf.formData, [e.target.name]: e.target.value });
    };

    const handleFormDataChange = (e) => {
        DictConf.setFormData({ ...DictConf.formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {/* {isMobile ? false :
                <Grid item xs={6} style={{ ...mw50, textAlign: 'center' }} spacing={3}>
                    <div>
                        <Typography variant="h2" gutterBottom style={subTitleStyle}> INSTRUCCIONES DE PAGO </Typography>

                        <DialogContent style={{ padding: '5px 0px' }}>
                            <ol>
                                <li><strong>Ingresa el monto a pagar.</strong></li>
                                <li><strong>Recibirás un Código {DictConf.formData.paymentMethod == 'qr' ? 'QR de MERCADO PAGO, con el cual deberás realizar el pago en tu Aplicación Móvil.' : `de ${DictConf?.formData?.paymentMethod?.toUpperCase()}, con el que deberás acercarte a un Punto de Pago.`}</strong></li>
                                <li><strong>Una vez confirmado el pago, la información del eVoucher se encontrará disponible.</strong></li>
                            </ol>
                        </DialogContent>
                    </div>
                </Grid>
            } */}

            <Grid item xs={12} style={{ ...mw100, textAlign: 'center', alignSelf: 'center', minWidth: isMobile ? '100%' : '' }} spacing={3}>

                {/* <Grid item xs={12} style={{ maxWidth: isMobile ? '100%' : '99%', textAlign: 'center' }} spacing={3}>
                    <div style={{ marginBottom: '15px' }}>
                        <TextField type='number' label="Ingresa el Monto a Pagar" name="amount" value={DictConf.formData.amount} step="1.00" onChange={handleAmountChange} fullWidth error={false} /></div>
                </Grid> */}

                <ReturnForm DictConf={DictConf} />


                < EmailButton DictConf={DictConf} methodPayment={DictConf.methodPayment} />

                {/* {isMobile ?

                    <div>
                        <Typography variant="h2" gutterBottom style={subTitleStyle}> INSTRUCCIONES DE PAGO </Typography>

                        <DialogContent style={{ padding: '5px 0px' }}>
                            <ol>
                                <li><strong>Ingresa el monto a pagar.</strong></li>
                                <li><strong>Recibirás un Código {DictConf.formData.paymentMethod == 'qr' ? 'QR de MERCADO PAGO, con el cual deberás realizar el pago en tu Aplicación Móvil.' : `de ${DictConf?.formData?.paymentMethod?.toUpperCase()}, con el que deberás acercarte a un Punto de Pago.`}</strong></li>
                                <li><strong>Una vez confirmado el pago, la información del eVoucher se encontrará disponible.</strong></li>
                            </ol>
                        </DialogContent>
                    </div>

                    : false
                } */}

            </Grid>
        </>
    );

};



export const VoucherField = ({ DictConf }) => {
    let operationId

    if (window.location.pathname.split('/')[window.location.pathname.split('/').length - 2] !== 'formpgar') {
        operationId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
    }
    return (
        <>
            <Grid item xs={12} style={{ ...mw100, textAlign: 'center', alignSelf: 'center', minWidth: isMobile ? '100%' : '' }} spacing={3}>

                <Grid item xs={12} style={{ textAlign: 'center', display: isMobile ? '' : 'flex', gap: '4%', justifyContent: 'center' }} spacing={3}>

                    <Grid item xs={6} style={{ maxWidth: isMobile ? '100%' : '50%', textAlign: 'center' }} spacing={3}>
                        <div style={{ marginBottom: '15px' }}><TextField type="text" label="Ingresa Tu Nº de eVoucher" name="operation_id" value={operationId ? operationId : DictConf.formData.operation_id} onChange={DictConf.onChange} fullWidth required /></div>
                    </Grid>

                </Grid>
                < VocuherButton DictConf={DictConf} methodPayment={DictConf.formData.paymentMethod} />

            </Grid>
        </>
    );

};



