import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { verify } from '../../Actions/auth';

const Activate = ({ verify }) => {

    const params = useParams();

    const [verified, setVerified] = useState(false);

    const verify_account = e => {
        const uid = params.uid;
        const token = params.token;

        verify(uid, token);
        setVerified(true);
    };

    if (verified) {
        return <Navigate to='/' />
    }

    return (

        <section className='mt-4'>
            <div className="px-4 py-5 px-md-5 text-center text-lg-start" style={{ backgroundColor: 'white' }}>
                <div className="container">
                    <div className="row gx-lg-5 align-items-center">

                        <div className="col-lg-7 mb-5 mb-lg-0">
                            <h1 className="my-5 display-3 fw-bold ls-tight" style={{ color: '#4f4f4f', textAlign: 'center' }}>
                                Verify your Account <br />
                                <span style={{ color: '#316bf4' }}>To start using EasyPayBit</span>
                            </h1>
                        </div>

                        <div className="col-lg-5 mb-5 mb-lg-0">
                            <div className="card">
                                <div className="card-body py-5 px-md-5">
                                    <button
                                        onClick={verify_account}
                                        style={{ marginTop: '50px' }}
                                        // style={{ width: '100%' }}
                                        type='button'
                                        className='btn btn-primary'
                                    >
                                        Verify Account
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </section>

    );
};

export default connect(null, { verify })(Activate);

