import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FileCopy from '@mui/icons-material/FileCopy'
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import {Modal, Form, Button} from 'react-bootstrap';
import {
    Button as Button2, InputAdornment, Grid, Paper,
    Table, TableBody, TableHead, TableRow, TextField, Autocomplete
} from "@mui/material";

import { CenteredTableCell, ScrollableTableContainer } from '../../../Containers/Common/Common';

const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`, } };

const ModalDisplayLinks = ({ links, modalIsOpen, closeModal }) => {
    useEffect(() => {
        const modalLocation = document.querySelector(".modal-display-links");
        if (modalLocation) {
            const locationChild = modalLocation.firstChild;
            const siblingElement = modalLocation.previousElementSibling;
            if (locationChild) {
                locationChild.style.minWidth = "50vw";
            }
            if (siblingElement) {
                siblingElement.style.zIndex = "1055";
            }
        }
    }, [modalIsOpen]);

    const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value = text; document.body.appendChild(textArea); textArea.focus(); textArea.select(); try { document.execCommand('copy') } catch (err) { console.error('Unable to copy to clipboard', err) } document.body.removeChild(textArea) };

    const copyToClipboard = (e) => {
        const textField = e.target.name;
        unsecuredCopyToClipboard(textField)

        if (textField) {
            toast.success(`Link Copied`, { theme: 'dark' });
        }
    };

    return (

        <Modal
            show={modalIsOpen}
            onHide={closeModal}
            centered
            className='modal-display-links'
        >
            <Modal.Header closeButton>
                Available Links for Merchant
            </Modal.Header>

            <Modal.Body className='p-4'>
                <ScrollableTableContainer component={Paper} style={{ maxHeight: '55vh', boxShadow: 'none' }}>
                    {links.map(item => (
                        <Grid xs={12} spacing={3} style={{ marginBottom: '20px' }} >
                            <TextField
                                type="string"
                                // value={`Provider: ${item.customer_project} - Amount: ${item.amount} - ${item.link.substring(0, 35) + '...'}`}
                                value={`Amount: ${item.amount} - ${item.link.substring(0, 40) + '...'}`}
                                fullWidth
                                required
                                disabled
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button2
                                                variant="outlined"
                                                size="small"
                                                name={item.link}
                                                onClick={copyToClipboard}
                                                startIcon={<FileCopy />}
                                            >
                                                Copy Payment Link
                                            </Button2>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                        </Grid>
                    ))}
                </ScrollableTableContainer>
            </Modal.Body>
        </Modal>
    );
};

const FormField = ({ typeField = 'type', typeValue = 'text', fieldLabel, fieldName, formData, handleInputChange }) => {
    const dynamicAttributes = {
        [typeField]: typeValue,
        placeholder: fieldLabel,
        name: fieldName,
        value: formData[fieldName] || '',
        onChange: (e) => handleInputChange(fieldName, e.target.value),
        required: true,
    };

    return (
        <Form.Group className="mb-3" key={fieldName}>
            <Form.Label>{fieldLabel}</Form.Label>
            <Form.Control {...dynamicAttributes} />
        </Form.Group>
    );
};

const FormImage = ({ fieldLabel = 'image', fieldName = 'image', handleInputChange }) => {
    const dynamicAttributes = {
        type: 'file',
        placeholder: fieldLabel,
        name: fieldName,
        onChange: (e) => { handleInputChange(fieldName, e.target.files[0]) },
    };

    return (
        < Form.Group controlId="formFile" className="mb-3" key={fieldName}>
            <Form.Label>{fieldLabel}</Form.Label>
            <Form.Control {...dynamicAttributes} />
        </Form.Group >
    )
};

const FormActive = ({ fieldName = 'active', formData, handleInputChange }) => {

    const [activeState, setActive] = useState(formData[fieldName]);

    useEffect(() => {

        if (formData[fieldName] === undefined) {
            setActive(true);
            formData[fieldName] = true
        }
        else {
            setActive(formData[fieldName]);
        }

    }, [formData, fieldName]);


    const onSetActive = () => {
        setActive(false);
        handleInputChange(fieldName, !activeState)
        toast.success(`User Activated`, { theme: 'dark' });
    };

    const onSetInactive = () => {
        setActive(true);
        handleInputChange(fieldName, !activeState)
        toast.success(`User Deactivated`, { theme: 'dark' });
    };

    return (
        <>
            <Form.Label>{fieldName}</Form.Label>
            <div className="form-check form-switch form-switch-md">
                {activeState !== false ? (
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value={activeState}
                        onChange={onSetActive}
                        checked
                    />
                ) : (
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value={activeState}
                        onChange={onSetInactive}
                    />
                )}
            </div>
        </>
    )
};

const FormActiveTransaction = ({ fieldName = 'finalStatus', formData, handleInputChange, formConfig }) => {

    const [activeState, setActive] = useState((formData[fieldName] === 'OK') ? true : false);

    useEffect(() => {

        // if (formData[fieldName] === undefined || formData[fieldName] === 'NOK') {
        //     setActive(false);
        //     formData[fieldName] = 'NOK'
        // }
        if (formData[fieldName] === 'OK') {
            setActive(true);
            formData[fieldName] = 'OK'
        }
        else {
            setActive(false);
            formData[fieldName] = 'NOK'
        }
    }, [formData, handleInputChange]);

    const onSetActive = () => {
        setActive(true);
        handleInputChange(formData, 'OK', true)
        toast.success(`Transaction Status Changed to OK`, { theme: 'dark' });
    };

    const onSetInactive = () => {
        setActive(false);
        handleInputChange(formData, 'NOK', false)
        toast.warning(`Transaction Status Changed to NOK`, { theme: 'dark' });
    };

    return (
        <>
            <Form.Label>{fieldName}</Form.Label>
            <div className="form-check form-switch form-switch-md">
                {activeState !== false ? (
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value={activeState}
                        onChange={onSetInactive}
                        checked
                    />
                ) : (
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value={activeState}
                        onChange={onSetActive}
                    />
                )}
            </div>
        </>
    )
};

const ButtonsCreate = ({ handleClose, onSubmit, method = null }) => {

    if (method === "DELETE") {

        return (
            <>
                <Modal.Footer className="footer text-center" style={{ display: 'block' }}>
                    <Button variant='secondary' style={{ width: '30%' }} id="cancelBtn"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>

                    <Button variant="danger" style={{ width: '30%' }}
                        onClick={onSubmit}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </>
        )
    }

    if (method === "LINKS") {

        return (
            <>
                <Modal.Footer className="footer text-center" style={{ display: 'block' }}>
                    <Button variant='secondary' style={{ width: '30%' }} id="cancelBtn"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>

                    <Button variant="primary" style={{ width: '30%' }}
                        onClick={onSubmit}
                    >
                        Create Link
                    </Button>
                </Modal.Footer>
            </>
        )
    }

    return (
        <>
            <Modal.Footer className="footer text-center" >
                <Button variant="secondary" onClick={handleClose} id="cancelBtn">
                    Cancel
                </Button>
                <Button variant="primary" onClick={onSubmit}>
                    Save Record
                </Button>
            </Modal.Footer>
        </>
    )
};

const UploadBar = ({ uploadProgress }) => {

    return (
        <>
            {uploadProgress > 0 && (
                <div className="progress-container">
                    <motion.div
                        className="progress-bar"
                        initial={{ width: '0%' }}
                        animate={{ width: `${uploadProgress}%` }}
                    >
                        {uploadProgress}%
                    </motion.div>
                </div>
            )}
        </>
    )
};

const FormMultiActive = ({ formData, allUsers, handleChange }) => {
    const usr = JSON.parse(`${localStorage.getItem('userObj')}`);
    const isSuperUser = usr.is_superuser
    const [modifiedUsers, setModifiedUsers] = useState(() => {
        const userSet = new Set(formData.users);
        return allUsers.map((data) => ({ ...data, isActive: userSet.has(data.id) }));
    });

    const handleCheckboxChange = (userId, isChecked) => {

        if (isSuperUser === true) {
            setModifiedUsers((prevUsers) =>
                prevUsers.map((user) => (user.id === userId ? { ...user, isActive: isChecked } : user))
            );
            const indexToRemove = formData.users.indexOf(userId);
            if (isChecked === true) {
                formData.users.push(userId)
            }
            else {
                formData.users.splice(indexToRemove, 1)
            }
            handleChange(formData)
        }

    };

    return (
        <>
            <ScrollableTableContainer component={Paper} style={{ maxHeight: '55vh' }}>
                <Table key={formData.merchant_id}>
                    <TableHead style={{ position: 'sticky', top: 0, background: 'white' }}>
                        <TableRow>
                            <CenteredTableCell>{formData.merchant_name}</CenteredTableCell>
                            <CenteredTableCell>Users Active</CenteredTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="text-center">
                        {modifiedUsers.map((data) => {
                            return (
                                <TableRow key={data.user}>
                                    <CenteredTableCell>{data.email}</CenteredTableCell>
                                    <CenteredTableCell>
                                        <div className="form-check form-switch form-switch-md d-flex justify-content-center">
                                            <input
                                                key={data.user}
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={(e) => { handleCheckboxChange(data.id, e.target.checked) }}
                                                checked={data.isActive}
                                                disabled={!isSuperUser}
                                            />

                                        </div>
                                    </CenteredTableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </ScrollableTableContainer>
        </>
    );
};

const AmountField = ({ handleInputChange }) => {

    const [amount, setAmount] = useState(1);

    const onInput = (e) => {
        const parsedValue = parseFloat(e.target.value);
        e.target.value = parseFloat(e.target.value);

        if (isNaN(parsedValue) || parsedValue < 1) {
            setAmount('0');
            handleInputChange('amount', '0');
        }

        // else if (parsedValue < 1) {
        //     setAmount('0');
        //     handleInputChange('a', '0');
        // } 
        else {
            setAmount(parsedValue)
            handleInputChange('amount', parsedValue);
        }
    };

    return (
        <>
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
                {'Amount'}
                <TextField
                    type="number"
                    name="amount"
                    value={parseFloat(amount)}
                    onChange={onInput}
                    fullWidth
                />
            </Grid>
        </>
    )
};

const DropDownField = ({ options, fieldLabel, fieldName, formData, handleInputChange }) => {

    const [selectedValue, setSelectedValue] = useState(null);

    return (
        <>
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
                {fieldLabel}
                <Autocomplete
                    options={options}
                    value={selectedValue}
                    // value={formData[fieldName]}
                    onChange={(e, value) => { handleInputChange(fieldName, value); setSelectedValue(value); }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            required
                        />
                    )}
                />
            </Grid>
        </>
    )
};

const FormCreateLink = ({ formConfig, handleInputChange, formData }) => {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [links, setLinks] = useState({});

    useEffect(() => { handleInputChange('merchantId_EPB', formConfig.createFields.merchant_name) })
    const openModal = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/v1/merchants/mx/albo/get-links-merchant/?m=${formConfig.createFields.merchant_name}`, config)
            .then(res => { setLinks(res.data); setModalIsOpen(true); })
            .catch(err => { console.log(err); });
    };

    const closeModal = () => { setModalIsOpen(false); };

    return (
        <>

            {modalIsOpen && < ModalDisplayLinks links={links} openModal={openModal} modalIsOpen={modalIsOpen} closeModal={closeModal} />}

            <Grid container spacing={2}>
                <Grid item xs={6}><AmountField handleInputChange={handleInputChange} formData={formData} fieldLabel={'amount'} /></Grid>
                <Grid item xs={6}><Grid item xs={12} style={{ marginBottom: '20px', marginTop: '30px', textAlign: 'right' }}>
                    <Button variant="primary" onClick={openModal}>See Links
                    </Button>
                </Grid>

                </Grid>
            </Grid >
        </>

    );
};

const ReturnFormFields = ({ createFields, formData, handleInputChange, formConfig }) => {

    if (createFields === undefined) {
        return (
            <>
                <h4 className="p-4 text-center" >Are you sure you want to delete this Record?
                </h4>
            </>
        )
    }

    return (
        <>
            <Form>
                {Object.entries(createFields).map(([idx, entry]) => (
                    <React.Fragment key={idx}>
                        {Object.entries(entry).map(([field, value]) => (
                            <React.Fragment key={field}>
                                {field === 'FormField' && (
                                    <FormField
                                        {...value.typeField ? { typeField: value.typeField } : {}}
                                        {...value.typeValue ? { typeValue: value.typeValue } : {}}
                                        {...value.disabled ? { disabled: value.disabled } : {}}
                                        fieldLabel={value.fieldLabel}
                                        fieldName={value.fieldName}
                                        formData={formData}
                                        handleInputChange={handleInputChange}
                                    />
                                )}
                                {field === 'FormImage' && (
                                    <FormImage
                                        {...value.fieldLabel ? { fieldLabel: value.fieldLabel } : {}}
                                        {...value.fieldName ? { fieldName: value.fieldName } : {}}
                                        handleInputChange={handleInputChange}
                                    />
                                )}
                                {field === 'FormActive' && (
                                    <FormActive
                                        {...value.fieldName ? { fieldName: value.fieldName } : {}}
                                        formData={formData}
                                        // formData={formData[fieldName]}
                                        // {...value.initialActiveState ? { initialActiveState: value.initialActiveState } : {}}
                                        handleInputChange={handleInputChange}
                                    />
                                )}

                                {field === 'FormActiveTransaction' && (
                                    <FormActiveTransaction
                                        {...value.fieldName ? { fieldName: value.fieldName } : {}}
                                        formData={formConfig.formData}
                                        // formData={formData[fieldName]}
                                        formConfig={formConfig}
                                        // {...value.initialActiveState ? { initialActiveState: value.initialActiveState } : {}}
                                        handleInputChange={formConfig.handleInputChange}
                                    />
                                )}

                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            </Form>
        </>
    )
};

const FormMultiActiveMerchantPayment = ({ formConfig, formData, allUsers, setFormData }) => {

    const [allMerchants, setAllMerchants] = useState([]);

    useEffect(() => {
        const allInfo = [];
        formConfig.allUsers.map((u) => {
            const paymentMethod = u.payment_method[0];
            const merchantInfo = {
                'merchant_id': u.merchant_id,
                'merchant_name': u.merchant_name,
                'isActive': paymentMethod !== undefined ? paymentMethod.payment_method_id.includes(formData.payment_method_id) : false,
                ...(paymentMethod !== undefined ? paymentMethod : {})
            };
            allInfo.push(merchantInfo);
        });
        setAllMerchants(allInfo);
    }, [formConfig]);

    const handleCheckboxChange = (e, merchantsIds) => {
        const filteredUsers = formConfig.allUsers.filter(user => user.merchant_id === merchantsIds.merchant_id);
        let updatedPaymentMethodIDs;

        if (merchantsIds.payment_method_id.includes(formData.payment_method_id)) {
            updatedPaymentMethodIDs = merchantsIds.payment_method_id.filter(id => id !== formData.payment_method_id);
        } else {
            updatedPaymentMethodIDs = [...merchantsIds.payment_method_id, formData.payment_method_id];
        }

        if (filteredUsers.length > 0) {
            filteredUsers[0].payment_method[0].payment_method_id = updatedPaymentMethodIDs;
        }

        const updatedAllUsers = formConfig.allUsers.map(user => {
            if (filteredUsers.some(filteredUser => filteredUser.merchant_id === user.merchant_id)) {
                return filteredUsers.find(filteredUser => filteredUser.merchant_id === user.merchant_id);
            }

            return user;
        });

        // const newForm = { ...formConfig, allUsers: { ...formConfig.allUsers, ...updatedAllUsers } };

        axios
            .patch(`${process.env.REACT_APP_API_URL}/v1/merchants/merchant-payment-method/${merchantsIds.id}/`, { 'payment_method_id': updatedPaymentMethodIDs }, config)
            .then(re => {
                toast.success(`Merchant Permissions Updated`, { theme: 'dark' });

                setFormData((prevData) => ({
                    ...prevData,
                    [allUsers]: updatedAllUsers,
                }));
            })
    };

    return (
        <>
            <ScrollableTableContainer component={Paper} style={{ maxHeight: '55vh' }}>
                <Table key={formData.merchant_id}>
                    <TableHead style={{ position: 'sticky', top: 0, background: 'white', padding: '0rem 1.5rem 1.5rem !important' }}>
                        <TableRow>
                            <CenteredTableCell>{formData.merchant_name}</CenteredTableCell>
                            <CenteredTableCell>Merchants Active</CenteredTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody className="text-center">
                        {allMerchants.map((data) => {
                            return (
                                <TableRow key={data.merchant_id}>
                                    <CenteredTableCell>{data.merchant_name}</CenteredTableCell>
                                    <CenteredTableCell>
                                        <div className="form-check form-switch form-switch-md d-flex justify-content-center">
                                            <input
                                                key={data.merchant_id}
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={(e) => { handleCheckboxChange(e, data) }}
                                                checked={data.isActive}
                                            />
                                        </div>
                                    </CenteredTableCell>

                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </ScrollableTableContainer>
        </>
    );
};

function ModalHandler({ formConfig }) {

    const [uploadProgress, setUploadProgress] = useState(0);
    const handleClose = () => formConfig.setModal(false);
    const [formData, setFormData] = useState(formConfig.initialData || {});

    const handleInputChange = (fieldName, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    const configForm = {
        formData,
        handleInputChange,
        formTitle: formConfig.formTitle,
        allUsers: formConfig.allUsers,
        createFields: formConfig.createFields
    };

    const config = {
        onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            setUploadProgress(progress);
        },
    };

    const onSubmit = (e) => { e.preventDefault(); formConfig.SaveData(formData, formConfig.config ? formConfig.config : config); };
    const onUsersUptades = () => { formConfig.SaveData(formData, config); };
    const onLinkCreating = () => { formConfig.SaveData(formData, config); };

    return (
        <>
            <style>{`.modal-container { display: flex; justify-content: center; align-items: center; height: 100vh;}
                    .progress-container { width: 100%; height: 20px; background-color: #f5f5f5; border-radius: 10px; margin-top: 20px;} 
                    .progress-bar { height: 100%; background-color: #007bff; border-radius: 10px; color: #fff; display: flex; align-items: center; justify-content: center; font-weight: bold;}`
            }</style>

            <Modal show={true} onHide={handleClose} centered>
                <Modal.Header closeButton> {configForm.formTitle}</Modal.Header>

                <Modal.Body className='p-4'>
                    {configForm.formTitle === 'Merchant Payment Method' ? <FormMultiActiveMerchantPayment formConfig={configForm} formData={configForm.formData} allUsers={configForm.allUsers} setFormData={setFormData} /> : false}
                    {configForm.formTitle === 'Create Link' ? <FormCreateLink formConfig={configForm} handleInputChange={handleInputChange} formData={configForm.formData} /> : false}
                    {configForm.allUsers !== undefined && configForm.formTitle !== 'Merchant Payment Method' ? <FormMultiActive formData={configForm.formData} allUsers={configForm.allUsers} handleChange={onUsersUptades} /> : false}
                    {configForm.formTitle !== 'Create Link' ? <> <ReturnFormFields createFields={configForm.createFields} {...configForm} formConfig={formConfig} /> < UploadBar uploadProgress={uploadProgress} /> </> : false}
                </Modal.Body>

                {configForm.formTitle === 'Create Link' ? <ButtonsCreate handleClose={handleClose} onSubmit={onLinkCreating} method={formConfig.method ? formConfig.method : null} />
                    : (configForm.allUsers !== undefined || configForm.formTitle === 'Merchant Payment Method'
                        ? false
                        : <ButtonsCreate handleClose={handleClose} onSubmit={onSubmit} method={formConfig.method ? formConfig.method : null} />)
                }

            </Modal>
        </>
    );
}

export default ModalHandler;
