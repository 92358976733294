import { isMobile } from 'react-device-detect';
import '@fontsource/nunito-sans/700.css'
import '@fontsource/nunito-sans/400.css'
import '@fontsource/nunito-sans/200.css'


export const overlayStyle = {
    background: 'white',
    boxShadow: '0px 4px 30px rgba(79.74, 102.47, 42.65, 0.40)',
    borderRadius: '25px',
    position: 'relative',
    // overflowY: isMobile ? 'scroll' : 'auto',
};

export const overlayStyleCircle = {
    background: 'white',
    boxShadow: '0px 4px 30px rgba(79.74, 102.47, 42.65, 0.40)',
    borderRadius: '25px',
    position: 'relative',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    zIndex: '9'
}
export const overlayStyleCircleTop = {
    background: 'white',
    boxShadow: '0px 4px 30px rgba(79.74, 102.47, 42.65, 0.40)',
    borderRadius: '25px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    zIndex: '9',
    position: 'fixed',
    top: '2%',
    left: '5%',
    height: '50px',
}

export const overlayStyle2 = {
    background: 'white',
    boxShadow: '0px 4px 30px rgba(79.74, 102.47, 42.65, 0.40)',
    borderRadius: '5px',
    position: 'fixed',
    top: '2%',
    right: '5%',
    textAlign: 'center'

};

export const overlayStyle3 = {
    background: 'white',
    boxShadow: '0px 4px 30px rgba(79.74, 102.47, 42.65, 0.40)',
    borderRadius: '5px',
    position: 'fixed',
    top: '2%',
    left: '5%',
    height: '50px',
    textAlign: 'center'
};

export const overlayStyle4 = {
    background: 'transparent',
    boxShadow: '0px 4px 30px rgba(79.74, 102.47, 42.65, 0.40)',
    borderRadius: '5px',
    position: 'fixed',
    bottom: '2%',
    right: '5%',
    height: '55px',
    textAlign: 'center',
    width: '55px'
};


export const overlayStyleCircleIcon = {
    height: '30px',
    width: '30px'
};


export const buttonStyle = {
    backgroundColor: '#0BC177',
    borderRadius: '15px',
    height: '60px',
    fontFamily: 'Nunito Sans',
    fontWeight: '700',
    fontSize: isMobile ? '14px' : '20px',
    lineHeight: isMobile ? '20px' : '28px',
    textAlign: 'center',
    textTransform: 'none'
    // maxWidth: '80%'
};

export const buttonStyleRed = {
    backgroundColor: 'rgba(233, 116, 81, 0.8)',
    borderRadius: '15px',
    width: '101.3%',
    height: '60px',
    fontFamily: 'Nunito Sans',
    fontWeight: '700',
    fontSize: isMobile ? '10px' : '14px',
    lineHeight: isMobile ? '14px' : '18px',
    textAlign: 'center',
    textTransform: 'none'
};

export const buttonStyleYell = {
    backgroundColor: '#0BC177',
    borderRadius: '15px',
    width: '101.3%',
    height: '50px',
    fontFamily: 'Nunito Sans',
    fontWeight: '700',
    fontSize: isMobile ? '14px' : '18px',
    lineHeight: isMobile ? '19px' : '24px',
    textAlign: 'center',
    textTransform: 'none'
    // maxWidth: '80%',
};

export const buttonDivStyle = {
    display: 'flex',
    textAlign: 'center'
};

export const buttonDivStyleInitial = {
    textAlign: 'center'
};

export const mw50 = {
    maxWidth: isMobile ? 'auto' : '50%'
}
export const mw30 = {
    maxWidth: isMobile ? 'auto' : '30%'
}
export const mw70 = {
    maxWidth: isMobile ? 'auto' : '70%'
}

export const mw100 = {
    maxWidth: isMobile ? 'auto' : '100%'
}

export const mw75 = {
    maxWidth: isMobile ? '100%' : '75%'
}



export const titleStyle = {
    color: '#004955',
    fontSize: isMobile ? '24px' : '31px',
    lineHeight: isMobile ? '24px' : '34px',
    fontFamily: 'Nunito Sans',
    fontWeight: '700',
    letterSpacing: '0.80px',
    textAlign: 'center',
    padding: isMobile ? '0px' : '10px 30px'

};
export const circleText = {
    color: 'white',
    fontSize: isMobile ? '14px' : '14px',
    lineHeight: isMobile ? '14px' : '14px',
    fontFamily: 'Nunito Sans',
    fontWeight: '700',
    letterSpacing: '0.80px',
    textAlign: 'center',
};

// export const titleStyle = {
//     color: '#3E6B06',
//     fontSize: isMobile ? '25px' : '35px',
//     lineHeight: '35px',
// };


// export const titleStyle = {
//     color: '#3E6B06',
//     fontSize: isMobile ? '25px' : '40px',
//     lineHeight: isMobile ? '28px' : '45px',
// };


export const stl = {
    justifyContent: 'flex-end',
    position: isMobile ? false : 'absolute',
    marginTop: isMobile ? '-15px' : '0px',
    // width: 'auto',
    // minWidth: '97%',
    bottom: '20px'
}
export const stl3 = {
    justifyContent: 'flex-end',
    width: 'auto',
    // paddingTop: '5px', 
    bottom: '20px'
    // minWidth: '97%',
}
export const stl4 = {
    paddingTop: '5px'
}

export const mobileButtons = {
    marginTop: '10px'
}

export const stl2 = {
    justifyContent: 'center',
    width: 'auto',
    // minWidth: '97%',
    bottom: '40px'
}

export const fldStl = {
    padding: isMobile ? '0px 0px 15px 0px' : '0px 0px 14px 0px'
};


export const subTitleStyle = {
    color: '#004955',
    fontSize: isMobile ? '16px' : '20px',
    fontFamily: 'Nunito Sans',
    fontWeight: '700',
    lineHeight: '24px',
    marginBottom: '20px',
    letterSpacing: '1px',
    textAlign: 'left'
};


export const mobileButtons2 = {
    marginTop: '20px',
    marginBottom: '20px'
}

export const cpbtstl = {
    minWidth: 'auto',
    padding: '7px 1px 7px 10px'
};

export const cpbtstlweb = {
    // minWidth: 'auto',
    // padding: '7px 1px 7px 10px',
    textTransform: 'none',
    color: '#B9C0C7',
    borderRadius: '7px',
    border: '1px solid #B9C0C7',
    height: '30px !important',
    fontSize: '14px',
    fontFamily: 'Nunito Sans',
    marginRight: '20px'

};


export const subTitleStyle2 = {
    color: '#004955',
    fontSize: isMobile ? '16px' : '20px',
    fontFamily: 'Nunito Sans',
    fontWeight: '700',
    lineHeight: isMobile ? '16px' : '24px',
    marginBottom: isMobile ? '10px' : '20px',
    letterSpacing: '1px',
    textAlign: 'center'
};

export const spinnerArea = {
    display: 'flex',
    alignItems: 'center',
    width: '137px',
    height: '137px',
    backgroundColor: '#D8FEE6',
    borderRadius: '25px',
    justifyContent: 'center',
}

export const spinnerContainer = {
    alignItems: 'center',
    padding: '0px 0px',
    display: 'flex',
    justifyContent: 'center',
}


export const coupon = {
    border: '3px dotted #bbb',
    width: '50%',
    borderRadius: '15px',
    margin: '0 auto',
    minWidth: '350px',
}

export const couponContainer = {
    padding: '10px 15px',
    backgroundColor: '#f1f1f1',
    textAlign: 'center',
}

export const couponH3 = {
    color: 'rgb(0, 73, 85)',
    fontSize: '24px',
    fontFamily: 'Nunito Sans',
    fontWeight: '700',
    textAlign: 'center',
    marginBottom: '0px',
}

export const couponH4 = {
    color: 'rgb(0, 73, 85)',
    fontFamily: 'Nunito Sans',
    fontWeight: '700',
    textAlign: 'center',
    fontSize: '1.2rem',
    margin: '10px 0px'
}

export const promo = {
    marginTop: '10px',
    background: '#ccc',
    padding: '3px',
    fontStyle: 'bold',
}
