import React from 'react';
import { Button, Grid, CircularProgress } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { buttonDivStyle as btnDivStyle, stl2, mobileButtons2, mw75, mw100, buttonStyle } from './styles';

const buttonDivStyle = { ...btnDivStyle, ...stl2 }

export const EmailButton = ({ DictConf, methodPayment }) => {
    return (
        <>
            <Grid item xs={12} style={{ ...(isMobile ? mobileButtons2 : (['rapipago', 'qr', 'pagofacil', 'dlocalgo'].includes(methodPayment) ? mw100 : mw75)), ...buttonDivStyle, textAlign: 'center', }} spacing={3}>
                <Button variant="contained" color="primary" type="submit" style={{...buttonStyle, width: '75%'}}>
                    {DictConf.isLoading ? <CircularProgress size={24} color="inherit" /> : "Ir a Pagar"}
                </Button>
            </Grid>
        </>
    )
}

export const VocuherButton = ({ DictConf, methodPayment }) => {
    return (
        <>
            <Grid item xs={12} style={{ ...(isMobile ? mobileButtons2 : (['rapipago', 'qr', 'pagofacil','dlocalgo'].includes(methodPayment) ? mw100 : mw75)), ...buttonDivStyle, textAlign: 'center'}} spacing={3}>
                <Button variant="contained" color="primary" fullWidth type="submit" style={{...buttonStyle, width: '50%'}}>
                    {DictConf.isLoading ? <CircularProgress size={24} color="inherit" /> : "Validar Nº de eVoucher"}
                </Button>
            </Grid>
        </>
    )
}

