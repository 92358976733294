
import React, { useState, useEffect } from "react";
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, styled } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp, Edit, Delete } from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import { Dialog, DialogTitle, DialogContent, Slide } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import ModalDeleteUser from "./ModalDeleteUser";
import "react-toastify/dist/ReactToastify.css";
import { isMobile } from 'react-device-detect';
const config = { headers: { 'Authorization': `JWT ${localStorage.getItem('access')}` } };

const CenteredTableCell = styled(TableCell)({ textAlign: "center" });
const ScrollableTableContainer = styled(TableContainer)({ maxHeight: "calc(100vh - 180px)", overflowY: "auto" });
const Transition = React.forwardRef(function Transition(props, ref) { return <Slide direction="up" ref={ref} {...props} /> });

const titleStyle = {
	color: '#3E6B06',
	fontSize: '30px',
	fontFamily: 'Roboto, sans-serif',
	fontWeight: '600',
	lineHeight: '27px',
	letterSpacing: '0.80px',
	textAlign: 'center',
	padding: '10px 5px'

};

const stl = {
	display: 'flex',
	justifyContent: 'center'
}

const InfoAlert = ({ open, handleClose, u, c, uid }) => {
	const [activeUser, setActiveUser] = useState(u);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const setFormCustomerData = () => {
			if (u.length !== 0) {
				setActiveUser(u);
				setLoading(false);
			}
		}
		setFormCustomerData();
	}, [u, activeUser]);

	const onUpdateCustomers = (method, userId, array) => {
		axios
			.patch(`${process.env.REACT_APP_API_URL}/v1/auth/users-permissions/${userId}/`, { 'customer_id': array }, config)
			.then(d => { { method === 'Update' ? toast.warning(`Permissions Updated successfully`, { theme: 'dark' }) : toast.success(`Permissions Updated successfully`, { theme: 'dark' }) } })
			.catch(err => { toast.error(`There was an error, please try again...`, { theme: 'dark' }) })
	}

	const onSwitch = (e, cid, ucr, uuid) => {
		const myArray = ucr
		const valueToRemove = cid
		const indexToRemove = myArray?.indexOf(valueToRemove) === undefined ? false : myArray?.indexOf(valueToRemove);

		if (indexToRemove !== -1) {
			const poppedValue = myArray.splice(indexToRemove, 1)[0];
			onUpdateCustomers('Update', uuid.id, myArray);
		} else {
			myArray.push(cid);
			onUpdateCustomers('Append', uuid.id, myArray);
		}
		setActiveUser([activeUser[0].customer_id = myArray]);
	}

	const paperStyle = { width: isMobile ? '90vw' : '50vw' };

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			TransitionComponent={Transition}
			transitionDuration={500}
			PaperComponent={({ children }) => (<Paper elevation={5} style={paperStyle}> {children} </Paper>)}
		>
			<DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
				<Typography variant="h5" > USERS - PROVIDERS </Typography>
				<IconButton aria-label="close" onClick={handleClose} sx={{ color: (theme) => theme.palette.grey[500] }}> <CloseIcon /> </IconButton>
			</DialogTitle>

			<DialogContent style={{ padding: '0px 20px 10px 20px', textAlign: 'center' }}>
				<small className="text-muted" style={{ textAlign: 'center' }}> Editing User Permissions For: {activeUser[0]?.email}</small>
			</DialogContent>

			<DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '20px 20px', paddingTop: 'none' }}>

				{!loading && activeUser?.map((user) => (
					<ScrollableTableContainer component={Paper} style={{ maxHeight: '55vh' }}>
						<Table key={user.customer_id}>
							<TableHead style={{ position: 'sticky', top: 0, background: 'white', padding: '0rem 1.5rem 1.5rem !important' }}>
								<TableRow>
									<CenteredTableCell></CenteredTableCell>
									<CenteredTableCell>User Active</CenteredTableCell>
								</TableRow>
							</TableHead>

							<TableBody className="text-center">
								{c?.map((cust) => {
									return (
										<TableRow key={cust.customer_id}>
											<CenteredTableCell>{cust.customer_name}</CenteredTableCell>
											<CenteredTableCell>
												<div className="form-check form-switch form-switch-md" style={stl}>
													<input className="form-check-input" type="checkbox"
														value={cust.customer_id === undefined || user.customer_id === undefined ? false : user.customer_id.includes(cust.customer_id)}
														onChange={(e) => onSwitch(e, cust.customer_id, user.customer_id, user)}
														checked={cust.customer_id === undefined || user.customer_id === undefined ? false : user.customer_id.includes(cust.customer_id)}
													/>
												</div>
											</CenteredTableCell>
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
					</ScrollableTableContainer>
				))}
			</DialogContent>
		</Dialog>
	);
};

const TableHeaders = ({ ConfDict }) => {
	return (
		<TableHead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: 'white' }}  >
			<TableRow>
				<TableCell /> {ConfDict?.h?.map((v, i) => (<CenteredTableCell key={i}> {v} </CenteredTableCell>))}
			</TableRow>
		</TableHead>
	);
};

const ActiveField = ({ d, k, i, ConfDict }) => {
	const [activeValue, setActiveValue] = useState(d[k])

	const onSwitch = (e) => {
		setActiveValue(!activeValue)
		toast(!activeValue === true ? 'Record activated' : 'Record deactivated', { theme: 'dark', type: !activeValue ? 'success' : 'warning' });
		ConfDict.oup({ uid: d['id'], k, v: activeValue });
	};

	return (
		<>
			<div className="form-check form-switch form-switch-md" style={stl}>
				<input
					className="form-check-input" type="checkbox"
					value={activeValue} onChange={(e) => { onSwitch(e) }}
					checked={activeValue}
				/>
			</div>
		</>
	)
};

const TableContent = ({ ConfDict }) => {
	const textFields = ConfDict.k['textFields']
	const activeFields = ConfDict.k['activeFields']
	const [open, setOpen] = useState(false);
	const [usr, setUsr] = useState([])
	const [cust, setCust] = useState([])
	const [uuid, setUuid] = useState([])
	const [activeUser, setActiveUser] = useState([]);
	const [modalDeleteUser, setModalDeleteUser] = useState(false);

	const handleClose = (data) => {
		setUuid(data);
		setOpen(!open);

		if (open !== true) {
			axios.get(`${process.env.REACT_APP_API_URL}/v1/payment-methods/payment-providers/`, config).then(resp => {
				setCust(resp.data);
				axios.get(`${process.env.REACT_APP_API_URL}/v1/auth/users-permissions/?u=${data.id}`, config).then(res => {
					setUsr(res.data)
				})
			}).catch(error => { console.log('Error fetching users: ', error) });
		}
	};

	const OnDelete = (data) => {
		setActiveUser(data)
		setModalDeleteUser(true);
	};

	return (
		<>
			{modalDeleteUser && (
				<ModalDeleteUser
					setModalDeleteUser={setModalDeleteUser}
					data={activeUser}
					setUsrs={ConfDict.setUsers}
				/>
			)}

			{open && <InfoAlert open={open} handleClose={handleClose} u={usr} c={cust} uid={uuid} />}

			{ConfDict?.d?.map(data => (
				<TableRow key={data.id}>
					<TableCell> <IconButton aria-label="expand row" size="small"> <KeyboardArrowUp /> </IconButton> </TableCell>
					{textFields?.map((key, index) => (<CenteredTableCell key={index}> {data.hasOwnProperty(key) ? data[key] : false} </CenteredTableCell>))}
					{activeFields?.map((key, index) => (<CenteredTableCell key={index}> {data.hasOwnProperty(key) ? <ActiveField d={data} k={key} i={index} ConfDict={ConfDict} /> : false} </CenteredTableCell>))}
					<TableCell style={{ textAlign: 'center' }}>
						<IconButton aria-label="expand row" size="medium" onClick={(e) => { handleClose(data) }} >
							<Edit onClick={ConfDict.setModal} />
						</IconButton>
						<IconButton aria-label="expand row" size="medium" onClick={(e) => { OnDelete(data) }} >
							<Delete onClick={ConfDict.setModal} />
						</IconButton>
					</TableCell>
				</TableRow>
			))}
		</>
	);
};

const CreateTable = ({ ConfDict }) => {
	return (
		<>
			<ScrollableTableContainer component={Paper}> <Table aria-label="collapsible table">
				<TableHeaders ConfDict={ConfDict} />
				<TableBody> <TableContent ConfDict={ConfDict} /> </TableBody>
			</Table> </ScrollableTableContainer>
		</>
	)
};

const UserTable = ({ users, setUsers, setAllUsers }) => {

	const OnUpdate = ({ uid, k, v }) => {
		const data = { [k]: !v }
		axios.patch(`${process.env.REACT_APP_API_URL}/v1/auth/users/${uid}/`, data, config).then(res => { }).catch(error => { console.log('Error fetching users: ', error) });
	};

	const ConfDict = {
		d: users,
		h: ['User Name', 'User Lastname', 'Email', 'Is Active', 'Is Staff', 'Actions'],
		k: { textFields: ['first_name', 'last_name', 'email'], activeFields: ['is_active', 'is_staff'] },
		setUsers: setUsers,
		oup: OnUpdate,
	};

	return (<> <CreateTable ConfDict={ConfDict} /> </>);
};

export default UserTable;

