import React, { useState } from 'react';
import { Button, Grid, CircularProgress } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { ConfirmToast } from 'react-confirm-toast'
import { stl3, stl4, buttonDivStyleInitial as btnDivStyle, mw50, mobileButtons, buttonStyleRed, buttonStyleYell, buttonStyle, mw100 } from './styles';

const buttonDivStyle = { ...btnDivStyle, ...stl3, }
const mw101 = { ...mw100, ...stl4, }

export const FormButtons = ({ DictConf, merchant }) => {

    const [isOpenConfirm, setIsOpenConfirm] = useState(false)
    const handleIsOpenConfirm = () => { setIsOpenConfirm(!isOpenConfirm) }

    const myFunction = (e) => {
        DictConf.onConfirmationForm(e);
    };

    return (
        <>
            <Grid item xs={12} style={buttonDivStyle}>
                {DictConf.formSent && DictConf.isError ?

                    <Grid item xs={12} style={isMobile ? mobileButtons : mw100}>
                        <Button variant="contained" color="primary" fullWidth type="submit" style={buttonStyleRed} onClick={DictConf.reloadForm}>
                            Ha habido un error... <br /> Comienza nuevamente por favor
                        </Button>
                    </Grid>
                    :
                    (DictConf.formSent ?
                        <Grid item xs={12} style={isMobile ? mobileButtons : mw101}>
                            <ConfirmToast style={{ left: '-33%' }}
                                asModal={true} customConfirm='Confirmar' customCancel='Cancelar' customFunction={(e) => myFunction(e)} showCloseIcon={false}
                                message={`Luego de que confirmemos tu pago, te redireccionaremos a la página de ${merchant}`} >
                                <button style={{ color: 'transparent', border: 'none', background: 'transparent', width: '100%' }}>
                                    <Button variant="contained" color="primary" fullWidth type="submit" style={buttonStyleYell} onClick={(e) => { handleIsOpenConfirm(e) }} >
                                        {DictConf.isLoading ? <CircularProgress size={24} color="inherit" /> : "Obtener eVoucher "}
                                    </Button>
                                </button>
                            </ConfirmToast>
                        </Grid>
                        :
                        <Grid item xs={12} style={isMobile ? mobileButtons : mw101}>
                            <Button variant="contained" color="primary" fullWidth type="submit" style={buttonStyle} >
                                {DictConf.isLoading ? <CircularProgress size={24} color="inherit" /> : "Ir a Pagar"}
                            </Button>
                        </Grid>
                    )
                }
            </Grid >
        </>
    )
}


