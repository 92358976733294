import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from '../../Components/Modal/Modals/Modal';
import axios from 'axios';
import { LoadingOverlay } from '../Common/Common';
import { Card, Col, Button, Row } from 'react-bootstrap';
import { Box, Paper, TextField } from "@mui/material";
import {
	AiOutlineEdit, AiOutlinePlusSquare, AiOutlineDelete, AiOutlineBank
} from 'react-icons/ai';

function PaymentMethods() {
	const pathArray = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
	const [isLoading, setIsLoading] = useState(true);
	const [dataInfo, setDataInfo] = useState([]);
	const [allUsers, setAllUsers] = useState([]);
	const [modalAdd, setModalAdd] = useState(false);
	const [modalEdit, setModalEdit] = useState(false);
	const [modalDelete, setModalDelete] = useState(false);
	const [modalMerchantsPayments, setModalMerchantsPayments] = useState(false);
	const [idSelected, setIdSelected] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');

	const config = {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `JWT ${localStorage.getItem('access')}`,
		}
	};

	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
			try {
				const [paymentMethodsRes, merchantsRes] = await Promise.all(
					[
						axios.get(`${process.env.REACT_APP_API_URL}/v1/payment-methods/providers-payment-method/?provider=${pathArray}`, config),
						axios.get(`${process.env.REACT_APP_API_URL}/v1/merchants/merchants/`, config),
					]
				);
				setDataInfo(paymentMethodsRes.data);
				setAllUsers(merchantsRes.data);
				await delay(500);
				setIsLoading(false);
			}
			catch (error) {
				toast.error(`There is no available data to show at the moment`, { theme: 'dark' });
				console.log(error);
			}
		};
		fetchData();
	}, [setDataInfo, setAllUsers]);

	const SaveData = (formData) => {
		axios.post(
			`${process.env.REACT_APP_API_URL}/v1/payment-methods/payment-method/`, formData, config
		).then((res) => {
			if (res.status >= 400) { toast.error(`Server did not respond: Error ${res.status}`, { theme: 'dark' }); }
			else { toast.success(`${formData.name} added to the database successfully`, { theme: 'dark' }); };
			axios.post(
				`${process.env.REACT_APP_API_URL}/v1/payment-methods/providers-payment-method/`,
				{ 'payment_method_id': res.data.id, 'customer_id': pathArray }, config
			).then((r) => {
				axios.get(
					`${process.env.REACT_APP_API_URL}/v1/payment-methods/providers-payment-method/?provider=${pathArray}`, config
				).then((resp) => {
					setDataInfo(resp.data);
				});
				setModalAdd(false);
			});
		}).catch((err) => {
			console.log(err);
		});
	};

	const updateData = (formData) => {
		const data = {
			'name': formData.name,
			'type': formData.type,
		};
		axios.patch(
			`${process.env.REACT_APP_API_URL}/v1/payment-methods/payment-method/${formData.payment_method_id}/`, data, config
		).then(res => {
			if (res.status >= 400) { toast.error(`Server did not respond: Error ${res.status}`, { theme: 'dark' }); }
			else { toast.success(`${data.name} Updated successfully`, { theme: 'dark' }); };
			axios.get(`${process.env.REACT_APP_API_URL}/v1/payment-methods/providers-payment-method/?provider=${pathArray}`, config)
				.then(resp => {
					setDataInfo(resp.data);
					setModalEdit(false);
				});
		}).catch(err => {
			console.log(err);
		});
	};

	const deleteData = (formData) => {
		fetch(`${process.env.REACT_APP_API_URL}/v1/payment-methods/payment-method/${formData.payment_method_id}/`, {
			method: "DELETE",
			headers: new Headers({ 'Authorization': `JWT ${localStorage.getItem('access')}` })
		}).then(res => {
			if (res.status >= 400) { toast.error(`Server did not respond : Error ${res.status}`, { theme: 'dark', }); }
			else { toast.success(`The Customer ${formData.name} has been removed from the Database!`, { theme: "dark" }); };
			axios
				.get(`${process.env.REACT_APP_API_URL}/v1/payment-methods/providers-payment-method/?provider=${pathArray}`, config)
				.then(resp => {
					setDataInfo(resp.data)
					setModalDelete(false);
				});
		}).catch(err => {
			console.log(err);
		});
	};

	const onSaveMerchantsPayments = () => {

	}

	const onAddMerchant = (data) => {
		setIdSelected(data);
		setModalMerchantsPayments(true);
	};

	const onEdit = (data) => {
		setIdSelected(data);
		setModalEdit(true);
	};

	const onDelete = (data) => {
		setIdSelected(data);
		setModalDelete(true);
	};

	const handleSearch = (event) => {
		setSearchTerm(event.target.value);
	};

	const filteredDataInfo = dataInfo.filter((data) =>
		data.provider.toLowerCase().includes(searchTerm.toLowerCase()) ||
		data.payment_method.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const createFields = {
		0: {
			FormField: {
				fieldName: 'name',
				fieldLabel: 'Payment Method Name',
			}
		},
		1: {
			FormField: {
				fieldName: 'type',
				fieldLabel: 'Payment Method Type',
			}
		},
	};

	const formConfig = {
		formTitle: 'Create Payment Method',
		setModal: setModalAdd,
		setInfoData: setDataInfo,
		SaveData: SaveData,
		createFields: createFields
	};

	const formConfigEdit = {
		formTitle: 'Edit Payment Method',
		setModal: setModalEdit,
		setInfoData: setDataInfo,
		SaveData: updateData,
		initialData: idSelected,
		createFields: createFields,
	};

	const formConfigDelete = {
		formTitle: 'Delete Payment Method',
		setModal: setModalDelete,
		setInfoData: setDataInfo,
		SaveData: deleteData,
		initialData: idSelected,
		method: 'DELETE'
	};

	const formConfigMerchantPayment = {

		formTitle: 'Merchant Payment Method',
		setModal: setModalMerchantsPayments,
		setInfoData: setDataInfo,
		SaveData: onSaveMerchantsPayments,
		initialData: idSelected,
		allUsers: allUsers,
		createFields: 'createFields',
	};

	return (
		<>
			{isLoading && < LoadingOverlay />}
			{modalAdd && <Modal formConfig={formConfig} />}
			{modalEdit && <Modal formConfig={formConfigEdit} />}
			{modalDelete && <Modal formConfig={formConfigDelete} />}
			{modalMerchantsPayments && <Modal formConfig={formConfigMerchantPayment} />}

			<style>{`.MuiInputBase-root {max-height: 46px;}`}</style>
			<style>{`.icon:hover {color: blue;cursor: pointer;}`}</style>

			<Box sx={{ overflowX: 'hidden' }}>
				<Row style={{ height: 'calc(100vh - 90px)' }}>
					<Col lg={12} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
						<Row xs={1} md={10} lg={10} style={{ position: 'sticky', top: '0', zIndex: 2, marginBottom: '10px' }}>

							<Paper style={{ marginBottom: '10px' }}>
								<Row className="p-3 align-items-center">

									<Col lg={8}>
										<h2 className="text-center" style={{ marginTop: '0.5rem' }}>
											{/* {`${process.env.REACT_APP_WEB_NAME}`} PAYMENT METHODS */}
											{allUsers[0]?.provider} PAYMENT METHODS
										</h2>
									</Col>

									<Col lg={4} className="text-end">
										<TextField style={{ marginRight: '10px' }} value={searchTerm} onChange={handleSearch} placeholder="Find..." />
										<Button variant="outline-success" size="md" onClick={() => setModalAdd(true)}>
											<AiOutlinePlusSquare className="icon" size="2em" />
										</Button>
									</Col>

								</Row>
							</Paper>
						</Row>

						<div className="scroll-container" style={{ overflowY: 'auto', flex: 1, margin: '15px', overflowX: 'hidden' }}>
							<Row xs={1} md={4} className="g-3 p-1">
								{filteredDataInfo.map((data) => (
									<Col className="align-self-stretch" key={data.payment_method_id} style={{ paddingLeft: '-0.5rem' }}>
										<Card className="d-flex h-100">
											<div className={`pb-2 w-100 rounded-top ${data.active === false ? 'bg-danger' : 'bg-success'}`} />
											<Card.Header>
												<Row className="text-center">

													<Col lg={4} xs={4} className="border-end">
														<AiOutlineDelete className="icon" onClick={() => onDelete(data)} />
													</Col>

													<Col lg={4} xs={4} className="border-end">
														<AiOutlineEdit className="icon" onClick={() => onEdit(data)} />
													</Col>

													<Col lg={4} xs={4}>
														<AiOutlineBank className="icon" onClick={() => onAddMerchant(data)} />
													</Col>

												</Row>
											</Card.Header>

											<Card.Body className="d-flex flex-column">
												<Link className="text-decoration-none" to={String(data.name)}>
													<Card.Title className="mb-auto p-2 bd-highlight text-center">{data.name}</Card.Title>
												</Link>
												<Card.Text className="mb-auto p-2 bd-highlight text-center">
													<small className="text-muted">{data.type}</small>
												</Card.Text>
											</Card.Body>

											<Card.Footer className="text-center">
												{/* <small className="text-muted">{new Date(data.created_date).toDateString()}</small> */}
												<small className="text-muted">{data.provider}</small>
											</Card.Footer>

										</Card>
									</Col>
								))}
							</Row>
						</div>
					</Col>
				</Row>
			</Box>
		</>
	);
}


export default PaymentMethods;
