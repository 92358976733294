import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';

const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`, } };

function ModalHandler({ setModalCreateUser, setUsers }) {
    const [formData, setFormData] = useState({ first_name: '', last_name: '', email: '', password: '', re_password: '' });
    const { first_name, last_name, email, password, re_password } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        if (password === re_password) {
            axios.post(`${process.env.REACT_APP_API_URL}/auth/users/`, formData, config).then((response) => {
                toast.success('Added to the Database successfully', { theme: 'dark' });
                    axios.get(`${process.env.REACT_APP_API_URL}/v1/auth/users/`, config).then((re) => {
                        setUsers(re.data);
                        console.log(re.data)
                        setModalCreateUser(false);
                    });
            }).catch((error) => {
                if (error.response) {
                    const data = error.response.data;
                    if (data.email) { toast.error(`Error! ${data.email[0]}`, { theme: 'dark' }); }
                    if (data.password) { toast.error(`Error! ${data.password[0]}`, { theme: 'dark' }); }
                } else { console.error(error); }
            });
        };
    };

    return (
        <>
            <Modal show={true} onHide={() => setModalCreateUser(false)}>
                <Modal.Header closeButton> <span>CREATE USER</span> </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control className="form-control" type="text" placeholder="FirstName" name="first_name" value={first_name} onChange={e => onChange(e)} required />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control className="form-control" type="text" placeholder="LastName" name="last_name" value={last_name} onChange={e => onChange(e)} required />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control className="form-control" placeholder="email" name="email" type='email' value={email} onChange={e => onChange(e)} required />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control className="form-control" placeholder="password" name="password" type='password' minLength="6" value={password} onChange={e => onChange(e)} required />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Re Password</Form.Label>
                            <Form.Control className="form-control" placeholder="password" name="re_password" type='password' minLength="6" value={re_password} onChange={e => onChange(e)} required />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer className="footer">
                    <Button variant="secondary" onClick={() => setModalCreateUser(false)} id="cancelBtn"> Cancel </Button>
                    <Button variant="primary" onClick={e => onSubmit(e)}> Register </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalHandler;

