import React from 'react';
import { TextField } from "@mui/material";

const SearchBar = ({ value, onChange }) => {
    return (
        <TextField
            value={value}
            onChange={onChange}
            placeholder="Find..."
        />
    );
};

export default SearchBar;