import React, { useEffect } from 'react';
import QRCode from 'qrcode'; 

const QRCodeComponent = ({ qrData, className }) => {

    useEffect(() => {
        generateQRCode(qrData);
    }, [qrData]);

    const generateQRCode = async (data) => {
        try {
            const canvas = document.createElement('canvas');
            canvas.width = 200;
            canvas.height = 200;

            await QRCode.toCanvas(canvas, data, {
                width: 200,
                height: 200,
                color: {
                    light: '#0000' // Transparent background
                }
            });

            // Get the data URL of the canvas
            const qrImageData = canvas.toDataURL('image/png');

            // Update the image source using the class name
            const qrImages = document.getElementsByClassName(className);
            Array.from(qrImages).forEach(qrImage => {
                qrImage.src = qrImageData;
            });
        } catch (error) {
            console.error('Error generating QR code:', error);
        }
    };

    return (
        <div style={{marginTop: '-10px'}}>
            {/* Use className to identify the img element */}
            <img className={className} alt="QR Code" style={{backgroundColor: ''}}/>
        </div>
    );
};

export default QRCodeComponent;
