


import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Box, Paper, TextField } from "@mui/material";
import { Card, Col, Button, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AiOutlineEdit, AiOutlinePlusSquare, AiOutlineDelete, AiOutlineBank } from 'react-icons/ai';
import Modal from '../../Components/Modal/Modals/Modal';
import { LoadingOverlay } from '../Common/Common';

function PaymentMethods() {
	const pathArray = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
	const pathArray2 = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];

	const [idSelected, setIdSelected] = useState(null);
	const [allUsers, setAllUsers] = useState([]);
	const usr = JSON.parse(`${localStorage.getItem('userObj')}`);
	
	const [isLoading, setIsLoading] = useState(true);
	const [dataInfo, setDataInfo] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/merchants/merchant-payment-method/?m=${pathArray}`, config);
				const pms = await res.data[0].payment_method_id
				const resp = await axios.get(`${process.env.REACT_APP_API_URL}/v1/payment-methods/payment-method/?pms=${await pms}`, config);
				setDataInfo(resp.data);
				setIsLoading(false);
			} catch (error) {
				console.error(error);
				setIsLoading(false);
			}
		};
		fetchData();
	}, [pathArray, pathArray2, setAllUsers]);

	const config = {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `JWT ${localStorage.getItem('access')}`,
		}
	};

	const handleSearch = (event) => {
		setSearchTerm(event.target.value);
	};

	const filteredDataInfo = dataInfo.filter((data) =>
		data.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
		data.type.toLowerCase().includes(searchTerm.toLowerCase())
	);

	return (
		<>

			<style>{`.MuiInputBase-root {max-height: 46px;}`}</style>
			<style>{`.icon:hover {color: blue;cursor: pointer;}`}</style>

			<Box sx={{ overflowX: 'hidden' }}>
				<Row style={{ height: 'calc(100vh - 90px)' }}>
					<Col lg={12} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
						<Row xs={1} md={10} lg={10} style={{ position: 'sticky', top: '0', zIndex: 2, marginBottom: '10px' }}>

							<Paper style={{ marginBottom: '10px' }}>
								<Row className="p-3 align-items-center">
									<Col lg={8}>
										<h2 className="text-center" style={{ marginTop: '0.5rem' }}>
											PAYMENT METHODS
										</h2>
									</Col>
									<Col lg={4} className="text-end">
										<TextField style={{ marginRight: '10px' }} value={searchTerm} onChange={handleSearch} placeholder="Find..." />
									</Col>
								</Row>
							</Paper>
						</Row>

						<div className="scroll-container" style={{ overflowY: 'auto', flex: 1, margin: '15px', overflowX: 'hidden' }}>
							{isLoading && <LoadingOverlay />}
							<Row xs={1} md={4} className="g-3 p-1">
								{filteredDataInfo.map((data) => (
									<Col className="align-self-stretch" key={data.id} style={{ paddingLeft: '-0.5rem' }}>
										<Card className="d-flex h-100">
											<div className={`pb-2 w-100 rounded-top ${data.active === false ? 'bg-danger' : 'bg-success'}`} />
											<Card.Header>
												<Row className="text-center">
												</Row>
											</Card.Header>

											<Card.Body className="d-flex flex-column">
												<Link className="text-decoration-none" to={String(data.name)}>
													<Card.Title className="mb-auto p-2 bd-highlight text-center">{data.name}</Card.Title>
												</Link>
												<Card.Text className="mb-auto p-2 bd-highlight text-center">
													<small className="text-muted">{data.type}</small>
												</Card.Text>
											</Card.Body>

											<Card.Footer className="text-center">
												<small className="text-muted">{data.type}</small>
											</Card.Footer>

										</Card>
									</Col>
								))}
							</Row>
						</div>
					</Col>
				</Row>
			</Box>
		</>
	);
}


export default PaymentMethods;
