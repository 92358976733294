import React from 'react';
import { isMobile } from 'react-device-detect';
import { TextField, Button, Grid, InputAdornment } from '@mui/material';
import { DialogContent } from '@mui/material';
import { Typography } from '@mui/material';
import { toast } from "react-toastify";

import { mw50, fldStl, cpbtstl, cpbtstlweb } from '../Common/styles';
import { subTitleStyle, spinnerContainer, spinnerArea, buttonStyleYell } from '../Common/styles';
import { FormButtons, } from '../Common/FormButtons';
import QRCodeComponent from '../Common/QrGenerator'

import WebAssets from "../Assets/WebAssets";

const { CopyIcon, spinnerArrow, icon6 } = WebAssets;


const InstruccionesArg = ({ methodPayment, concepto, amount, email, isError }) => {

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 2);

    const formattedDate = `${currentDate.getDate()} de ${getMonthName(currentDate.getMonth())}`;

    function getMonthName(monthIndex) {
        const monthNames = [
            "enero", "febrero", "marzo", "abril", "mayo", "junio",
            "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
        ];
        return monthNames[monthIndex];
    }

    return (
        // <Grid item xs={12} style={{ ...mw50, alignSelf: 'center', minWidth: isMobile ? '100%' : '', marginTop: isMobile ? '30px' : '' }}>
        <div>
            {/* <Typography variant="h2" gutterBottom style={subTitleStyle}> {isError ? "OOPS.. HA HABIDO UN ERROR" : "INSTRUCCIONES DE PAGO"} </Typography> */}
            <DialogContent style={{ padding: '5px 0px' }}>

                {isError ?
                    <ol>
                        <li><strong>Intenta comenzar el proceso de pago nuevamente por favor.</strong></li>
                    </ol>
                    :
                    <>
                        {methodPayment === 'qr' ?
                            false
                            // <ol>
                            //     <li><strong>Escanea el código QR que aparece en pantalla y paga ${amount} antes del {formattedDate}. </strong></li>
                            //     <li><strong>¡Listo! podrás proceder a canjear el eVoucher.</strong></li>
                            //     <li><strong>Recibirás un e-mail con las instrucciones a {email}.</strong></li>
                            // </ol>
                            :
                            <DialogContent id='btcConfRep' style={{ padding: '20px 15px', textAlign: 'center', fontFamily: 'Nunito Sans', fontSize: '17px', fontWeight: '400', lineHeight: '19.6px' }}>
                                <span>Debes indicar el número de operación {concepto} y realizar el pago.</span><br />
                            </DialogContent>
                            // <ol>
                            //     <li><strong>Paga ${amount} en tu sucursal de {methodPayment} más cercana, indicando el número de operación {concepto}, antes del {formattedDate}.</strong></li>
                            //     <li><strong>¡Listo! podrás proceder a canjear el eVoucher.</strong></li>
                            //     <li><strong>Recibirás un e-mail con las instrucciones a {email}.</strong></li>
                            // </ol>
                        }
                    </>
                }
            </DialogContent>
        </div>
        // </Grid>
    )
};



export const InitialFields = ({ DictConf, merchant, methodPayment }) => {

    const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value = text; document.body.appendChild(textArea); textArea.focus(); textArea.select(); try { document.execCommand('copy') } catch (err) { console.error('Unable to copy to clipboard', err) } document.body.removeChild(textArea) };

    const copyToClipboard = (e) => {
        const textField = e.target.tagName === 'path' ? e.target.parentNode.getAttribute('name') : e.target.name;
        unsecuredCopyToClipboard(textField)
        if (textField !== '') { toast.success(`Information Copied`, { theme: 'dark' }); }
    };

    const handleIconClick = (e) => { e.stopPropagation(); copyToClipboard(e) };

    return (
        <>

            {/* {!isMobile && <InstruccionesArg
                methodPayment={methodPayment}
                concepto={DictConf.formResponse.concepto}
                amount={DictConf.formData.amount}
                email={DictConf.formData.email}
                isError={DictConf.isError}
            />} */}

            <Grid item xs={6} style={{ ...mw50, textAlign: 'center', alignSelf: 'center', minWidth: isMobile ? '100%' : '', paddingTop: '0px' }} spacing={3}>

                {DictConf.isError ?
                    <div>
                        <DialogContent style={{ padding: '5px 0px' }}>
                            <ol>
                                <li><strong>Intenta comenzar el proceso de pago nuevamente por favor.</strong></li>
                            </ol>
                        </DialogContent>
                    </div>
                    :
                    <>
                        {methodPayment === 'qr' ?
                            <QRCodeComponent qrData={DictConf.formResponse.concepto} className="qrCode2" />
                            :
                            < div style={fldStl} >

                                <InstruccionesArg
                                    methodPayment={methodPayment}
                                    concepto={DictConf.formResponse.concepto}
                                    amount={DictConf.formData.amount}
                                    email={DictConf.formData.email}
                                    isError={DictConf.isError}
                                />

                                <TextField type="number" label="Nº de Operación" name="ReferenceCode" value={DictConf.formResponse.concepto} fullWidth disabled InputProps={{
                                    endAdornment: (<InputAdornment position="end">
                                        <Button style={isMobile ? cpbtstl : cpbtstlweb} variant="outlined" size="small" name={DictConf.formResponse.concepto} onClick={copyToClipboard} startIcon={<img src={CopyIcon} style={{ margin: '0px 0px 0px 5px' }} name={DictConf.formResponse.concepto} alt={DictConf.formResponse.concepto} onClick={handleIconClick} />} >
                                            {isMobile ? false : 'Copiar'}
                                        </Button>
                                    </InputAdornment>),
                                }} />
                            </div>
                        }
                    </>
                }

                {/* < FormButtons DictConf={DictConf} merchant={merchant} /> */}

            </Grid>

            <Grid item xs={6} style={{ ...mw50, textAlign: 'center', alignSelf: 'center', minWidth: isMobile ? '100%' : '' }} spacing={3}>
                
                {DictConf.isError ?
                    <div style={{ margin: isMobile ? '0px 0px 20px 0px' : '0px', ...spinnerContainer }}>
                        <div style={{...spinnerArea, backgroundColor: 'rgba(255, 99, 71, 0.2)'}}>
                            <span style={{ width: '60px', height: '60px' }} >
                                <img 
                                alt="Error" id='imageError' style={{ width: '100%', height: '100%', transform: 'scale(2)' }} 
                                src={"https://png.pngtree.com/png-vector/20221011/ourmid/pngtree-white-background-with-vector-icon-of-x-mark-in-flat-design-style-vector-png-image_25529198.png"} 
                                />
                            </span>
                        </div>
                    </div>
                    :

                    <div id='divToCenter'>

                        {isMobile ?
                            <DialogContent id='btcConfRep' style={{ padding: isMobile ? '5px 5px 20px 5px' : '15px 5px', textAlign: 'center', fontFamily: 'Nunito Sans', fontSize: '15px', fontWeight: '400', lineHeight: '14px' }}>
                                <span>La información de tu eVoucher estará disponible una vez el pago sea confirmado.</span><br />
                            </DialogContent>
                            :
                            false
                        }

                        <div style={{ margin: isMobile ? '0px 0px 20px 0px' : '0px', ...spinnerContainer }}>
                            <div style={spinnerArea}>
                                <span id='spinerArrow' className="MuiCircularProgress-root MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate" role="progressbar" style={{ width: '60px', height: '60px' }} >
                                    <img src={spinnerArrow} alt="Loading..." id='spinnerLoading' style={{ width: '100%', height: '100%', transform: 'scaleX(-1)' }} />
                                    <img src={icon6} alt="Confirmed" id='imagedConfirmed' style={{ width: '100%', height: '100%', display: 'none' }} />
                                </span>
                            </div>
                        </div>

                        {isMobile ? false :
                            <DialogContent id='btcConfRep' style={{ padding: '20px 15px', textAlign: 'center', fontFamily: 'Nunito Sans', fontSize: '17px', fontWeight: '400', lineHeight: '19.6px' }}>
                                <span>La información de tu eVoucher estará disponible una vez el pago sea confirmado.</span><br />
                            </DialogContent>
                        }

                    </div>
                }

            </Grid>



            {/* {isMobile && <InstruccionesArg
                methodPayment={methodPayment}
                concepto={DictConf.formResponse.concepto}
                amount={DictConf.formData.amount}
                email={DictConf.formData.email}
                isError={DictConf.isError}
            />} */}

        </>
    )
}

