import React, { useState, useEffect } from "react";
import { Button, Paper, Box } from "@mui/material";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserTable from "./TableUsers";
import ModalCreateUser from "./ModalCreateUser";
import SearchBar from './SearchBar';
import { LoadingOverlay } from "../Common/Common";

function Profile() {
    const config = { headers: { 'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`, } };
    const [users, setUsers] = useState([]);
    const [modalCreateUser, setModalCreateUser] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [allUsers, setAllUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
            try {
                const [usersRes, usersPermissionsRes] = await Promise.all(
                    [
                        axios.get(`${process.env.REACT_APP_API_URL}/v1/auth/users/`, config),
                        axios.get(`${process.env.REACT_APP_API_URL}/v1/auth/users-permissions/`, config),
                    ]
                );
                setUsers(usersRes.data);
                setAllUsers(usersPermissionsRes.data);
                await delay(500);
                setIsLoading(false);
            }
            catch (error) {
                toast.error(`There is no available data to show at the moment`, { theme: 'dark' });
                console.log(error);
            }
        };
        fetchData();
    }, [setUsers]);

    const openModalCreateUser = () => {
        setModalCreateUser(true);
    };

    return (
        <>
            <style>
                {`.MuiInputBase-root {
					max-height: 46px;
				}`}
            </style>

            {modalCreateUser && (
                <ModalCreateUser
                    setModalCreateUser={setModalCreateUser}
                    setUsers={setUsers}
                />
            )}

            {isLoading && < LoadingOverlay />}

            <Box sx={{ overflowX: 'hidden' }}>
                <Paper style={{ position: 'sticky', top: '0', zIndex: '2', marginBottom: '10px' }} >
                    <Row className='p-3 text-center' >
                        <Col lg={7}>
                            <h2 className="text-center" style={{ marginTop: '0.5rem' }}>REGISTERED USERS</h2>
                        </Col>
                        <Col lg={5} className="d-flex justify-content-between align-items-center">
                            <div style={{ flex: 1 }}>
                                <SearchBar value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <Button variant="contained" style={{ marginRight: '5px' }} onClick={openModalCreateUser}>
                                    Add User
                                </Button>
                            </div>
                        </Col>
                    </Row>

                </Paper>

                <div style={{ margin: '15px' }}>
                    <UserTable users={users} setUsers={setUsers} />
                </div>
            </Box>
        </>
    );

}

export default Profile;
