
import React, { useState, useEffect } from "react";
import { Edit } from "@mui/icons-material";
import { toast } from 'react-toastify'
import axios from 'axios';
import Modal from '../../Components/Modal/Modals/Modal';

import {
	Paper, Table, TableBody, TableHead, TableRow, TablePagination, IconButton
} from "@mui/material";

import {
	LoadingOverlay, Circle, CenteredTableCell, ScrollableTableContainer, formatCreationDate
} from '../Common/Common'


// const [customerSearch, setCustomerSearch] = useState("");
// const handleCustomerSearch = (event) => {setCustomerSearch(event.target.value);};

const TableArgentina = ({ users }) => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `JWT ${localStorage.getItem('access')}`,
		},
	};

	const [isLoading, setIsLoading] = useState(false);
	const [initialRender, setInitialRender] = useState(true);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [evnt, setEvnt] = useState();
	const [payments, setPayments] = useState(users);
	const [paginatedUsers, setPaginatedUsers] = useState(users.results);
	const [editData, setEditData] = useState({});
	const [modalEdit, setModalEdit] = useState(false);

	const searchField = document.getElementsByName('searchField')[0].value

	const handleModalEdit = (data) => {
		setEditData(data)
		setModalEdit(!modalEdit)
	};

	const fetchPageData = async (url) => {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`,
			},
		};
		const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

		try {
			setIsLoading(true);
			const response = await axios.get(`${url}`, config);
			setPayments(response.data);
			setPaginatedUsers(response.data.results);
			await delay(500);
			setIsLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	const fetchNewRowsPage = async () => {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `JWT ${localStorage.getItem('access')}`,
			},
		};

		const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

		try {
			setIsLoading(true);
			const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/merchants/${users.base_url}&page_size=${rowsPerPage}`, config);
			setPayments(response.data);
			setPaginatedUsers(response.data.results);
			await delay(500);
			setIsLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	const handleChangePage = (event, newPage) => {
		const KeyboardArrowRightIcon = document.querySelectorAll('[data-testid="KeyboardArrowRightIcon"]');
		const KeyboardArrowLeftIcon = document.querySelectorAll('[data-testid="KeyboardArrowLeftIcon"]');

		KeyboardArrowRightIcon[0].firstChild.style.pointerEvents = 'none';
		KeyboardArrowLeftIcon[0].firstChild.style.pointerEvents = 'none';

		event.stopPropagation();
		setEvnt(event);
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		const handleSearchChange = () => {
			const searchTermLower = searchField?.toLowerCase();

			const filteredResults = payments?.results?.filter((data) => {
				if (searchTermLower === '') {
					return data;
				}
				if (searchTermLower === 'ok') {
					return data.finalStatus?.toLowerCase() === searchTermLower;
				} else {
					return data.email?.toLowerCase()?.includes(searchTermLower) ||
						data.finalStatus?.toLowerCase()?.includes(searchTermLower) ||
						data.merchant?.toLowerCase()?.includes(searchTermLower) ||
						data.last_name?.toLowerCase()?.includes(searchTermLower) ||
						data.first_name?.toLowerCase()?.includes(searchTermLower) ||
						data.providerTxId?.toLowerCase()?.includes(searchTermLower) ||
						data.operation_id?.toLowerCase()?.includes(searchTermLower) ||
						data.doc_number?.toLowerCase()?.includes(searchTermLower) ||
						data.amount?.toLowerCase()?.includes(searchTermLower);
				}
			});

			setPaginatedUsers(filteredResults);
		};

		handleSearchChange();

	}, [searchField, page, rowsPerPage]);

	useEffect(() => {
		if (page === 0 && payments.previous === null) {
			return;
		}

		if (evnt.target.dataset.testid === "KeyboardArrowRightIcon" && payments.next !== null) {
			fetchPageData(payments.next);
		}
		if (evnt.target.dataset.testid === "KeyboardArrowLeftIcon" && payments.previous !== null) {
			fetchPageData(payments.previous);
		}

	}, [page, evnt]);

	useEffect(() => {
		if (initialRender) {
			setInitialRender(false);
			return;
		}
		fetchNewRowsPage();
	}, [rowsPerPage]);


	const SaveData = (fieldValue, activeState) => { }

	const handleInputChange = (data, fieldValue, activeState) => {
		console.log(data)
		console.log(fieldValue)
		console.log(activeState)
		const newData = { ...data, finalStatus: fieldValue }
		const newDataPayments = payments?.results?.map(d => d.operation_id === newData.operation_id ? newData : d)
		setEditData(newData)
		setPayments({...payments, results: newDataPayments})
		setPaginatedUsers(newDataPayments)

		axios.patch(`${process.env.REACT_APP_API_URL}/v1/merchants/ar/update-payment-status/${newData.operation_id}/`, {finalStatus: fieldValue }, config)
		.then((res) => {
			console.log(res.data)
		})
	};

	const createFields = {
		0: {
			FormField: {
				fieldName: 'email',
				fieldLabel: 'email',
				disabled: true,
			}
		},
		1: {
			FormField: {
				fieldName: 'amount',
				fieldLabel: 'amount',
				disabled: true,
			}
		},
		2: {
			FormActiveTransaction: {
				fieldName: 'finalStatus',
				fieldLabel: 'finalStatus',
			}
		},
	};

	const formConfig = {
		formTitle: 'Create Settlement',
		setModal: setModalEdit,
		handleInputChange: handleInputChange,
		initialData: editData,
		formData: editData,
		config: config,
		// setInfoData: setDataInfo,
		SaveData: SaveData,
		createFields: createFields
	};


	return (
		<>
			{modalEdit && <Modal formConfig={formConfig} />}

			<Table aria-label="collapsible table">
				<TableHead style={{ position: "sticky", top: 0, zIndex: 1 }} component={Paper}>
					<TableRow>
						<CenteredTableCell>Merchant</CenteredTableCell>
						<CenteredTableCell>Operation Id</CenteredTableCell>
						<CenteredTableCell>Payment Method</CenteredTableCell>
						<CenteredTableCell>Payment Code</CenteredTableCell>
						<CenteredTableCell>Email</CenteredTableCell>
						<CenteredTableCell>Amount</CenteredTableCell>
						<CenteredTableCell>Currency</CenteredTableCell>
						<CenteredTableCell>Creation Date</CenteredTableCell>
						<CenteredTableCell>Status</CenteredTableCell>
					</TableRow>
				</TableHead>


				{isLoading && (<LoadingOverlay />)}
				{/* // : ( */}
				<TableBody>
					{paginatedUsers?.map((user) => (
						<React.Fragment key={user.operation_id}>
							<TableRow>
								<CenteredTableCell>{user.shop.toUpperCase()}</CenteredTableCell>
								<CenteredTableCell>{user.operation_id}</CenteredTableCell>
								<CenteredTableCell>{user.payment_method}</CenteredTableCell>
								<CenteredTableCell>{user.providerTxId}</CenteredTableCell>
								<CenteredTableCell>{user.email}</CenteredTableCell>
								<CenteredTableCell>{user.amount}</CenteredTableCell>
								<CenteredTableCell>{'ARG'}</CenteredTableCell>
								<CenteredTableCell>{formatCreationDate(user.created_date)}</CenteredTableCell>
								<CenteredTableCell>
									<span style={{ display: 'flex', alignItems: 'center' }}>
										<Circle isTrue={user.finalStatus === 'OK'} />
										{user.finalStatus === 'OK' ? 'OK' : 'NOK'}
									</span>
								</CenteredTableCell>
								<CenteredTableCell>

									<IconButton aria-label="edit_record" size="medium" onClick={(e) => { handleModalEdit(user) }} >
										<Edit onClick={(e) => { handleModalEdit(user) }} />
									</IconButton>

								</CenteredTableCell>
							</TableRow>
						</React.Fragment>
					))}
				</TableBody>
				{/* )} */}
			</Table>

			<TablePagination
				style={{ position: 'sticky', bottom: '0%', background: 'white' }}
				rowsPerPageOptions={[10, 100, 500, 1000, 5000]}
				component="div"
				count={users.count}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</>
	);
};


// const TableArgentina = ({ users }) => {

// 	console.log(users)
// 	return (
// 		<>
// 			<TableHead style={{ position: "sticky", top: 0, zIndex: 1 }} component={Paper} >
// 				<TableRow>
// 					<CenteredTableCell>Merchant</CenteredTableCell>
// 					<CenteredTableCell>Operation Id</CenteredTableCell>
// 					<CenteredTableCell>Payment Method</CenteredTableCell>
// 					<CenteredTableCell>Payment Code</CenteredTableCell>
// 					<CenteredTableCell>Email</CenteredTableCell>
// 					<CenteredTableCell>Amount</CenteredTableCell>
// 					<CenteredTableCell>Currency</CenteredTableCell>
// 					<CenteredTableCell>Creation Date</CenteredTableCell>
// 					<CenteredTableCell>Status</CenteredTableCell>
// 				</TableRow>
// 			</TableHead>

// 			<TableBody>
// 				<>
// 					{users.map((user, index) => (<React.Fragment key={user.operation_id}>
// 						<TableRow>
// 							<CenteredTableCell> {user.shop} </CenteredTableCell>
// 							<CenteredTableCell> {user.operation_id} </CenteredTableCell>
// 							<CenteredTableCell> {user.payment_method} </CenteredTableCell>
// 							<CenteredTableCell> {user.providerTxId} </CenteredTableCell>
// 							<CenteredTableCell> {user.email} </CenteredTableCell>
// 							<CenteredTableCell> {user.amount} </CenteredTableCell>
// 							<CenteredTableCell> {'ARG'} </CenteredTableCell>
// 							<CenteredTableCell> {formatCreationDate(user.created_date)} </CenteredTableCell>
// 							<CenteredTableCell> <span style={{ display: 'flex', alignItems: 'center' }}> <Circle isTrue={user.finalStatus === 'OK'} /> {user.finalStatus === 'OK' ? 'OK' : 'NOK'} </span> </CenteredTableCell>
// 						</TableRow>

// 					</React.Fragment>))}
// 				</>
// 			</TableBody >
// 		</>
// 	)
// }


const PaymentTable = ({ users, provider, handleSearch }) => {

	const [openRowIndex, setOpenRowIndex] = useState(null)
	const [resourceName, setResourceName] = useState(null)
	const toInclude = ['resource', 'concepto_id', 'id_transaccion', 'numero_referencia', 'name', 'amount', 'numero_cuenta', 'institucion', 'nombre_emisor', 'numero_cuenta_emisor', 'institucion_emisor', 'updated_date']

	useEffect(() => { }, [setOpenRowIndex, openRowIndex])

	const handleRowClick = (e, index, user) => {
		(user.resource_name?.length !== 0
			? setOpenRowIndex((prevIndex) => index)
			: (() => {
				setResourceName((prevResourceName) => null);
				setOpenRowIndex((prevIndex) => null)
				toast.error(`There is no available data to show for this transaction`, { theme: 'dark' });
			})()
		);
		setResourceName(resourceName => user.resource_name)
	};

	return (
		<>
			<div style={{ margin: '15px' }}>
				<ScrollableTableContainer component={Paper}>

					{(provider === 'QR' || provider === 'RAPIPAGO' || provider === 'PAGOFACIL') ? < TableArgentina users={users} /> : false}

				</ScrollableTableContainer >
				<style> {`
				.modal-dialog { max-width: 800px !important; } 
				.custom-modal-content { max-height: 90vh; }
				.MuiTablePagination-root {  box-shadow: 0px 0px 5px 0px; border-top: 1px; left: 0px; overflow: hidden; }
				.MuiTablePagination-root > * > * {  margin-bottom: 7px; padding-bottom: 0px }
				.MuiTablePagination-root > div {  padding: 0px 0px; }
				.MuiTablePagination-root path {  pointer-events: none; }
				`} </style>
			</div >
		</>
	);
};

export default PaymentTable;

