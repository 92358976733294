import React, { useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import * as Icon from 'react-icons/bs';
import { connect } from 'react-redux';
import { logout } from '../../Actions/auth';
import Sidebar from 'react-bootstrap-sidebar-menu';
import './Sidebar2.scss';

const SidebarMp = ({ logout, theme }) => {

	const usr = JSON.parse(`${localStorage.getItem('userObj')}`);

	const [collapseOnSelect, setCollapseOnSelect] = useState(false);

	const onSelect = (eventKey) => {
		if (eventKey) document.getElementById(`${eventKey}`)?.scrollIntoView({ behavior: 'smooth' });
	};

	let isExpanded = localStorage.getItem('sidebarExpanded') === 'true' ? true : false;

	const setExpand = () => {
		isExpanded = localStorage.getItem('sidebarExpanded') === 'true' ? true : false;
		if (isExpanded) {
			localStorage.setItem('sidebarExpanded', 'false');
		} else {
			localStorage.setItem('sidebarExpanded', 'true');
		}
	};

	return (
		<>
			<Sidebar
				variant={theme}
				bg={theme}
				defaultExpanded={isExpanded}
				expand="lg"
				hide="md"
				collapseOnSelect={collapseOnSelect}
				onSelect={onSelect}
				onToggle={setExpand}
			>
				<Sidebar.Collapse>
					<Sidebar.Header>
						<Sidebar.Brand>
							<Link to="/" className="navbar-brand" style={{ color: 'white', paddingLeft: '20px' }}>
								<span className="text-primary">{`${process.env.REACT_APP_WEB_NAME}`}</span>
							</Link>
						</Sidebar.Brand>
						<Sidebar.Toggle />
					</Sidebar.Header>
					<Sidebar.Body>
						{usr.is_superuser === true ?
							<Sidebar.Nav>

									<LinkContainer to="/evoucher-web/evouchers">

										<Sidebar.Nav.Link eventKey="Home">
											<Sidebar.Nav.Icon>
												<Icon.BsBank />
											</Sidebar.Nav.Icon>
											<Sidebar.Nav.Title>Evouchers</Sidebar.Nav.Title>
										</Sidebar.Nav.Link>
									</LinkContainer>

								<LinkContainer to="/evoucher-web/providers">
									<Sidebar.Nav.Link eventKey="providers">
										<Sidebar.Nav.Icon>
											<Icon.BsCurrencyEuro />
										</Sidebar.Nav.Icon>
										<Sidebar.Nav.Title>Providers</Sidebar.Nav.Title>
									</Sidebar.Nav.Link>
								</LinkContainer>
								<LinkContainer to="/evoucher-web/merchants">
									<Sidebar.Nav.Link eventKey="merchants">
										<Sidebar.Nav.Icon>
											<Icon.BsPersonBadge />
										</Sidebar.Nav.Icon>
										<Sidebar.Nav.Title>Merchants</Sidebar.Nav.Title>
									</Sidebar.Nav.Link>
								</LinkContainer>
								<LinkContainer to="/evoucher-web/users">
									<Sidebar.Nav.Link eventKey="users">
										<Sidebar.Nav.Icon>
											<Icon.BsPersonLinesFill />
										</Sidebar.Nav.Icon>
										<Sidebar.Nav.Title>Users</Sidebar.Nav.Title>
									</Sidebar.Nav.Link>
								</LinkContainer>
							</Sidebar.Nav> :
							<Sidebar.Nav>
								<LinkContainer to="/evoucher-web/merchants">
									<Sidebar.Nav.Link eventKey="merchants">
										<Sidebar.Nav.Icon>
											<Icon.BsPersonBadge />
										</Sidebar.Nav.Icon>
										<Sidebar.Nav.Title>Merchants</Sidebar.Nav.Title>
									</Sidebar.Nav.Link>
								</LinkContainer>
							</Sidebar.Nav>
						}
					</Sidebar.Body>
					<Sidebar.Footer />
				</Sidebar.Collapse>
			</Sidebar>
		</>
	);
};

export default connect(null, { logout })(SidebarMp);
