import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../Actions/auth';
import { TextField, Button, Typography, Card, CardContent, Grid, Box, CircularProgress } from '@mui/material';

import backgroundImage from './bg.jpg';
import logo from './sampleAvatar.png';

const Login = ({ login, isAuthenticated }) => {
	const [isLoading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		email: '',
		password: ''
	});
	const { email, password } = formData;
	
	useEffect(() => { setLoading(false); }, []);

	const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			await login(email, password);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: '100vh',
				backgroundImage: `url(${backgroundImage})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center'
			}}
		>
			<Card sx={{ maxWidth: 400 }}>
				<CardContent>
					<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
						<img src={logo} alt="Logo" style={{ width: '150px' }} />
						<br />
						{isLoading ? (
							<div className="text-center">
								<h3>Loading...</h3>
							</div>) : (
							<Typography variant="h5" gutterBottom>

							</Typography>
						)}
					</Box>
					<form onSubmit={onSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									type="email"
									label="Email address"
									placeholder="Email"
									name="email"
									value={email}
									onChange={onChange}
									fullWidth
									required
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									type="password"
									label="Password"
									placeholder="Password"
									name="password"
									value={password}
									onChange={onChange}
									fullWidth
									minLength="6"
									required
								/>
							</Grid>
							<Grid item xs={12}>
								<Button variant="contained" color="primary" fullWidth type="submit">
									{isLoading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
								</Button>
							</Grid>
							<Grid item xs={12}>
								<Typography className="mt-3">
									Forgot your Password? <Link to="/reset-password">Reset Password</Link>
								</Typography>
							</Grid>
						</Grid>
					</form>
				</CardContent>
			</Card>
		</Box>
	);
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login);
