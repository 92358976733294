
import React, { useState } from 'react';
import { TextField, Grid } from '@mui/material';
import { mw50, mw100, mw75 } from '../Common/styles';
import { isMobile } from 'react-device-detect';
import { EmailButton, VocuherButton } from '../Common/EmailButton';



import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Switch from 'react-bootstrap/Switch'

import { toast } from 'react-toastify';
import { Form, Button } from 'react-bootstrap';


const FormSelect = ({ fieldLabel, fieldName, fieldValue, DictConf }) => {

    const countryCurrencyOptions = [
        { "AR": { "longName": "Argentina", "currencies": ["ARS", "USD"] } },
        { "BO": { "longName": "Bolivia", "currencies": ["BOB", "USD"] } },
        { "BR": { "longName": "Brazil", "currencies": ["BRL", "USD"] } },
        { "CL": { "longName": "Chile", "currencies": ["CLP", "USD"] } },
        { "CO": { "longName": "Colombia", "currencies": ["COP", "USD"] } },
        { "EC": { "longName": "Ecuador", "currencies": ["USD", "USD"] } },
        { "ID": { "longName": "Indonesia", "currencies": ["IDR", "USD"] } },
        { "MX": { "longName": "México", "currencies": ["MXN", "USD"] } },
        { "PN": { "longName": "Panama", "currencies": ["USD", "USD"] } },
        { "PE": { "longName": "Peru", "currencies": ["PEN", "USD"] } },
        { "PY": { "longName": "Paraguay", "currencies": ["PYG", "USD"] } },
        { "UY": { "longName": "Uruguay", "currencies": ["UYU", "USD"] } },
    ];

    const [currentCountry, setCurrentCountry] = useState(fieldValue);
    const [currentCurrency, setCurrentCurrency] = useState('');
    const [currentCurrencies, setCurrentCurrencies] = useState([]);

    const handleChange = (fieldName) => (e) => {
        
        const value = e?.target?.value || null;

        if (fieldName === 'country') {

            setCurrentCountry(value);

            const selectedCountryData = countryCurrencyOptions.find(
                (country) => Object.keys(country)[0] === value
            );
    
            if (selectedCountryData) {
                const { currencies } = selectedCountryData[value];
                setCurrentCurrency(currencies[0]);
                DictConf.setFormData({ ...DictConf.formData, 'currency': currencies[0] });
                console.log({ ...DictConf.formData, 'currency': currencies[0] })
                setCurrentCurrencies(currencies);
            } else {
                setCurrentCurrencies([]); // Reset if no country is selected
            }

            DictConf.setFormData({ ...DictConf.formData, [fieldName]: value });
        }
       

        if (fieldName === 'currency') {
            DictConf.setFormData({ ...DictConf.formData, [fieldName]: value });
        }
   
        const error = DictConf.validateField(fieldName, value);
        DictConf.setErrors({ ...DictConf.errors, [fieldName]: error });
    };

    

    return (
        <>
            <Col md={6} key={`${fieldName}-index`} style={{ paddingRight: '13px' }}>
                <Form.Group controlId={`${fieldName}-controlId`} key={fieldName}>
                    <Form.Label>{fieldLabel}</Form.Label>
                    <Form.Select
                        aria-label={`${fieldName}-controller`}
                        style={{ height: '100%' }}
                        value={currentCountry}
                        onChange={handleChange(fieldName)}
                    >
                        <option value="">Select Country</option>
                        {countryCurrencyOptions.map((d, index) => {
                            const countryName = Object.keys(d)[0];
                            const { shortName } = d[countryName];
                            return (
                                <option key={index} value={countryName}>
                                    {countryName} ({shortName})
                                </option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>
            </Col>


            <Col md={6} key={`${fieldName}-currency-index`} style={{ paddingLeft: '13px' }}>
                {currentCurrencies.length > 0 && (
                    <Form.Group controlId="currency-select">
                        <Form.Label>Available Currencies</Form.Label>
                        <Form.Select
                            aria-label="currency-select"
                            style={{ height: '100%' }}
                        >
                            {currentCurrencies.map((currency, index) => (
                                <option key={index} value={currency}>
                                    {currency}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                )}
            </Col>
        </>
    );
};


// const FormSelect = ({ fieldLabel, fieldName }) => {

//     const countryCurrencyOptions = [
//         {"Argentina": { "shortName": "AR", "currencies": ["ARS", "USD"] }},
//         {"Bolivia": { "shortName": "BO", "currencies": ["BOB", "USD"] }},
//         {"Brazil": { "shortName": "BR", "currencies": ["BRL", "USD"] }},
//         {"Chile": { "shortName": "CL", "currencies": ["CLP", "USD"] }},
//         {"Colombia": { "shortName": "CO", "currencies": ["COP", "USD"] }},
//         {"Ecuador": { "shortName": "EC", "currencies": ["USD", "USD"] }},
//         {"Indonesia": { "shortName": "ID", "currencies": ["IDR", "USD"] }},
//         {"México": { "shortName": "MX", "currencies": ["MXN", "USD"] }},
//         {"Panama": { "shortName": "PN", "currencies": ["USD", "USD"] }},
//         {"Peru": { "shortName": "PE", "currencies": ["PEN", "USD"] }},
//         {"Paraguay": { "shortName": "PY", "currencies": ["PYG", "USD"] }},
//         {"Uruguay": { "shortName": "UY", "currencies": ["UYU", "USD"] }},
//     ]

//     const [currentValue, setCurrentValue] = useState()

//     const onChange = (e) => {
//         console.log(e.target.value)
//         console.log(e.target.name)

//         setCurrentValue(e.target.value)

//     }


//     return (
//         <Form.Group controlId={`${fieldName}-controlId`} key={fieldName}>

//             <Form.Select
//                 aria-label={`${fieldName}-controller`}
//                 style={{ height: '100%' }}
//                 value={currentValue}
//                 onChange={(e) => onChange(e)}
//             >
//                 <option value="all">Categorías</option>

//                 {countryCurrencyOptions?.map((d) => (
//                     <option key={d.id} value={d.id}>
//                         {d.shortName}
//                     </option>
//                 ))}

//             </Form.Select>
//         </Form.Group>
//     );
// };



const FormField = ({ typeField = 'type', typeValue = 'text', fieldLabel, fieldName, fieldValue, onChange, error, DictConf }) => {
    const dynamicAttributes = {
        [typeField]: (
            (fieldName === 'amount' || fieldName === 'docNumber') ? 'number' : (
                (fieldName === 'phone') ? 'number' : (
                    (fieldName === 'email') ? 'email' : typeValue)
            )
        ),
        placeholder: fieldLabel,
        name: fieldName,
        value: fieldValue,
        required: true,
        onChange
    };

    if (fieldName === 'active') {
        return (
            <Form.Group controlId="Switchcontrol" className="mb-3" key={fieldName}>
                <Form.Label>{fieldLabel}</Form.Label>
                <br />
                <Switch checked={fieldValue} onChange={onChange} />
            </Form.Group>
        );
    }

    if (fieldName === 'country') {
        return (
            // <FormSelect fieldName={fieldName} activeId={activeId} onChange={onChange} optionsObject={optionsObject} />
            <FormSelect fieldName={fieldName} fieldLabel={fieldLabel} fieldValue={fieldValue} DictConf={DictConf} />
        );
    }

    if (fieldName === 'currency') {
        return
        // <FormSelect fieldName={fieldName} activeId={activeId} onChange={onChange} optionsObject={optionsObject} />
        // <FormSelect fieldName={fieldName}  />


    }

    return (
        <Form.Group className="mb-3" key={fieldName} style={{ textAlign: 'left' }}>
            <Form.Label style={{ fontSize: '12px' }}>{fieldLabel}</Form.Label>
            <Form.Control {...dynamicAttributes} isInvalid={!!error} />
            <Form.Control.Feedback type="invalid">
                {error}
            </Form.Control.Feedback>
        </Form.Group>
    );
};


const ReturnForm = ({ DictConf }) => {

    const handleChange = (fieldName) => (e) => {
        const value = e?.target?.value || null;

        console.log(e?.target)

        if (fieldName === 'amount') {
            DictConf.setFormData({ ...DictConf.formData, [e.target.name]: parseFloat(value).toFixed(2) });
        }
        else {
            DictConf.setFormData({ ...DictConf.formData, [fieldName]: value });
        }
        const error = DictConf.validateField(fieldName, value);
        DictConf.setErrors({ ...DictConf.errors, [fieldName]: error });
    };

    // const handleChangeDrop = ({fieldName, dropValue}) => {
    //     const value = e?.target?.value || null;
    //     if (fieldName === 'amount') {
    //         DictConf.setFormData({ ...DictConf.formData, [e.target.name]: parseFloat(value).toFixed(2) });
    //     }
    //     if (fieldName === 'currency') {
    //         DictConf.setFormData({ ...DictConf.formData, [fieldName]: dropValue });
    //     } 
    //     if (fieldName === 'country') {

    //         console.log(dropValue)
    //         console.log(DictConf.formData)
    //         console.log(DictConf.formData[fieldName])

    //         DictConf.setFormData({ ...DictConf.formData, [fieldName]: dropValue });
    //     } 
    //     else {
    //         DictConf.setFormData({ ...DictConf.formData, [fieldName]: value });
    //     }
    //     const error = DictConf.validateField(fieldName, value);
    //     DictConf.setErrors({ ...DictConf.errors, [fieldName]: error });
    // };

    const labels = {
        country: "País en el que Pagas",
        // currency: "Moneda de Pago",
        amount: "Monto a Pagar",
        email: "Ingresa tu Email",
    };

    const entries = Object.entries(DictConf.formData);

    return (
        <Form>
            <Row>
                {entries.map(([key, value], index) => (
                    <Col md={key === 'country' ? 12 : 6} key={index} style={{ 'display': (key === 'currency' ? 'none' : (key === 'country' ? 'flex' : '')) }}>
                        <FormField
                            fieldLabel={labels[key]}
                            fieldName={key}
                            fieldValue={value}
                            onChange={(key === 'country' || key === 'currency') ? handleChange(key) : handleChange(key)}
                            error={DictConf.errors[key]}
                            DictConf={DictConf}

                        />
                    </Col>
                ))}
            </Row>
        </Form>
    );
};




export const EmailField = ({ DictConf }) => {

    const handleAmountChange = (e) => {
        let value = e.target.value;
        if (value) {
            // value = parseFloat(value).toFixed(2);
            console.log(parseFloat(value).toFixed(2))
        };
        DictConf.setFormData({ ...DictConf.formData, [e.target.name]: e.target.value });
    };

    const handleFormDataChange = (e) => {
        DictConf.setFormData({ ...DictConf.formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            {/* {isMobile ? false :
                <Grid item xs={6} style={{ ...mw50, textAlign: 'center' }} spacing={3}>
                    <div>
                        <Typography variant="h2" gutterBottom style={subTitleStyle}> INSTRUCCIONES DE PAGO </Typography>

                        <DialogContent style={{ padding: '5px 0px' }}>
                            <ol>
                                <li><strong>Ingresa el monto a pagar.</strong></li>
                                <li><strong>Recibirás un Código {DictConf.formData.paymentMethod == 'qr' ? 'QR de MERCADO PAGO, con el cual deberás realizar el pago en tu Aplicación Móvil.' : `de ${DictConf?.formData?.paymentMethod?.toUpperCase()}, con el que deberás acercarte a un Punto de Pago.`}</strong></li>
                                <li><strong>Una vez confirmado el pago, la información del eVoucher se encontrará disponible.</strong></li>
                            </ol>
                        </DialogContent>
                    </div>
                </Grid>
            } */}

            <Grid item xs={12} style={{ ...mw100, textAlign: 'center', alignSelf: 'center', minWidth: isMobile ? '100%' : '' }} spacing={3}>

                {/* <Grid item xs={12} style={{ maxWidth: isMobile ? '100%' : '99%', textAlign: 'center' }} spacing={3}>
                    <div style={{ marginBottom: '15px' }}>
                        <TextField type='number' label="Ingresa el Monto a Pagar" name="amount" value={DictConf.formData.amount} step="1.00" onChange={handleAmountChange} fullWidth error={false} /></div>
                </Grid> */}

                <ReturnForm DictConf={DictConf} />


                < EmailButton DictConf={DictConf} methodPayment={DictConf.methodPayment} />

                {/* {isMobile ?

                    <div>
                        <Typography variant="h2" gutterBottom style={subTitleStyle}> INSTRUCCIONES DE PAGO </Typography>

                        <DialogContent style={{ padding: '5px 0px' }}>
                            <ol>
                                <li><strong>Ingresa el monto a pagar.</strong></li>
                                <li><strong>Recibirás un Código {DictConf.formData.paymentMethod == 'qr' ? 'QR de MERCADO PAGO, con el cual deberás realizar el pago en tu Aplicación Móvil.' : `de ${DictConf?.formData?.paymentMethod?.toUpperCase()}, con el que deberás acercarte a un Punto de Pago.`}</strong></li>
                                <li><strong>Una vez confirmado el pago, la información del eVoucher se encontrará disponible.</strong></li>
                            </ol>
                        </DialogContent>
                    </div>

                    : false
                } */}

            </Grid>
        </>
    );

};



export const VoucherField = ({ DictConf }) => {
    let operationId

    if (window.location.pathname.split('/')[window.location.pathname.split('/').length - 2] !== 'formpgar') {
        operationId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
    }
    return (
        <>
            <Grid item xs={12} style={{ ...mw100, textAlign: 'center', alignSelf: 'center', minWidth: isMobile ? '100%' : '' }} spacing={3}>

                <Grid item xs={12} style={{ textAlign: 'center', display: isMobile ? '' : 'flex', gap: '4%', justifyContent: 'center' }} spacing={3}>

                    <Grid item xs={6} style={{ maxWidth: isMobile ? '100%' : '50%', textAlign: 'center' }} spacing={3}>
                        <div style={{ marginBottom: '15px' }}><TextField type="text" label="Ingresa Tu Nº de eVoucher" name="operation_id" value={operationId ? operationId : DictConf.formData.operation_id} onChange={DictConf.onChange} fullWidth required /></div>
                    </Grid>

                </Grid>
                < VocuherButton DictConf={DictConf} methodPayment={DictConf.formData.paymentMethod} />

            </Grid>
        </>
    );

};



