
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { overlayStyle, overlayStyleCircleIcon, overlayStyleCircleTop } from '../Common/styles';
import { VocuherCollectForm } from './EmailCollectFormAr';
import { VoucherFormAr } from '../Common/WaitingForm';
import axios from 'axios';

import { changeTextFillColorToWhite } from '../Common/themeSelector';
import { cardTransitionStyles } from '../Common/cardStyles'
import { ProgressVoucher } from '../Common/ProgressSteps'

import WebAssets from "../Assets/WebAssets";

const { icon5, icon7 } = WebAssets;

const decryptParams = async ({ encryptedParams, setFormData, setMerchant, setMethodPayment }) => {

    let pathArray

    if (window.location.pathname.split('/')[window.location.pathname.split('/').length - 2] === 'formpgar') {
        pathArray = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
    }
    else {
        pathArray = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
    }

    const decodedParams = atob(pathArray);

    const [param1, param2, param3, param4, param5, param6, param7] = decodedParams.split(';');
    const data = { docNumber: param3, paymentMethod: param1, firstName: param4, lastName: param5, email: param6, shop: param2 };

    setFormData(data)
    setMerchant(param2);
    setMethodPayment(param1);
};


function WebFormVoucherDlocalGo() {

    let pathArray
    let operationId

    if (window.location.pathname.split('/')[window.location.pathname.split('/').length - 2] === 'formpgar') {
        pathArray = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
    }
    else {
        pathArray = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
        operationId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
    }

    const [formStatus, setFormStatus] = useState('INICIAL')
    const [amount, setAmount] = useState(1);

    const [merchant, setMerchant] = useState(null);
    const [methodPayment, setMethodPayment] = useState(null);
    const [email, setEmail] = useState();
    const [isLoading, setLoading] = useState(false);
    const [paymentLink, setPaymentLink] = useState();
    const [formSent, setFormSent] = useState(false);
    const [isError, setIsError] = useState();
    const [formData, setFormData] = useState({});
    const [cardStyles, setCardStyles] = useState({ width: isMobile ? '90vw' : '55vw', height: 'auto', minWidth: isMobile ? '90vw' : '55vw', minHeight: isMobile ? '40vh' : '20vh', maxHeight: '80vh', });
    const [circleStyles, setCircleStyle] = useState({ width: isMobile ? '50px' : '50px', height: '50px', minWidth: '50px', minHeight: isMobile ? '50px' : '50px', maxHeight: '50px', });

    const [colorTheme, setColorTheme] = useState('WHITE')

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const [formResponse, setFormResponse] = useState({ concepto: '', paymentMethod: '', operation_id: operationId });

    useEffect(() => {

        changeTextFillColorToWhite('WHITE', setColorTheme)

        decryptParams({
            encryptedParams: pathArray,
            setFormData: setFormData,
            setMerchant: setMerchant,
            setMethodPayment: setMethodPayment,
        });

    }, []);

    const { param1, param2 } = useParams();

    useEffect(() => {
        if (formStatus === 'INICIAL') {
            setCardStyles((prevStyles) => ({ ...prevStyles, minHeight: isMobile ? '40vh' : '10vh', maxHeight: '80vh', }));
        }
    }, [formStatus, setFormStatus, pathArray]);


    const createPaymentLink = () => {
        setCardStyles((prevStyles) => ({ ...prevStyles, minHeight: isMobile ? '40vh' : '45vh', maxHeight: '80vh', }));

        setFormResponse({
            concepto: '32424564324',
            paymentMethod: methodPayment,
            amount: '100.00',
            operation_id: operationId ? operationId : formData.operation_id
        });

        setIsError(false);
        setFormSent(true);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setFormStatus('RECIBIDO');
        setLoading(false);
    };

    const reloadForm = (e) => {
        setLoading(true);
        setEmail('');
        setFormStatus('INICIAL');
        setFormResponse({ concepto: null, paymentMethod: null, operation_id: null });

        const emailCollectionDiv = document.getElementsByClassName('emailCollection')[0];
        const confirmFormDiv = document.getElementsByClassName('waitingConfirmation')[0];

        emailCollectionDiv.classList.remove('hideEmailCollect');
        emailCollectionDiv.classList.add('showEmailCollect');
        confirmFormDiv.classList.remove('showWaitingConfirmation');
        confirmFormDiv.classList.add('hideWaitingConfirmation');

        const icon_1_line = document.getElementById('icon_line_1')
        const icon_2 = document.getElementById('icon2')
        
        icon_2.style.filter = 'grayscale(1)'
        icon_1_line.style.borderTop = '5px solid white'

        setIsError(false);
        setFormSent(false);
        setLoading(false);
    };


    const onEmailCollect = async (e) => {
        e.preventDefault();
        setLoading(true);
        await createPaymentLink();
        setFormStatus('RECIBIDO');
        setLoading(false);

        const emailCollectionDiv = document.getElementsByClassName('emailCollection')[0];
        const confirmFormDiv = document.getElementsByClassName('waitingConfirmation')[0];

        emailCollectionDiv.classList.add('hideEmailCollect');
        emailCollectionDiv.classList.remove('showEmailCollect');
        confirmFormDiv.classList.add('showWaitingConfirmation');
        confirmFormDiv.classList.remove('hideWaitingConfirmation');

    };

    const onClickChangeColor = () => {
        changeTextFillColorToWhite(colorTheme, setColorTheme)
    }

    const conf = {
        onSubmit: onSubmit,
        onChange: onChange,
        setEmail: setEmail,
        onEmailCollect: onEmailCollect,
        paymentLink: paymentLink,
        reloadForm: reloadForm,
        formSent: formSent,
        isError: isError,
        isLoading: isLoading,
        formStatus: formStatus,
        formData: formData,
        setFormStatus: setFormStatus,
        setAmount: setAmount,
        setFormData: setFormData,
        formResponse: formResponse,
        email: email,
        methodPayment: methodPayment
    }

    return (
        <>
            <style>{cardTransitionStyles()}</style>

            <Box sx={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh',
                background: "linear-gradient(107.15deg, #082001 0%, #2aa604 56%, #75fb4c 100%)"
            }}
            >

                <Card sx={circleStyles} style={overlayStyleCircleTop} onClick={onClickChangeColor}>
                    <img src={colorTheme === 'WHITE' ? icon5 : icon7} style={overlayStyleCircleIcon} />
                </Card>

                <div style={{ display: 'grid', maxHeight: isMobile ? '80vh' : '100vh' }}>

                    <ProgressVoucher circleStyles={circleStyles} />

                    <div style={{ display: 'grid', maxHeight: isMobile ? '72vh' : '100vh' }}>
                        <Card sx={cardStyles} style={overlayStyle}>

                            {['qr', 'rapipago', 'pagofacil'].includes(methodPayment) ?
                                <>
                                    <div className={`emailCollection showEmailCollect`}> <VocuherCollectForm DictConf={conf} merchant={merchant} /> </div>
                                    <div className={`waitingConfirmation hideWaitingConfirmation`}> <VoucherFormAr DictConf={conf} merchant={merchant} /> </div>
                                </> :
                                false
                            }

                        </Card>
                    </div>
                </div>
            </Box >
        </>
    );
}

export default WebFormVoucherDlocalGo;




