import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { toast } from "react-toastify";
import { TextField, Button, Grid, InputAdornment, Typography, DialogContent } from '@mui/material';
import { mw50, mw100, fldStl, cpbtstl, buttonStyleYell, cpbtstlweb, subTitleStyle2, spinnerArea, spinnerContainer, coupon, couponContainer, couponH3, couponH4 } from './styles';
// import { mw50, fldStl, cpbtstl, buttonStyleYell, cpbtstlweb, subTitleStyle2, spinnerArea, spinnerContainer } from './styles';
import { ModalContent } from "./ModalQr"

import QRCodeComponent from './QrGenerator'
import WebAssets from "../Assets/WebAssets";
import { Scale } from '@mui/icons-material';

const { icon6, CopyIcon, spinnerArrow } = WebAssets;

export const ConfirmationFields = ({ DictConf, merchant }) => {

    const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value = text; document.body.appendChild(textArea); textArea.focus(); textArea.select(); try { document.execCommand('copy') } catch (err) { console.error('Unable to copy to clipboard', err) } document.body.removeChild(textArea) };

    const copyToClipboard = (e) => {
        const textField = e.target.tagName === 'path' ? e.target.parentNode.getAttribute('name') : e.target.name;
        unsecuredCopyToClipboard(textField)
        if (textField !== '') { toast.success(`Information Copied`, { theme: 'dark' }); }
    };

    const handleIconClick = (e) => { e.stopPropagation(); copyToClipboard(e) };

    return (
        <>
            <Grid id='confContainer' item xs={12} style={{ padding: isMobile ? '5px 10px' : '15px 10px', ...mw50, marginTop: isMobile ? '-15px' : '0px' }}>

                <div id='divToCenter'>
                    <Typography id='confSbtlRm' variant="h2" gutterBottom style={subTitleStyle2}> VERIFICACIÓN DEL PAGO </Typography>

                    {isMobile ?
                        <DialogContent id='btcConfRep' style={{ padding: isMobile ? '5px 5px 20px 5px' : '15px 5px', textAlign: 'center', fontFamily: 'Nunito Sans', fontSize: '14px', fontWeight: '400', lineHeight: '14px' }}>
                            Una vez confirmado, te redireccionaremos a la página de {merchant}
                        </DialogContent>
                        :
                        false
                    }

                    <div style={{ margin: isMobile ? '0px 0px 20px 0px' : '0px', ...spinnerContainer }}>
                        <div style={spinnerArea}>
                            <span id='spinerArrow' className="MuiCircularProgress-root MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate" role="progressbar" style={{ width: '60px', height: '60px' }} >
                                <img src={spinnerArrow} alt="Loading..." id='spinnerLoading' style={{ width: '100%', height: '100%', transform: 'scaleX(-1)' }} />
                                <img src={icon6} alt="Confirmed" id='imagedConfirmed' style={{ width: '100%', height: '100%', display: 'none' }} />
                            </span>
                        </div>
                    </div>

                    {isMobile ? false :
                        <DialogContent id='btcConfRep' style={{ padding: '20px 15px', textAlign: 'center', fontFamily: 'Nunito Sans', fontSize: '15px', fontWeight: '400', lineHeight: '19.6px' }}>
                            Una vez confirmado, te redireccionaremos a la página de {merchant}
                        </DialogContent>
                    }

                    <Button id='btnConfShow' variant="contained" color="primary" fullWidth type="submit" sx={buttonStyleYell} style={{ display: 'none', marginTop: isMobile ? '10px' : '20px', background: '#0BC177', maxWidth: isMobile ? '100%' : '70%' }} > Vovler a Comenzar </Button>

                </div>

            </Grid>

            <Grid item xs={12} style={mw50} spacing={3} id='confFldsRm'>

                < div style={fldStl} >
                    <TextField type="number" label="Monto a Pagar" name="amount" value={DictConf.formData.amount} fullWidth required disabled InputProps={{
                        endAdornment: (<InputAdornment position="end">
                            <Button style={isMobile ? cpbtstl : cpbtstlweb} variant="outlined" size="small" name={DictConf.formData.amount} onClick={copyToClipboard} startIcon={<img src={CopyIcon} style={{ margin: '0px 0px 0px 5px' }} name={DictConf.formData.amount} alt={DictConf.formData.amount} onClick={handleIconClick} />} >
                                {isMobile ? false : 'Copiar'}
                            </Button>
                        </InputAdornment>),
                    }} />
                </div >

                < div style={fldStl} >
                    <TextField type="string" label="Concepto del Pago" name="ReferenceCode" value={DictConf.formResponse.concepto} fullWidth required disabled InputProps={{
                        endAdornment: (<InputAdornment position="end">
                            <Button style={isMobile ? cpbtstl : cpbtstlweb} variant="outlined" size="small" name={DictConf.formResponse.concepto} onClick={copyToClipboard} startIcon={<img src={CopyIcon} style={{ margin: '0px 0px 0px 5px' }} name={DictConf.formResponse.concepto} alt={DictConf.formResponse.concepto} onClick={handleIconClick} />} >
                                {isMobile ? false : 'Copiar'}
                            </Button>
                        </InputAdornment>),
                    }} />
                </div>

                < div style={fldStl} >
                    <TextField type="string" label="Número CLABE" name="BankDetails" value={DictConf.formResponse.account_send} fullWidth required disabled InputProps={{
                        endAdornment: (<InputAdornment position="end">
                            <Button style={isMobile ? cpbtstl : cpbtstlweb} variant="outlined" size="small" name={DictConf.formResponse.account_send} onClick={copyToClipboard} startIcon={<img src={CopyIcon} style={{ margin: '0px 0px 0px 5px' }} name={DictConf.formResponse.account_send} onClick={handleIconClick} />} >
                                {isMobile ? false : 'Copiar'}
                            </Button>
                        </InputAdornment>),
                    }} />
                </div>

                < div style={fldStl} >
                    <TextField type="string" label="Nombre del Receptor" name="name_owner" value={DictConf.formResponse.holderName} fullWidth required disabled InputProps={{
                        endAdornment: (<InputAdornment position="end">
                            <Button style={isMobile ? cpbtstl : cpbtstlweb} variant="outlined" size="small" name={DictConf.formResponse.holderName} onClick={copyToClipboard} startIcon={<img src={CopyIcon} style={{ margin: '0px 0px 0px 5px' }} name={DictConf.formResponse.holderName} onClick={handleIconClick} />} >
                                {isMobile ? false : 'Copiar'}
                            </Button>
                        </InputAdornment>),
                    }} />
                </div>
            </Grid>
        </>
    )
};

export const ConfirmationFieldsAr = ({ DictConf, merchant }) => {

    const [isOpen, setIsopen] = useState(false);
    const showModal = () => setIsopen((prev) => !prev);

    const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value = text; document.body.appendChild(textArea); textArea.focus(); textArea.select(); try { document.execCommand('copy') } catch (err) { console.error('Unable to copy to clipboard', err) } document.body.removeChild(textArea) };

    const copyToClipboard = (e) => {
        const textField = e.target.tagName === 'path' ? e.target.parentNode.getAttribute('name') : e.target.name;
        unsecuredCopyToClipboard(textField)
        if (textField !== '') { toast.success(`Information Copied`, { theme: 'dark' }); }
    };

    const handleIconClick = (e) => { e.stopPropagation(); copyToClipboard(e) };

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const onRedeem = () => {
        const pathArray = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
        return openInNewTab(`/redeem/formpgar/${pathArray}/${DictConf.formResponse.operation_id}`)
    }

    return (
        <>
            {/* <Grid id='confContainer' item xs={12} style={{ padding: isMobile ? '5px 10px' : '15px 10px', ...mw50, marginTop: isMobile ? '-15px' : '0px', alignSelf: 'center' }}> 

                <div id='divToCenter'>

                    {isMobile ?
                        <DialogContent id='btcConfRep' style={{ padding: isMobile ? '5px 5px 20px 5px' : '15px 5px', textAlign: 'center', fontFamily: 'Nunito Sans', fontSize: '15px', fontWeight: '400', lineHeight: '14px' }}>
                            <span>La información de tu eVoucher estará disponible una vez el pago sea confirmado.</span><br />
                        </DialogContent>
                        :
                        false
                    }

                    <div style={{ margin: isMobile ? '0px 0px 20px 0px' : '0px', ...spinnerContainer }}>
                        <div style={spinnerArea}>
                            <span id='spinerArrow' className="MuiCircularProgress-root MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate" role="progressbar" style={{ width: '60px', height: '60px' }} >
                                <img src={spinnerArrow} alt="Loading..." id='spinnerLoading' style={{ width: '100%', height: '100%' }} />
                                <img src={icon6} alt="Confirmed" id='imagedConfirmed' style={{ width: '100%', height: '100%', display: 'none' }} />
                            </span>
                        </div>
                    </div>

                    {isMobile ? false :
                        <DialogContent id='btcConfRep' style={{ padding: '20px 15px', textAlign: 'center', fontFamily: 'Nunito Sans', fontSize: '17px', fontWeight: '400', lineHeight: '19.6px' }}>
                            <span>La información de tu eVoucher estará disponible una vez el pago sea confirmado.</span><br />
                        </DialogContent>
                    }

                    <Button id='btnConfShow' variant="contained" color="primary" fullWidth type="submit" sx={buttonStyleYell} style={{ display: 'none', marginTop: isMobile ? '10px' : '20px', background: '#0BC177', maxWidth: isMobile ? '100%' : '70%' }} > Vovler a Comenzar </Button>

                </div> 

            </Grid> */}


            <Grid item xs={12} style={{ textAlign: 'center', alignSelf: 'center', paddingTop: '0px' }} spacing={3} id='confFldsRm'>

                <Grid item xs={12} style={{ textAlign: 'center', display: isMobile ? '' : 'flex', gap: '4%' }} spacing={3}>

                    <div style={coupon} id='coupon'>
                        <div style={couponContainer} className='bd2remove'>
                            <h3 style={couponH3}>
                                {DictConf.formStatus === 'CONFIRMED' ? 'Información del eVoucher' : 'Confirmando el Pago'}

                            </h3>
                        </div>

                        {/* <img
                            src={"https://cdn.apuestashouse.com/img/apuestashouse.svg"}
                            alt={"Avatar"}
                            style={{ width: '100%', backgroundColor: 'black', padding: '25px 25px' }}
                        /> */}

                        <div>

                            <h4 style={couponH4}>
                                <b>Valor: ${DictConf.formData.amount} ARG</b><br />
                            </h4>
                            <h4 style={couponH4}>
                                {DictConf.formStatus === 'CONFIRMED' && <b>Nº del eVoucher: {DictConf.formResponse.concepto}</b>}
                            </h4>

                        </div>

                        <div style={couponContainer} className='bd2remove'>

                            <span>Método de Pago: {DictConf.methodPayment}</span><br />
                            
                            {DictConf.methodPayment !== 'dlocalgo' &&
                                <>
                                    <span>Email: {DictConf.formData.email}</span><br />
                                    <span>Nombre: {DictConf.formData.firstName} {DictConf.formData.lastName}</span><br />
                                    <span>DNI: {DictConf.formData.docNumber}</span><br />
                                </>
                            }
                            {/* {(DictConf.formStatus !== 'CONFIRMED' && DictConf.methodPayment !== 'qr') && <span>Nº de Operación: {DictConf.formResponse.concepto}</span>} */}
                            {(DictConf.formStatus === 'CONFIRMED' && DictConf.methodPayment !== 'qr') && <span>Nº de Operación: {DictConf.formResponse.operation_id}</span>}
                        </div>

                        {/* // <QRCodeComponent qrData={'00020101021243650016com.mercadolibre02013063642875ada-476d-4587-8946-8f7293713b4b50150011307180633095204970053030325802AR5924Rixsus payment solutions6004CABA63046763'} className="qrCode1" /> */}

                        <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 css-15j76c0"
                            style={{ maxWidth: '100%', textAlign: 'center', padding: '10px' }}>

                            <Button
                                startIcon={(DictConf.methodPayment === 'qr' || DictConf.formStatus === 'CONFIRMED') ? false : <img src={CopyIcon} style={{ height: '20px', width: '20px', margin: '0px 0px 0px 5px' }} name={DictConf.formResponse.concepto} alt={DictConf.formResponse.concepto} onClick={handleIconClick} />}
                                name={DictConf.formResponse.concepto}
                                id='btnVocuher'
                                variant="contained"
                                sx={buttonStyleYell}
                                onClick={
                                    DictConf.formStatus === 'CONFIRMED' ? onRedeem : (DictConf.methodPayment === 'qr' ? showModal : copyToClipboard)
                                }
                                style={{ marginTop: isMobile ? '10px' : '0px', background: '#0BC177', maxWidth: isMobile ? '100%' : '70%' }}
                            >
                                {DictConf.formStatus === 'CONFIRMED' ? 'Canjear eVoucher' : (DictConf.methodPayment === 'qr' ? 'Mostrar QR' : 'Copiar Nº de Op.')}
                            </Button>

                            {isOpen && (
                                <ModalContent onClose={() => setIsopen(false)}>
                                    <QRCodeComponent qrData={DictConf.formResponse.concepto} className="qrCode1" />
                                </ModalContent>
                            )}
                        </div>

                    </div>

                </Grid>

            </Grid >
        </>
    )
};


const transition1 = () => {
    const icon_2 = document.getElementById('icon2')
    const icon_1_line = document.getElementById('icon_line_1')
    const divToCenter = document.getElementById('divToCenter')
    const coupon = document.getElementById('coupon')
    const textVerif = document.getElementById('textVerif')

    icon_2.classList.add('transition');
    icon_1_line.classList.add('transition');
    divToCenter.classList.add('transition');
    textVerif.classList.add('transition');
    coupon.classList.add('transition');

    icon_2.style.filter = ''
    icon_1_line.style.borderTop = '5px solid #004955'
    divToCenter.style.display = 'ruby'
    textVerif.style.display = ''
    coupon.style.display = 'none'
};



export const VoucherFieldsAr = ({ DictConf, merchant }) => {

    const validateVoucher = () => {
        transition1();
    };

    return (
        <>

            <Grid item xs={12} style={{ ...mw50, textAlign: 'center', alignSelf: 'center', paddingTop: '0px' }} spacing={3} id='confFldsRm'>

                <Grid item xs={12} style={{ textAlign: 'center', display: isMobile ? '' : 'flex', gap: '4%', justifyContent: 'center' }} spacing={3}>

                    <div id='divToCenter' style={{ display: 'none' }}>

                        <div style={{ margin: isMobile ? '0px 0px 20px 0px' : '0px', ...spinnerContainer }}>
                            <div style={spinnerArea}>
                                <span role="progressbar" style={{ width: '60px', height: '60px' }} >
                                    <img src={icon6} alt="Confirmed" id='imagedConfirmed' style={{ width: '100%', height: '100%' }} />
                                </span>
                            </div>
                        </div>


                        <Button onClick={DictConf.reloadForm} id='btnConfShow' variant="contained" color="primary" fullWidth type="submit" sx={buttonStyleYell} style={{ marginTop: isMobile ? '10px' : '20px', background: '#0BC177', maxWidth: isMobile ? '100%' : '100%', padding: '5px 30px' }} > Vovler a Comenzar </Button>

                    </div>

                    <div style={{ ...coupon, marginTop: '20px' }} id='coupon'>
                        <div style={couponContainer} className='bd2remove'>
                            <h3 style={couponH3}>
                                Información del eVoucher </h3>
                        </div>

                        <div>

                            <h4 style={couponH4}>
                                <b>Valor: ${DictConf.formResponse.amount} ARG</b><br />
                            </h4>
                            <h4 style={couponH4}>
                                <b>Nº del eVoucher: {DictConf.formResponse.operation_id}</b>
                            </h4>

                        </div>

                        <div style={couponContainer} className='bd2remove'>

                            <span>Método de Pago: {DictConf.formData.paymentMethod}</span><br />

                            {DictConf.methodPayment !== 'dlocalgo' &&
                                <>
                                    <span>Email: {DictConf.formData.email}</span><br />
                                    <span>Nombre: {DictConf.formData.firstName} {DictConf.formData.lastName}</span><br />
                                    <span>DNI: {DictConf.formData.docNumber}</span><br />
                                </>
                            }
                            {(DictConf.methodPayment !== 'qr') && <span>Nº de Operación: {DictConf.formResponse.concepto}</span>}
                        </div>

                        <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 css-15j76c0"
                            style={{ maxWidth: '100%', textAlign: 'center', padding: '10px' }}>

                            <Button
                                id='btnVocuher'
                                variant="contained"
                                sx={buttonStyleYell}
                                onClick={validateVoucher}
                                style={{ marginTop: isMobile ? '10px' : '0px', background: '#0BC177', maxWidth: isMobile ? '100%' : '70%' }}
                            >
                                Canjear eVoucher
                            </Button>

                        </div>

                    </div>

                </Grid>

            </Grid >
        </>
    )
}

