
import React, { useState, useEffect } from 'react';
import { Card, Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { overlayStyle, overlayStyleCircleIcon, overlayStyleCircleTop } from '../Common/styles';
import { InitialForm } from './InitialFormAr';
import { EmailCollectForm } from './EmailCollectFormAr';
import { WaitingFormAr } from '../Common/WaitingForm';
import axios from 'axios';
import { toast } from 'react-toastify'

import { changeTextFillColorToWhite } from '../Common/themeSelector';
import { cardTransitionStyles } from '../Common/cardStyles'
import { ProgressStepsAr } from '../Common/ProgressSteps'

import WebAssets from "../Assets/WebAssets";

const { icon5, icon7 } = WebAssets;

const fetchIpAddress = async ({ setIpAddress }) => {
    try {
        const response = await axios.get('https://api64.ipify.org?format=json');
        const ipAddress = response.data.ip;
        setIpAddress(ipAddress);

    } catch (error) {
        console.error('Error fetching IP address:', error);
    }
};


const transition1 = () => {
    const icon_2 = document.getElementById('icon2')
    const icon_1_line = document.getElementById('icon_line_1')

    icon_2.classList.add('transition');
    icon_1_line.classList.add('transition');

    icon_2.style.filter = ''
    icon_1_line.style.borderTop = '5px solid #004955'
}

const transition2 = () => {
    const icon_3 = document.getElementById('icon3')
    icon_3.classList.add('transition');
    icon_3.style.filter = ''

    const icon_2_line = document.getElementById('icon_line_2')
    icon_2_line.classList.add('transition');
    icon_2_line.style.borderTop = '5px solid #004955'

    const initialFormDiv = document.getElementsByClassName('initialFields')[0];
    const confirmFormDiv = document.getElementsByClassName('waitingConfirmation')[0];

    initialFormDiv.classList.remove('showInitialFields');
    initialFormDiv.classList.add('hideInitialFields');
    confirmFormDiv.classList.remove('hideWaitingConfirmation');
    confirmFormDiv.classList.add('showWaitingConfirmation');
}
const transition3 = () => {

    const confFldsRm = document.getElementById('confFldsRm');
    const confSbtlRm = document.getElementById('confSbtlRm');
    const confContainer = document.getElementById('confContainer');
    const btnConfShow = document.getElementById('btnConfShow');
    const btcConfRep = document.getElementById('btcConfRep');
    const spinnerLoading = document.getElementById('spinnerLoading');
    const imagedConfirmed = document.getElementById('imagedConfirmed');
    const spinerArrow = document.getElementById('spinerArrow');
    const textVerif = document.getElementById('textVerif');

    const divToCenter = document.getElementById('divToCenter');

    textVerif.innerText = 'El Pago ha sido Verificado!';
    divToCenter.style.textAlign = 'center';

    const icon_4 = document.getElementById('icon4');
    const icon_3 = document.getElementById('icon3');
    const icon_2 = document.getElementById('icon2');

    const icon_3_line = document.getElementById('icon_line_3');
    icon_3_line.classList.add('transition');
    icon_3_line.style.borderTop = '5px solid #004955';

    icon_4.classList.add('transition');

    confFldsRm.classList.add('transition');
    confContainer.classList.add('transition');
    btnConfShow.classList.add('transition');
    btcConfRep.classList.add('transition');
    spinnerLoading.classList.add('transition');
    imagedConfirmed.classList.add('transition');
    spinerArrow.className = '';
    spinerArrow.classList.add('transition');

    // Change styles
    icon_4.style.filter = ''

    // confFldsRm.style.display = 'none';
    btcConfRep.style.display = 'none';
    spinnerLoading.style.display = 'none';
    imagedConfirmed.style.display = '';
    btnConfShow.style.display = '';
    // confContainer.style.maxWidth = '100%';
}
const transition4 = () => {

}

function WebFormDlocalGo() {
    const pathArray = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];

    const [amount, setAmount] = useState(1);
    const [paymentLink, setPaymentLink] = useState();

    const [formStatus, setFormStatus] = useState('INICIAL')
    const [formSent, setFormSent] = useState(false);

    const [merchant, setMerchant] = useState(null);
    const [methodPayment, setMethodPayment] = useState(null);

    const [email, setEmail] = useState();
    const [ipAddress, setIpAddress] = useState('192.168.0.1');

    const [isLoading, setLoading] = useState(false);
    const [isError, setIsError] = useState();
    const [errors, setErrors] = useState({});

    // const [formData, setFormData] = useState({ docNumber: '93041103546', paymentMethod: methodPayment, firstName: 'formData.firstName', lastName: 'formData.lastName', amount: '100.00', email: 'formData.email@email.com', ipAddress: ipAddress, address: address, shop: merchant });

    const countryCurrencyOptions = {
        "Argentina": { "shortName": "AR", "currencies": ["ARS", "USD"] },
        "Bolivia": { "shortName": "BO", "currencies": ["BOB", "USD"] },
        "Brazil": { "shortName": "BR", "currencies": ["BRL", "USD"] },
        "Chile": { "shortName": "CL", "currencies": ["CLP", "USD"] },
        "Colombia": { "shortName": "CO", "currencies": ["COP", "USD"] },
        "Ecuador": { "shortName": "EC", "currencies": ["USD", "USD"] },
        "Indonesia": { "shortName": "ID", "currencies": ["IDR", "USD"] },
        "México": { "shortName": "MX", "currencies": ["MXN", "USD"] },
        "Panama": { "shortName": "PN", "currencies": ["USD", "USD"] },
        "Peru": { "shortName": "PE", "currencies": ["PEN", "USD"] },
        "Paraguay": { "shortName": "PY", "currencies": ["PYG", "USD"] },
        "Uruguay": { "shortName": "UY", "currencies": ["UYU", "USD"] },
    }

    const [formData, setFormData] = useState({
        country: "AR",
        currency: "ARS",
        amount: "110.00",
        email: "test@test.com",
    });

    const [formResponse, setFormResponse] = useState({ concepto: '', paymentMethod: '', operation_id: '' });

    const [cardStyles, setCardStyles] = useState({ width: isMobile ? '90vw' : '55vw', height: 'auto', minWidth: isMobile ? '90vw' : '55vw', minHeight: isMobile ? '40vh' : '20vh', maxHeight: '80vh', });
    const [circleStyles, setCircleStyle] = useState({ width: isMobile ? '50px' : '50px', height: '50px', minWidth: '50px', minHeight: isMobile ? '50px' : '50px', maxHeight: '50px', });

    const [colorTheme, setColorTheme] = useState('WHITE')

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });


    useEffect(() => {

        changeTextFillColorToWhite('WHITE', setColorTheme)

        fetchIpAddress({
            setIpAddress: setIpAddress
        });

        setMerchant(window.location.pathname.split('/').slice(-2, -1)[0]);
        setMethodPayment(window.location.pathname.split('/').slice(-1)[0]);
        // setMethodPayment('pagofacil');
        // setMerchant('pagofacil');

        // decryptParams({
        //     encryptedParams: pathArray,
        //     setFormData: setFormData,
        //     setMerchant: setMerchant,
        //     setMethodPayment: setMethodPayment,
        //     ipAddress: ipAddress
        // });

    }, []);


    useEffect(() => {
        if (formStatus === 'INICIAL') {
            setCardStyles((prevStyles) => ({ ...prevStyles, minHeight: isMobile ? '40vh' : '10vh', maxHeight: '80vh', }));
        }
    }, [formStatus, setFormStatus, pathArray]);

    const validateField = (fieldName, value) => {
        let error = '';
        switch (fieldName) {
            case 'amount':
                if (!value) error = 'El monto es requerido.';
                break;
            case 'email':
            case 'country':
            case 'currency':
                if (!value) error = 'Este campo es requerido.';
                break;
            default:
                break;
        }
        return error;
    };

    const createPaymentLink = async () => {

        try {
            let data = {
                "payment_method": methodPayment,
                "amount": formData.amount,
                "country": formData.country,
                "currency": formData.currency,
                "shop": merchant
            };

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/v1/merchants/ar/${methodPayment}/recurring-payments/`,
                headers: { 'Content-Type': 'application/json', },
                data: data
            };

            const response = await axios.request(config);

            // const testData = {
            //     providerTxId: '312312421312',
            //     payment_method: 'pagofacil',
            //     email: 'test@teast.com',
            //     operation_id: '213213112' ,
            // }

            // if (await testData.providerTxId !== "") {
            //     setFormResponse({
            //         concepto: testData.providerTxId,
            //         paymentMethod: testData.payment_method,
            //         email: testData.email,
            //         operation_id: testData.operation_id
            //     });

            if (await response.data.providerTxId !== "") {

                setFormResponse({
                    concepto: response.data.providerTxId,
                    paymentMethod: response.data.payment_method,
                    operation_id: response.data.operation_id
                });

                setFormStatus('CONFIRM');
                setIsError(false)
                setFormSent(true)
            } else {
                console.log('url payment link ERROR');
                setFormStatus('ERROR');
                setIsError(true);
                setFormSent(true)
            }

            setCardStyles((prevStyles) => ({ ...prevStyles, minHeight: isMobile ? '40vh' : '45vh', maxHeight: '80vh', }));
            transition1();

        } catch (error) { setIsError(true); setFormSent(true); console.log(error); }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setFormStatus('RECIBIDO');
        setLoading(false);
    };

    const reloadForm = (e) => {
        setLoading(true);
        setEmail('');
        setFormStatus('INICIAL');
        setFormResponse({ concepto: null, paymentMethod: null, operation_id: null });

        const emailCollectionDiv = document.getElementsByClassName('emailCollection')[0];
        const initialFormDiv = document.getElementsByClassName('initialFields')[0];
        const confirmFormDiv = document.getElementsByClassName('waitingConfirmation')[0];

        emailCollectionDiv.classList.remove('hideEmailCollect');
        emailCollectionDiv.classList.add('showEmailCollect');
        initialFormDiv.classList.remove('showInitialFields');
        initialFormDiv.classList.add('hideInitialFields');
        confirmFormDiv.classList.remove('showWaitingConfirmation');
        confirmFormDiv.classList.add('hideWaitingConfirmation');

        setIsError(false);
        setFormSent(false);
        setLoading(false);
    };

    const onConfirmationForm = (e) => {
        setLoading(true);
        setFormStatus('CONFIRM');
        transition2();
        setLoading(false);
    };

    const onEmailCollect = async (e) => {

        console.log(formData)
        e.preventDefault();

        setLoading(true);

        const errors = {};
        const fieldsToValidate = ['country', 'currency', 'amount', 'email'];

        fieldsToValidate.forEach(field => {
            const error = validateField(field, formData[field]);
            if (error) {
                errors[field] = error;
            }
        });

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            setLoading(false);
            return;
        }

        await createPaymentLink();

        setLoading(false);

        const emailCollectionDiv = document.getElementsByClassName('emailCollection')[0];
        const initialFormDiv = document.getElementsByClassName('initialFields')[0];

        emailCollectionDiv.classList.add('hideEmailCollect');
        emailCollectionDiv.classList.remove('showEmailCollect');
        initialFormDiv.classList.add('showInitialFields');
        initialFormDiv.classList.remove('hideInitialFields');

    };

    const checkPaymentStatus = () => {
        const config = { headers: { 'Content-Type': 'application/json' } };

        axios.get(`${process.env.REACT_APP_API_URL}/v1/merchants/ar/${methodPayment}/recurring-payments/?c=${formResponse.operation_id}`, config)
            .then((res) => {
                if (res.data[0].finalStatus === 'OK') {
                    toast.success('El pago ha sido confirmado, procede a canjear tu eVoucher')

                    const btnConfShow = document.getElementById('btnConfShow');
                    transition2();
                    setFormStatus('CONFIRMED');

                    btnConfShow.addEventListener('click', function () {
                        reloadForm();

                        const spinnerLoading = document.getElementById('spinnerLoading');
                        const imagedConfirmed = document.getElementById('imagedConfirmed');
                        const spinerArrow = document.getElementById('spinerArrow');

                        const icon_3_line = document.getElementById('icon_line_3')
                        const icon_2_line = document.getElementById('icon_line_2')
                        const icon_1_line = document.getElementById('icon_line_1')

                        const icon_3 = document.getElementById('icon3')
                        const icon_2 = document.getElementById('icon2')

                        spinnerLoading.style.display = '';
                        imagedConfirmed.style.display = 'none';
                        btnConfShow.style.display = 'none';
                        spinerArrow.classList.add("MuiCircularProgress-root", "MuiCircularProgress-colorPrimary", "MuiCircularProgress-indeterminate");

                        icon_3.style.filter = 'grayscale(1)'
                        icon_2.style.filter = 'grayscale(1)'

                        icon_3_line.style.borderTop = '5px solid white'
                        icon_2_line.style.borderTop = '5px solid white'
                        icon_1_line.style.borderTop = '5px solid white'
                    });

                    // reloadForm();
                }
            })
            .catch(error => console.error('Error checking payment status:', error));
    };


    useEffect(() => {
        let intervalId;
        if (formStatus === 'CONFIRM') {
            intervalId = setInterval(() => {
                checkPaymentStatus();
            }, 10000);
        }
        return () => clearInterval(intervalId);
    }, [formStatus]);


    const onClickChangeColor = () => {
        changeTextFillColorToWhite(colorTheme, setColorTheme)
    }

    const conf = {
        onSubmit: onSubmit,
        onChange: onChange,
        setEmail: setEmail,
        onEmailCollect: onEmailCollect,
        onConfirmationForm: onConfirmationForm,
        paymentLink: paymentLink,
        reloadForm: reloadForm,
        formSent: formSent,
        isError: isError,
        isLoading: isLoading,
        formStatus: formStatus,
        formData: formData,
        setFormStatus: setFormStatus,
        setAmount: setAmount,
        setFormData: setFormData,
        formResponse: formResponse,
        email: email,
        methodPayment: methodPayment,
        validateField: validateField,
        errors: errors,
        setErrors: setErrors,
    }

    return (
        <>
            <style>{cardTransitionStyles()}</style>

            <Box sx={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh',
                background: "linear-gradient(107.15deg, #082001 0%, #2aa604 56%, #75fb4c 100%)"
            }}
            >
                <Card sx={circleStyles} style={overlayStyleCircleTop} onClick={onClickChangeColor}>
                    <img src={colorTheme === 'WHITE' ? icon5 : icon7} style={overlayStyleCircleIcon} />
                </Card>

                <div style={{ display: 'grid', maxHeight: isMobile ? '80vh' : '100vh' }}>

                    <ProgressStepsAr circleStyles={circleStyles} />

                    <div style={{ display: 'grid', maxHeight: isMobile ? '72vh' : '100vh' }}>
                        <Card sx={cardStyles} style={overlayStyle}>

                            {['dlocalgo'].includes(methodPayment) ?
                                <>
                                    <div className={`emailCollection showEmailCollect`}> <EmailCollectForm DictConf={conf} merchant={merchant} /> </div>
                                    <div className={`initialFields hideInitialFields`}> <InitialForm DictConf={conf} merchant={merchant} methodPayment={methodPayment} /> </div>
                                    <div className={`waitingConfirmation hideWaitingConfirmation`}> <WaitingFormAr DictConf={conf} merchant={merchant} /> </div>
                                </> :
                                false
                            }

                        </Card>
                    </div>
                </div>
            </Box >
        </>
    );
}

export default WebFormDlocalGo;




