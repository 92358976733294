import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset_password } from '../../Actions/auth';
import { TextField, Button, Typography, Card, CardContent, Grid, Box } from '@mui/material';
import backgroundImage from './/bg.jpg';
import logo from './sampleAvatar.png';

const ResetPassword = ({ reset_password }) => {

	const [requestSent, setRequestSent] = useState(false);

	const [formData, setFormData] = useState({
		email: ''
	});

	const { email } = formData;

	const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = (e) => {
		e.preventDefault();
		reset_password(email);
		setRequestSent(true);
	};

	if (requestSent) {
		return <Navigate replace to="/login" />;
	}

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: '100vh',
				backgroundImage: `url(${backgroundImage})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center'
			}}
		>
			<Card sx={{ maxWidth: 400 }}>
				<CardContent>
					<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
						<img src={logo} alt="Logo" style={{ width: '150px' }} />
						<br />
						<Typography variant="h5" gutterBottom>
							Forgot your Password?
						</Typography>
						<Typography variant="subtitle1" gutterBottom>
							Request a recovery Mail!
						</Typography>
					</Box>
					<form onSubmit={onSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									type="email"
									label="Email address"
									placeholder="Email"
									name="email"
									value={email}
									onChange={onChange}
									fullWidth
									required
								/>
							</Grid>
							<Grid item xs={12}>
								<Button variant="contained" color="primary" fullWidth type="submit">
									Request Password Reset
								</Button>
							</Grid>
							<Grid item xs={12}>
								<Typography className="mt-3">
									Already have an account? <Link to="/login">Sign in</Link>
								</Typography>
							</Grid>
						</Grid>
					</form>
				</CardContent>
			</Card>
		</Box>
	);
};

export default connect(null, { reset_password })(ResetPassword);
