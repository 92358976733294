import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { checkAuthenticated, load_user } from '../Actions/auth';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Components/Navbar/Navbar';
import SidebarMp from "../Components/Sidebar/Sidebar";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Style.css'

// console.log = console.warn = console.error = () => { };

const Layout = ({ checkAuthenticated, load_user, children }) => {

    const [user, setUser] = useState(JSON.parse(`${localStorage.getItem('userObj')}`) || null)
    const theme = "dark";
    const navigate = useNavigate();
    const [needUser, setNeedUser] = useState(false)

    const [pageTitle, setTitle] = useState([])

    useEffect(() => {
        const fetchUser = async () => {
            try {
                await load_user();
                setUser(JSON.parse(localStorage.getItem('userObj')) || null);
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };
        // if (needUser === true) {
        //     fetchUser();
        // }
        fetchUser();
    }, [checkAuthenticated, load_user, navigate]);

    useEffect(() => {
        if (user) {
            if (user.is_active === true && window.location.pathname === '/evoucher-web/Login' || window.location.pathname === '/evoucher-web') {
                navigate('/evoucher-web/merchants');
            } else if (user.is_active === true && window.location.pathname !== '/evoucher-web/Login') {
                navigate(window.location.pathname);
            } else if (window.location.pathname.startsWith('/evoucher-web/redeem/formp') || window.location.pathname.startsWith('/evoucher-web/pay/formp') || window.location.pathname.startsWith('/evoucher-web/pay/formksf') || window.location.pathname.startsWith('/evoucher-web/pay/formpl')) {
                return navigate(window.location.pathname);
            }
        } else {
            if (window.location.pathname.startsWith('/evoucher-web/redeem/formp') || window.location.pathname.startsWith('/evoucher-web/pay/formp') || window.location.pathname.startsWith('/evoucher-web/pay/formksf') || window.location.pathname.startsWith('/evoucher-web/pay/formpl')) { return navigate(window.location.pathname); }
            if (window.location.pathname.startsWith('/evoucher-web/redeem/') || window.location.pathname.startsWith('/evoucher-web/pay/') ) { return navigate(window.location.pathname); }
            // if (!usr && window.location.pathname.startsWith('/pay/formpp') || window.location.pathname.startsWith('/pay/formpl')) { return navigate(window.location.pathname); }
            else {
                return navigate('/evoucher-web/Login');
            }
        }
    }, [user, navigate]);



    if (window.location.pathname.startsWith('/evoucher-web/redeem/formp') || window.location.pathname.startsWith('/evoucher-web/pay/formp') || window.location.pathname.startsWith('/evoucher-web/pay/formksf') || window.location.pathname.startsWith('/evoucher-web/pay/formpl')) {
        // setNeedUser(false)
        return <>{children}</>;
    }

    if (window.location.pathname.startsWith('/evoucher-web/pay/') || window.location.pathname.startsWith('/evoucher-web/redeem/')) {
        // setNeedUser(false)
        return <>{children}</>;
    }

    if (window.location.pathname === '/evoucher-web/Login' || window.location.pathname === '/evoucher-web/reset-password' || window.location.pathname.startsWith('/evoucher-web/password/reset/confirm')) {
        // setNeedUser(true)
        return <>{children}</>;
    }


    else {

        return (
            <>
                <div className="main-wrapper main-wrapper-responsive-lg ltr">

                    {user &&
                        <>
                            <Navbar title={pageTitle} />
                            <SidebarMp theme={theme} />
                        </>
                    }

                    <ToastContainer />

                    <main className="main-container container-fluid">
                        {children}
                    </main>
                </div>
            </>
        );
    }


};

export default connect(null, { checkAuthenticated, load_user })(Layout);