import React, { useState, useEffect } from "react";

import {
	Paper, Table, TableBody, CircularProgress, 
	TableCell, TableContainer, TableHead, TableRow,
	styled, TablePagination, IconButton
} from "@mui/material";
import { Edit} from "@mui/icons-material";


import { toast } from 'react-toastify'
import axios from 'axios';
import Modal from '../../Components/Modal/Modals/Modal';

export const LoadingOverlay = () => {
	const loadingOverlayStyle = {
		width: '100vw',
		height: '100vh',
		background: 'rgba(0, 0, 0, 0.75)',
		zIndex: '9990',
		position: 'absolute',
		top: '0',
		left: '0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	};

	return (
		<div style={loadingOverlayStyle}>
			<CircularProgress size={60} style={{ color: 'white' }} />
		</div>
	);
}

export const Circle = styled("div")(({ theme, isTrue }) => ({ width: 16, height: 16, borderRadius: "50%", backgroundColor: isTrue ? theme.palette.success.main : theme.palette.error.main, margin: "0px 10px " }));

export const CenteredTableCell = styled(TableCell)({ textAlign: "center" });

export const ScrollableTableContainer = styled(TableContainer)({ maxHeight: "calc(100vh - 210px)", overflowY: "auto" });

export const formatCreationDate = (dateString) => { return new Date(dateString).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }); };

export const  getMonthName = (dateString) => {
	const date = new Date(dateString);
	return new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
};