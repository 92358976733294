import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AiOutlineEdit, AiOutlinePlusSquare, AiOutlineDelete } from 'react-icons/ai';
import { FaUserCheck, FaLink } from "react-icons/fa";
import Modal from '../../Components/Modal/Modals/Modal';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { Paper, TextField, Box, CircularProgress } from "@mui/material";
import { toast } from 'react-toastify';
import { LoadingOverlay } from '../Common/Common';


function Merchants() {

	const config = {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `JWT ${localStorage.getItem('access')}`,
		}
	};

	const config2 = {
		headers: {
			'Content-Type': 'multipart/form-data',
			'Authorization': `JWT ${localStorage.getItem('access')}`,
		}
	};

	const usr = JSON.parse(`${localStorage.getItem('userObj')}`);
	const [isLoading, setIsLoading] = useState(false);
	const [dataInfo, setDataInfo] = useState([]);
	const [allUsers, setAllUsers] = useState([]);
	const [modalAdd, setModalAdd] = useState(false);
	const [modalEdit, setModalEdit] = useState(false);
	const [modalDelete, setModalDelete] = useState(false);
	const [modalUsers, setModalUsers] = useState(false);
	const [idSelected, setIdSelected] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');
	const [modalLinks, setModalLinks] = useState(false);

	useEffect(() => {
		setIsLoading(true)
		const fetchData = async () => {
			const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
			try {
				const [merchantsRes, usersPermissionsRes] = await Promise.all(
					[
						axios.get(`${process.env.REACT_APP_API_URL}/v1/merchants/merchants/`, config),
						axios.get(`${process.env.REACT_APP_API_URL}/v1/auth/users-permissions/`, config),
					]
				);
				setDataInfo(merchantsRes.data);
				setAllUsers(usersPermissionsRes.data);
				await delay(500);
				setIsLoading(false);
			}
			catch (error) {
				toast.error(`There is no available data to show at the moment`, { theme: 'dark' });
				console.log(error);
			}
		};
		fetchData();
	}, [setDataInfo, setAllUsers]);

	const SaveData = (formData) => {
		setIsLoading(true)
		axios.post(`${process.env.REACT_APP_API_URL}/v1/merchants/merchants/`, formData, config2
		).then((res) => {
			if (res.status >= 400) {
				setModalAdd(false);
				setIsLoading(false);
				toast.error(`Server did not respond: Error ${res.status}`, { theme: 'dark' });
			}
			else {
				const merchant_id = res.data.merchant_id;
				axios.post(`${process.env.REACT_APP_API_URL}/v1/merchants/merchant-payment-method/`, { 'merchant_id': merchant_id }, config
				).then((r) => {
					axios.get(`${process.env.REACT_APP_API_URL}/v1/merchants/merchants/`, config
					).then((resp) => {
						setDataInfo(resp.data);
						setModalAdd(false);
						setIsLoading(false);
						toast.success(`${formData.merchant_name} added to the database successfully`, { theme: 'dark' });
					});
				});
			};
		}).catch((err) => {
			console.log(err);
			setModalAdd(false);
			setIsLoading(false);
			toast.error(`Server did not respond: Error ${err}`, { theme: 'dark' });
		});
	};

	// const onLinkCreating = (formData) => {
	// 	const dataForm = { "amount": formData.amount, "customer_project": "ALBO", "merchantId_EPB": formData.merchantId_EPB, "payMethod": "albp2p", "userId": usr?.email }
	// 	axios.post(`${process.env.REACT_APP_API_URL}/v1/merchants/mx/albo/get-links-merchant/`, dataForm, config).then((res) => {
	// 		if (res.status >= 400) { toast.error(`Server did not respond: Error ${res.status}`, { theme: 'dark' }); }
	// 		else { toast.success(`Link added to the database successfully`, { theme: 'dark' }); }
	// 	}).catch((err) => { console.log(err); toast.error(`Server did not respond: Error ${err}`, { theme: 'dark' }); });
	// };

	const updateData = (formData) => {
		setIsLoading(true);
		const data = formData?.image ? { 'merchant_name': formData.merchant_name, 'merchant_description': formData.merchant_description, 'active': formData.active, 'image': formData.image } : { 'merchant_name': formData.merchant_name, 'merchant_description': formData.merchant_description, 'active': formData.active }
		axios.patch(`${process.env.REACT_APP_API_URL}/v1/merchants/merchants/${formData.merchant_id}/`, data, config2).then(res => {
			if (res.status >= 400) { toast.error(`Server did not respond: Error ${res.status}`, { theme: 'dark' }); }
			else {
				axios.get(`${process.env.REACT_APP_API_URL}/v1/merchants/merchants/`, config
				).then(resp => {
					setDataInfo(resp.data);
					setModalEdit(false);
					setIsLoading(false);
					toast.success(`${data.merchant_name} Updated successfully`, { theme: 'dark' });
				});
			};
		}).catch(err => { console.log(err); setModalEdit(false); setIsLoading(false); });
	};

	const deleteData = (formData) => {
		fetch(`${process.env.REACT_APP_API_URL}/v1/merchants/merchants/` + formData.merchant_id + '/', {
			method: "DELETE",
			headers: new Headers({ 'Authorization': `JWT ${localStorage.getItem('access')}` })
		}).then(res => {
			if (res.status >= 400) { toast.error(`Server did not respond : Error ${res.status}`, { theme: 'dark', }); }
			else { toast.success(`The Merchant ${formData.merchant_name} has been removed from the Database!`, { theme: "dark" }); };
			axios.get(`${process.env.REACT_APP_API_URL}/v1/merchants/merchants/`, config).then(resp => { setDataInfo(resp.data) });
			setModalDelete(false);
		}).catch(err => { console.log(err) });
	};

	const updateUsersIds = (formData) => {
		let data = JSON.stringify({ "users": formData.users });
		axios.patch(`${process.env.REACT_APP_API_URL}/v1/merchants/merchants/${formData.merchant_id}/`, data, config).then(res => {
			if (res.status >= 400) { toast.error(`Server did not respond: Error ${res.status}`, { theme: 'dark' }); }
			else { toast.success(`Permissions Updated successfully`, { theme: 'dark' }); }
			axios.get(`${process.env.REACT_APP_API_URL}/v1/merchants/merchants/`, config).then(resp => { setDataInfo(resp.data); });
		}).catch(err => { console.log(err); toast.error(`Server did not respond: Error ${err}`, { theme: 'dark' }); });
	};

	const onEdit = (data) => { setIdSelected(data); setModalEdit(true); };
	const onDelete = (data) => { setIdSelected(data); setModalDelete(true); };
	const onUsers = (data) => { setIdSelected(data); setModalUsers(true); };
	const handleSearch = (event) => { setSearchTerm(event.target.value); };

	// const onLink = (data) => { setIdSelected(data); setModalLinks(true); };
	// const createLinkMerchant = (data) => { onLinkCreating(data); setModalLinks(false) }

	// const formConfigLinks = {
	// 	formTitle: 'Create Link',
	// 	setModal: setModalLinks,
	// 	setInfoData: setDataInfo,
	// 	SaveData: createLinkMerchant,
	// 	allUsers: undefined,
	// 	createFields: idSelected,
	// 	initialData: { "customer_project": "", "merchantId_EPB": "", "payMethod": "", "userId": "", "amount": '' },
	// 	method: 'LINKS'
	// }

	const formConfig = {
		formTitle: 'Create Merchant',
		setModal: setModalAdd,
		setInfoData: setDataInfo,
		SaveData: SaveData,
		createFields: {
			0: { FormField: { fieldName: 'merchant_name', fieldLabel: 'Merchant Name', } },
			1: { FormField: { fieldName: 'merchant_description', fieldLabel: 'Merchant Description', typeField: 'as', typeValue: 'textarea' } },
			2: { FormImage: {} },
			3: { FormActive: {} },
		},
		allUsers: undefined,
	};

	const formConfigEdit = {
		formTitle: 'Edit Merchant',
		setModal: setModalEdit,
		setInfoData: setDataInfo,
		SaveData: updateData,
		initialData: idSelected,
		createFields: {
			0: { FormField: { fieldName: 'merchant_name', fieldLabel: 'Merchant Name', } },
			1: { FormField: { fieldName: 'merchant_description', fieldLabel: 'Merchant Description', typeField: 'as', typeValue: 'textarea' } },
			2: { FormImage: {} },
			3: { FormActive: {} },
		},
		allUsers: undefined,
	};

	const formConfigDelete = {
		formTitle: 'Delete Merchant',
		setModal: setModalDelete,
		setInfoData: setDataInfo,
		SaveData: deleteData,
		initialData: idSelected,
		method: 'DELETE',
		allUsers: undefined,
	};

	const formConfigUsers = {
		formTitle: 'Merchant Users',
		setModal: setModalUsers,
		setInfoData: setDataInfo,
		SaveData: updateUsersIds,
		initialData: idSelected,
		allUsers: allUsers,
		createFields: {
			0: { FormMultiActive: { fieldName: 'merchant_id', fieldLabel: 'Merchant Name' } },
			1: { FormMultiActive: { fieldName: 'user', fieldLabel: 'User' } }
		}
	};

	return (
		<>
			{modalAdd && <Modal formConfig={formConfig} />} {modalEdit && <Modal formConfig={formConfigEdit} />}
			{modalDelete && <Modal formConfig={formConfigDelete} />}  {modalUsers && <Modal formConfig={formConfigUsers} />}
			{/* {modalLinks && <Modal formConfig={formConfigLinks} />} <style>{`.MuiInputBase-root {max-height: 46px;} .icon:hover {color: blue;cursor: pointer;}`}</style> */}

			{isLoading && <LoadingOverlay />}

			<Box sx={{ overflowX: 'hidden' }}>
				<Row style={{ height: 'calc(100vh - 90px)' }}>
					<Col lg={12} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
						<Row xs={1} md={10} lg={10} style={{ position: 'sticky', top: '0', zIndex: 2, marginBottom: '10px' }}>
							<Paper style={{ marginBottom: '10px' }}>
								<Row className="p-3 align-items-center">
									<Col lg={8}> <h2 className="text-center" style={{ marginTop: '0.5rem' }}> {`${process.env.REACT_APP_WEB_NAME}`} MERCHANTS </h2> </Col>
									<Col lg={4} className="text-end">
										<TextField style={{ marginRight: '10px' }} value={searchTerm} onChange={handleSearch} placeholder="Find..." />
										{usr?.is_superuser ? <Button variant="outline-success" size="md" onClick={() => setModalAdd(true)}> 	<AiOutlinePlusSquare className="icon" size="2em" /> </Button> : false}
									</Col>
								</Row>
							</Paper>
						</Row>
						<div className="scroll-container" style={{ overflowY: 'auto', flex: 1, margin: '15px', overflowX: 'hidden' }}>
							<Row xs={1} md={4} className="g-3 p-1">
								{dataInfo.map((data) => (
									<Col className="align-self-stretch" key={data.merchant_id} style={{ paddingLeft: '-0.5rem' }}>
										<Card className="d-flex h-100">
											<div className={`pb-2 w-100 rounded-top ${data.active === false ? 'bg-danger' : 'bg-success'}`} />
											<Card.Header> {usr?.is_superuser ?
												<Row className="text-center">
													<Col lg={6} xs={6} className="border-end border-bottom"> <AiOutlineDelete className="icon" onClick={() => onDelete(data)} /> </Col>
													<Col lg={6} xs={6} className="border-bottom" > 	<AiOutlineEdit className="icon" onClick={() => onEdit(data)} /> </Col>
												</Row> : false}

												<Row className="text-center">
													<Col lg={6} xs={6} className="border-end "> <FaUserCheck className="icon" onClick={() => onUsers(data)} /> </Col>
													{/* {data.payment_method[0]?.payment_method_id?.includes(9) ? // : false} */}
													{/* <Col lg={6} xs={6} > <FaLink className="icon" onClick={() => onLink(data)} /> </Col>  */}

												</Row>
											</Card.Header>

											<Link className="text-decoration-none" to={String(data.merchant_id)}>
												<Card.Img className="rounded mx-auto p-2" style={{ display: 'block', height: '170px', objectFit: 'cover' }} thumbnail src={data.image} />
											</Link>

											<Card.Body className="d-flex flex-column">
												<Link className="text-decoration-none" to={String(data.merchant_id)}> <Card.Title className="mb-auto p-2 bd-highlight text-center">{data.merchant_name}</Card.Title> </Link>
												<Card.Text className="mb-auto p-2 bd-highlight text-center"> <small className="text-muted">{data.merchant_description}</small> </Card.Text>
											</Card.Body>

											<Card.Footer className="text-center"> <small className="text-muted">{new Date(data.created_date).toDateString()}</small></Card.Footer>
										</Card>
									</Col>
								))}
							</Row>
						</div>
					</Col>
				</Row>
			</Box>
		</>
	);
}

export default Merchants;
