export const changeTextFillColorToWhite = (colorTheme, setColorTheme) => {

    const elementsToStyle = [
        { selectors: ['.MuiPaper-root'], color: 'white', bgColor: '#2D3336' },
        { selectors: ['.bd2remove'], color: 'white', bgColor: '' },
        { selectors: ['input'], color: 'white', bgColor: '' },
        { selectors: ['li'], color: 'white', bgColor: '' },
        { selectors: ['h2', 'h3', 'h4'], color: 'white', bgColor: '' },
    ];

    const textColor = colorTheme === 'WHITE' ? '#004955' : 'white';
    const bgColor = colorTheme === 'WHITE' ? '' : '#2D3336';
    const textFillColor = colorTheme === 'WHITE' ? '#86898B' : 'white';

    elementsToStyle.forEach(({ selectors }) => {
        const elements = document.querySelectorAll(selectors.join(','));
        elements.forEach(element => {
            element.style.color = textColor;
            element.style.backgroundColor = bgColor;
            if (selectors.includes('input')) {
                element.style.webkitTextFillColor = textFillColor;
            }
            if (selectors.includes('bd2remove') && (colorTheme === 'WHITE')) {
                element.style.backgroundColor = 'rgb(241, 241, 241)';
            }
        });
    });

    setColorTheme(colorTheme === 'WHITE' ? 'BLACK' : 'WHITE');
};
