import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify'
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { TextField, Paper, Box } from "@mui/material";
import { AiOutlinePlusSquare, AiOutlineBank } from 'react-icons/ai';
import PaymentTable from "./TablePayments"
import { Modal as Modal1 } from 'react-bootstrap';
import Modal from '../../Components/Modal/Modals/Modal';
import { Table, TableBody, TableHead, TableRow  } from "@mui/material";
import { formatCreationDate, getMonthName, CenteredTableCell, ScrollableTableContainer, LoadingOverlay} from '../Common/Common';
import { useNavigate } from "react-router-dom";

const ModalDisplayBalance = ({ modalIsOpen, closeModal, data }) => {
	// const totalAmount = settlementsMX.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.amount), 0);
	const [selectedTab, setSelectedTab] = useState('SET')

	const onSelectTab = (tab) => {
		setSelectedTab(tab)
	}

	return (

		<Modal1
			show={modalIsOpen}
			onHide={closeModal}
			centered
			className='modal-display-balance'
		>
			<Modal1.Header closeButton>
				Merchant Settlement History
			</Modal1.Header>

			<Modal1.Body className='p-4'>
				<ScrollableTableContainer component={Paper} style={{ maxHeight: '55vh', boxShadow: 'none' }}>

					<Table aria-label="collapsible table">
						<TableHead style={{ position: "sticky", top: 0, zIndex: 1 }} component={Paper} >
							<TableRow>
								<CenteredTableCell>Transaction Type</CenteredTableCell>
								<CenteredTableCell>Date</CenteredTableCell>
								<CenteredTableCell>Amount</CenteredTableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							<>
								<CenteredTableCell colSpan={4}><strong>TOTALS</strong></CenteredTableCell>

								<TableRow>
									<CenteredTableCell> <strong> TOTAL SETTLEMENT </strong>  </CenteredTableCell>
									<CenteredTableCell>  <strong> {formatCreationDate()} </strong></CenteredTableCell>
									<CenteredTableCell>  <strong > {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARG' }).format(data.total_settlement)} </strong></CenteredTableCell>
								</TableRow>

								<TableRow>
									<CenteredTableCell> <strong> TOTAL PROCESSED </strong>  </CenteredTableCell>
									<CenteredTableCell>  <strong> {formatCreationDate()} </strong></CenteredTableCell>
									<CenteredTableCell>  <strong>  {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARG' }).format(data.sum)} </strong></CenteredTableCell>
								</TableRow>

								<TableRow>
									<CenteredTableCell> <strong> AVAILABLE BALANCE </strong>  </CenteredTableCell>
									<CenteredTableCell>  <strong> {formatCreationDate()} </strong></CenteredTableCell>
									<CenteredTableCell>  <strong>  {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARG' }).format(data.sum - data.total_settlement)} </strong></CenteredTableCell>
								</TableRow>

								<TableRow>
									<CenteredTableCell colSpan={4} >
										<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
											<span className='transition settlementsTabs' style={{ borderRight: '1px solid gray', color: selectedTab === 'SET' ? 'black' : 'gray' }} onClick={() => { onSelectTab('SET') }} >
												<strong>SETTLEMENTS HISTORY</strong>
											</span>
											<span className='transition settlementsTabs' style={{ color: selectedTab === 'TRX' ? 'black' : 'gray' }} onClick={(e) => { onSelectTab('TRX') }}>
												<strong>TRANSACTIONS HISTORY</strong>
											</span>
										</div>
									</CenteredTableCell>

								</TableRow>
								{selectedTab === 'SET' ?
									<>
										{data.settlement_data.map((s) => (
											<TableRow className='transition'>
												<CenteredTableCell> {s.merchant_id__merchant_name} PAY OUT </CenteredTableCell>
												<CenteredTableCell> {formatCreationDate(s.created_date)} </CenteredTableCell>
												<CenteredTableCell>
													{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARG' }).format(s.amount)}
												</CenteredTableCell>
											</TableRow>
										))}
									</>
									:
									<>
										{data.grouped_data.map((s) => (
											<TableRow className='transition'>
												<CenteredTableCell> {s.merchant_id__merchant_name} PAY IN </CenteredTableCell>
												<CenteredTableCell> {getMonthName(s.month)} </CenteredTableCell>
												<CenteredTableCell>
													{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARG' }).format(s.total_amount_ok)}
												</CenteredTableCell>
											</TableRow>
										))}
									</>
								}
							</>
						</TableBody >
					</Table >

				</ScrollableTableContainer>
			</Modal1.Body>
		</Modal1>
	);
};

function Payments() {

	const usr = JSON.parse(`${localStorage.getItem('userObj')}`);
	const pathArray = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
	const isProvidersPAth = window.location.pathname.split('/')[window.location.pathname.split('/').length - 3];
	const location = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2]

	const [isLoading, setIsLoading] = useState(true);
	const [ProvideName, setProvideName] = useState('');
	const [merchantName, setMerchantName] = useState('');
	const [payments, setPayments] = useState([]);
	const [methodName, setMethodName] = useState('');
	const [searchTerm, setSearchTerm] = useState('');
	const [modalBalance, setModalBalance] = useState(false)
	const [modalAddProject, setModalAddPayment] = useState(false);
	const navigate = useNavigate();

	const config = {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `JWT ${localStorage.getItem('access')}`,
		},
	};

	useEffect(() => {

		const fetchData = async () => {
			const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

			let linkType;

			if (pathArray === 'pagofacil') {
				setMethodName('PAGOFACIL')
				setProvideName('PAGOFACIL')
				if (usr?.is_superuser) {
					linkType = `ar/${pathArray}${isProvidersPAth === 'merchants' ? `/?m=${location}` : '/'}`
				} else {
					linkType = `ar/${pathArray}/`
				}
			}
			if (pathArray === 'rapipago') {
				setMethodName('RAPIPAGO')
				setProvideName('RAPIPAGO')
				if (usr?.is_superuser) {
					linkType = `ar/${pathArray}${isProvidersPAth === 'merchants' ? `/?m=${location}` : '/'}`
				} else {
					linkType = `ar/${pathArray}/`
				}
			}
			if (pathArray === 'qr') {
				setMethodName('QR')
				setProvideName('QR')
				if (usr?.is_superuser) {
					linkType = `ar/${pathArray}${isProvidersPAth === 'merchants' ? `/?m=${location}` : '/'}`
				} else {
					linkType = `ar/${pathArray}/`
				}
			}

			try {
				const [paymentsRes] = await Promise.all(
					[axios.get(`${process.env.REACT_APP_API_URL}/v1/merchants/${linkType}`, config),]
				);

				if (paymentsRes.data.results.length === 0) {
					return;
				}

				const pmt = paymentsRes.data
				pmt['base_url'] = linkType

				setPayments(pmt);
				setMerchantName(paymentsRes.data.merchant_name || "")
				await delay(500);
				setIsLoading(false);
			}
			catch (error) {
				console.log(error);
				toast.error(`There is no available data to show at the moment`, { theme: 'dark' });
				const currentLocation = window.location.pathname.split('/')
				const removeLocation = currentLocation.splice(-1)
				await delay(2000);
				// window.location.replace(currentLocation.join('/'))
				navigate(currentLocation.join('/'));
			}
		};
		fetchData();

	}, []);

	const createFields = {
		0: {
			FormField: {
				fieldName: 'account_number',
				fieldLabel: 'Account number',
			}
		},
		1: {
			FormField: {
				fieldName: 'amount',
				fieldLabel: 'amount',
			}
		},
	};

	const SaveData = (formData) => {
		axios.post(`${process.env.REACT_APP_API_URL}/v1/payment-methods/settlements/`, formData, config).then((res) => {
			setModalAddPayment(false)
			toast.success(`Pay Out added to the Database`, { theme: 'dark' });

		})
	};

	const formConfig = {
		formTitle: 'Create Settlement',
		setModal: setModalAddPayment,
		initialData: { payment_methods: pathArray, merchant_id: location, },
		config: config,
		SaveData: SaveData,
		createFields: createFields
	};

	return (
		<>
			{modalBalance && <ModalDisplayBalance modalIsOpen={modalBalance} closeModal={setModalBalance} data={payments} isProvidersPAth={isProvidersPAth} />}
			{modalAddProject && <Modal formConfig={formConfig} />}

			<style>{`.MuiInputBase-root {max-height: 46px;} .settlementsTabs:hover {transform: scale(1.2)} .transition {transition: all 0.3s ease;} .settlementsTabs {width: 100%; cursor: pointer}`}</style>
			<style> {`.icon:hover {color: blue;cursor: pointer;} `} </style>
			<Box sx={{ overflowX: 'hidden' }}>
				<Row style={{ height: 'calc(100vh - 85px)' }}>
					<Col lg={12} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
						<Row xs={1} md={10} lg={10} style={{ position: 'sticky', top: '0', zIndex: 2, marginBottom: '10px' }}>
							<Paper style={{ marginBottom: '10px' }}>
								<Row className='p-3 align-items-center'>
									<Col lg={6}>
										<h2 className="text-center" style={{ marginTop: '0.5rem' }}>{`${process.env.REACT_APP_WEB_NAME}`}: {isProvidersPAth === 'merchants' ? merchantName : ProvideName} TRANSACTIONS</h2>
									</Col>

									<Col lg={6} className="text-end">
										<TextField style={{ marginRight: '10px' }} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Find..." name='searchField' />
										{<Button variant='outline-success' size="md" style={{ marginRight: '10px' }} onClick={() => { setModalBalance(true); }}>
											<AiOutlineBank className='icon' size='2em' style={{ marginRight: '10px' }} /> Balance
										</Button>}
										{(usr.is_superuser && isProvidersPAth === 'merchants') && <Button variant='outline-success' size="md" style={{ marginRight: '10px' }} onClick={() => { setModalAddPayment(true); }}>
											<AiOutlinePlusSquare className='icon' size='2em' />
										</Button>}
									</Col>
								</Row>
							</Paper>
						</Row>

						{isLoading ? (<LoadingOverlay />)
							: (
								<PaymentTable
									users={payments}
									provider={methodName}
									handleSearch={setSearchTerm}
									searchTerm={searchTerm}
								/>
							)}
					</Col>
				</Row>
			</Box>
		</>
	);
};

export default Payments;




