import icon1 from './uil_edit-alt.png';
import icon2 from './uil_card-atm.png';
import icon3 from './uil_sync.png';
import icon4 from './uil_check.png';
import icon5 from './uil_moon.png';
import icon6 from './vectorcheck.png';
import icon7 from './uil_sun.png';
import CopyIcon from './copy_icon.png';
import spinnerArrow from './uil_sync.png';


const WebAssets = {
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  icon7,
  CopyIcon,
  spinnerArrow
};

export default WebAssets;



