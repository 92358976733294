import React from 'react';
import { Typography, CardContent, Grid, Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { titleStyle } from '../Common/styles';
import { VoucherField } from './EmailFieldAr';
import { EmailField } from './EmailFieldAr';


export const EmailCollectForm = ({ DictConf, merchant }) => {
    return (
        <>
            <CardContent style={{padding: '40px 30px'}}>
                
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', marginBottom: '10px' }}>
                    <Grid item xs={12} >
                        <Typography variant="h3" gutterBottom style={titleStyle}> Completa el Formulario de Pago </Typography>
                    </Grid>
                </Box>

                <form onSubmit={DictConf.onEmailCollect}>
                    <Grid container spacing={isMobile ? 0 : 1} style={{ justifyContent: 'center' }} >
                        <EmailField DictConf={DictConf} />
                    </Grid>
                </form>
            </CardContent>
        </>
    )
}


export const VocuherCollectForm = ({ DictConf, merchant }) => {

    return (
        <>
            <CardContent style={{padding: '40px 30px'}}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', marginBottom: '30px' }}>
                    <Grid item xs={12} >
                        <Typography variant="h3" gutterBottom style={titleStyle}> Ingresa un Nº de eVoucher </Typography>
                    </Grid>
                </Box>

                <form onSubmit={DictConf.onEmailCollect}>
                    <Grid container spacing={isMobile ? 0 : 2} style={{ justifyContent: 'center' }} >
                        <VoucherField DictConf={DictConf} />
                    </Grid>
                </form>
            </CardContent>
        </>
    )
}
