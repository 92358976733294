import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import Login from './Containers/Auth/Login';
import Activate from './Containers/Auth/Activate';
import ResetPassword from './Containers/Auth/ResetPassword';
import ResetPasswordConfirm from './Containers/Auth/ResetPasswordConfirm';
import Profile from './Containers/User/Profile';
import Layout from './Hocs/Layout';
import Providers from './Containers/Providers/Providers';
import PaymentMethods from './Containers/PaymentMethods/PaymentMethods';
import MerchantsPaymentMethods from './Containers/PaymentMethods/MerchantsPaymentMethods';
import Merchants from './Containers/Merchants/Merchants';
import Payments from './Containers/Payments/Payments';
import WebFormPar from './Integration/RixusAr/webFormPar';
import WebFormEVoucher from './Integration/RixusAr/webFormVoucher';
import WebFormDlocalGo from './Integration/DlocalGo/WebFormDlocalGo';
import WebFormVoucherDlocalGo from './Integration/DlocalGo/WebFormVoucherDlocalGo';
import store from './store';


const WEB_COUNTRY = `${process.env.REACT_APP_WEB_COUNTRY}`

const App = () => (

	<Provider store={store}>
		<Router>
			<Layout>
				<AnimatePresence >
					<Routes>
						<Route
							path="/evoucher-web/"
							element={
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5 }}
								>
									<Merchants />
								</motion.div>
							}
						/>
						<Route
							path="/evoucher-web/login"
							element={
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5 }}
								>
									<Login />
								</motion.div>
							}
						/>
						<Route
							path="/evoucher-web/reset-password"
							element={
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5 }}
								>
									<ResetPassword />
								</motion.div>
							}
						/>
						<Route
							path="/evoucher-web/password/reset/confirm/:uid/:token"
							element={
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5 }}
								>
									<ResetPasswordConfirm />
								</motion.div>
							}
						/>
						<Route
							path="/evoucher-web/activate/:uid/:token"
							element={
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5 }}
								>
									<Activate />
								</motion.div>
							}
						/>
						<Route
							path="/evoucher-web/users"
							element={
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5 }}
								>
									<Profile />
								</motion.div>
							}
						/>
						<Route
							path="/evoucher-web/merchants"
							element={
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5 }}
								>
									<Merchants />
								</motion.div>
							}
						/>
						<Route
							path="/evoucher-web/merchants/:id"
							element={
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5 }}
								>
									<MerchantsPaymentMethods />
								</motion.div>
							}
						/>
						<Route
							path="/evoucher-web/merchants/:id/:id"
							element={
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5 }}
								>
									<Payments />
								</motion.div>
							}
						/>
						<Route
							path="/evoucher-web/providers"
							element={
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5 }}
								>
									<Providers />
								</motion.div>
							}
						/>

						<Route
							path="/evoucher-web/providers/:id"
							element={
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5 }}
								>
									<PaymentMethods />
								</motion.div>
							}
						/>
						<Route
							path="/evoucher-web/providers/:id/:id"
							element={
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5 }}
								>
									<Payments />
								</motion.div>
							}
						/>

						

						{(WEB_COUNTRY === 'ar' || WEB_COUNTRY === 'all') && (
							<>
								<Route
									path="/evoucher-web/pay/formpgar/:param1"
									element={<WebFormPar />}
								/>

								<Route
									path="/evoucher-web/redeem/formpgar/:param1/:param2?"
									element={<WebFormEVoucher />}
								/>
								
							</>
						)}

						{(WEB_COUNTRY === 'ar' || WEB_COUNTRY === 'all') && (
							<>
								<Route
									path="/evoucher-web/pay/:param1/:param2"
									element={<WebFormPar />}
								/>

								<Route
									path="/evoucher-web/redeem/:param1/:param2"
									element={<WebFormEVoucher />}
								/>
								<Route
									path="/evoucher-web/pay/dlocalgo/:param1/:param2"
									element={<WebFormDlocalGo />}
								/>
								<Route
									path="/evoucher-web/redeem/dlocalgo/:param1/:param2"
									element={<WebFormVoucherDlocalGo />}
								/>
							</>
						)}

					</Routes>
				</AnimatePresence>
			</Layout>
		</Router>
	</Provider>
);

export default App;
