

import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset_password_confirm } from '../../Actions/auth';
import { TextField, Button, Typography, Card, CardContent, Grid, Box } from '@mui/material';
import backgroundImage from './/bg.jpg';
import logo from './sampleAvatar.png';

const ResetPasswordConfirm = ({ reset_password_confirm }) => {

    const params = useParams();

    const [requestSent, setRequestSent] = useState(false);

    const [formData, setFormData] = useState({
        new_password: '',
        re_new_password: ''
    });

    const { new_password, re_new_password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        const uid = params.uid;
        const token = params.token;
        reset_password_confirm(uid, token, new_password, re_new_password);
        setRequestSent(true);
    };

    if (requestSent) {
        return <Navigate replace to="/login" />
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
                <Card sx={{ maxWidth: 400 }}>
                    <CardContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
                            <img src={logo} alt="Logo" style={{ width: '150px' }} />
                            <br />
                            <Typography variant="h5" gutterBottom>
                                Choose a new Password
                            </Typography>
                        </Box>
                        <form onSubmit={onSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        className="form-control"
                                        type="password"
                                        placeholder="New Password"
                                        name="new_password"
                                        value={new_password}
                                        onChange={e => onChange(e)}
                                        minLength="6"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        className="form-control"
                                        type="password"
                                        placeholder="Confirm New Password"
                                        name="re_new_password"
                                        value={re_new_password}
                                        onChange={e => onChange(e)}
                                        minLength="6"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="primary" fullWidth type="submit">
                                        Request Password Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Box>
        </>

    );
};


export default connect(null, { reset_password_confirm })(ResetPasswordConfirm);



