import { isMobile } from "react-device-detect"

// .ConfirmToast-module_modal-content__HQS46 {top: 40%; left: ${isMobile ? '0%' : '10%'} }  

export const cardTransitionStyles = () => {

    return `
        fieldset { border-radius: 15px !important; border-color: #B9C0C7 !important}
        label, legend { color: #B9C0C7 !important }

        .MuiCircularProgress-indeterminate {
            animation: MuiCircularProgress-keyframes-circular-rotate 1.4s linear infinite;
        }

        @-webkit-keyframes MuiCircularProgress-keyframes-circular-rotate {
            0% {
            transform-origin: 50% 50%;
            }
            100% {
            transform: rotate(360deg);
            }
        }
        
        .transition {
            transition: all 0.3s ease; /* Adjust the duration and timing function as needed */
        }
            
        li {
            position: relative;
            font-family: Nunito Sans;
            font-size: 16px;
            font-weight: 200;
            line-height: 22.4px;
            text-align: left;
            color: #004955;
            padding: 10px 12px;
        }
            
        li::before {
            content: "";
            position: absolute;
            left: -27px;
            top: 8px;
            background-color: #D8FEE6;
            z-index: -1;
            display: inline-block;
            width: 29px;
            height: 29px;
            border-radius: 4px;
        }

        li::marker {
            font-weight: 700;
            line-height: 22.4px;
            font-size: 16px;
            color: #004955;
            font-family: Nunito Sans;
        }

        
        .ConfirmToast-module_modal__4lDAi {width: 100%; height: 100%; position: absolute;  }  
        .ConfirmToast-module_modal-content__HQS46 {top: 0; left: 0; position: relative;  }  
        
        .animated-card {transition: all 2.0s ease; }  
        .show {width: 270px; height: 50px;}
        .hide {width: 50px; height: 50px; overflow: hidden;}
        .showT {width: 100%; }
        .show2 {width: 100%; }
        .hide2 {width: 0%; overflow: hidden; }
        .hideT {width: 96%; overflow: hidden;}

        .emailCollection {
            transition: transform 0.5s ease-in-out;
            transform: translateX(0);
        }

        .initialFields {
            transition: transform 0.5s ease-in-out;
            transform: translateX(0);
        }

        .waitingConfirmation {
            transition: transform 0.5s ease-in-out;
            transform: translateX(0);
        }

        .hideWaitingConfirmation {
            transform: translateX(-100%);
            height: 0px;
        }

        .hideEmailCollect {
            transform: translateX(-100%);
            height: 0px;
        }

        .hideInitialFields {
            transform: translateX(-100%);
            height: 0px;
        }

        .showEmailCollect {
            transform: translateX(0);
            height: 100%;
            overflow-y:auto
        }

        .showWaitingConfirmation {
            transform: translateX(0);
            height: 100%;
            overflow-y: auto
        }

        .showInitialFields {
            transform: translateX(0);
            height: 100%;
            overflow-y: auto
        }
    `
    ;
};

