import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import 'react-toastify/dist/ReactToastify.css';

import '@fontsource/nunito-sans/700.css'
import '@fontsource/nunito-sans/400.css'
import '@fontsource/nunito-sans/200.css'

const root = ReactDOM.createRoot(document.getElementById('app'));
document.getElementsByTagName('title')[0].innerText = '💰 EVoucher'

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
