import React from 'react';
import { Typography, CardContent, Grid, Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { titleStyle, } from '../Common/styles';
import { InitialFields, } from './InitialFieldsAr';

export const InitialForm = ({ DictConf, merchant, methodPayment }) => {
    return (
        <>
            <CardContent style={{ padding: '40px 30px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', marginBottom: '20px' }}>
                    <Grid item xs={12} >
                        <Typography variant="h3" gutterBottom style={titleStyle}>
                            {DictConf.isError === true ? "Oops... Algo no ha salido bien..." :
                            (methodPayment === 'qr' ?
                                `Paga tu eVoucher por un valor de $${DictConf?.formData?.amount} usando Código QR` :
                                `Paga tu eVoucher por un valor de $${DictConf?.formData?.amount} en tu sucursal de ${methodPayment?.toUpperCase()} mas cercana.`)
                            } 
                            </Typography>
                    </Grid>
                </Box>

                <form onSubmit={DictConf.onSubmit}>
                    <Grid container spacing={isMobile ? 0 : 2}>
                        <InitialFields DictConf={DictConf} merchant={merchant} methodPayment={methodPayment} />
                    </Grid>
                </form>
            </CardContent>
        </>
    )
}
