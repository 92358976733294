

import React, { useState } from 'react';
import { Link, NavLink, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../Actions/auth';
import { Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';
import avatar from './sampleAvatar.png';
import './Styles.css'

import { isMobile } from 'react-device-detect';

const MyNavbar = ({ logout, title }) => {
    const usr = JSON.parse(`${localStorage.getItem('userObj')}`);

    const [isDarkTheme, setIsDarkTheme] = useState(true);
    const themeName = isDarkTheme ? 'dark' : 'light';
    const [redirect, setRedirect] = useState(false);

    const logoutUser = () => {
        logout();
        setRedirect(true);
        return <Navigate to='/evoucher-web/login' />
    };

    return (
        <Navbar className="main-header" expand="lg" bg={themeName} variant={themeName} style={{ backgroundColor: 'lightblue !important' }}>
            <h2>{title}</h2>
            <Navbar.Brand title={`${process.env.REACT_APP_WEB_NAME}`} href="/evoucher-web/" className="d-block d-lg-none me-auto mr-3">
                <Link to="/" className="navbar-brand" style={{ color: 'white', paddingLeft: '20px' }}>
                    <span className="text-primary">{`${process.env.REACT_APP_WEB_NAME}`}</span>
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse className="justify-content-end">
                <Nav>
                    <div className="d-flex justify-content-center">
                        <NavDropdown
                            className="dropdown-left"
                            title={<Image src={avatar} roundedCircle thumbnail style={{ height: '40px' }} />}
                            id="basic-nav-dropdown"
                        >
                            <div style={{ padding: '10px' }}>
                                {/* <NavDropdown.Divider /> */}
                                <NavLink to="/Login" onClick={logoutUser} style={{ textDecoration: 'none' }}>
                                    <li style={{ color: 'white', textDecoration: 'none', listStyleType: 'none', cursor: 'pointer', transition: 'filter 0.3s' }} className="nav-link-item">
                                        Logout
                                    </li>
                                </NavLink>
                            </div>
                        </NavDropdown>
                    </div>

                    {isMobile ?

                        <>
                            {usr.is_superuser === true ?


                                <div className="d-flex justify-content-center">

                                    <div style={{ padding: '10px' }}>

                                        <NavLink to="/evoucher-web/evouchers" style={{ textDecoration: 'none' }}>
                                            <li style={{ color: 'white', textDecoration: 'none', listStyleType: 'none', cursor: 'pointer', transition: 'filter 0.3s' }} className="nav-link-item">
                                                Evouchers
                                            </li>
                                        </NavLink>
                                        <NavDropdown.Divider />


                                        <NavLink to="/evoucher-web/providers" style={{ textDecoration: 'none' }}>
                                            <li style={{ color: 'white', textDecoration: 'none', listStyleType: 'none', cursor: 'pointer', transition: 'filter 0.3s' }} className="nav-link-item">
                                                Providers
                                            </li>
                                        </NavLink>

                                        <NavDropdown.Divider />

                                        <NavLink to="/evoucher-web/merchants" style={{ textDecoration: 'none' }}>
                                            <li style={{ color: 'white', textDecoration: 'none', listStyleType: 'none', cursor: 'pointer', transition: 'filter 0.3s' }} className="nav-link-item">
                                                Merchants
                                            </li>
                                        </NavLink>

                                        <NavDropdown.Divider />

                                        <NavLink to="/evoucher-web/users" style={{ textDecoration: 'none' }}>
                                            <li style={{ color: 'white', textDecoration: 'none', listStyleType: 'none', cursor: 'pointer', transition: 'filter 0.3s' }} className="nav-link-item">
                                                Users
                                            </li>
                                        </NavLink>

                                    </div>
                                </div>
                                :
                                <div className="d-flex justify-content-center">

                                    <div style={{ padding: '10px' }}>



                                    </div>
                                </div>}
                        </>
                        :
                        false}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default connect(null, { logout })(MyNavbar);

