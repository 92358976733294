// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link-item:hover {
  color: rgb(49, 108, 244) !important;
  text-decoration: underline !important;
}

.nav-link-item:hover::after {
  content: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Navbar/Styles.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,qCAAqC;AACvC;;AAEA;EACE,aAAa;AACf","sourcesContent":[".nav-link-item:hover {\n  color: rgb(49, 108, 244) !important;\n  text-decoration: underline !important;\n}\n\n.nav-link-item:hover::after {\n  content: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
