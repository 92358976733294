// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.main-container {
    padding-top: 0px !important;
}

.container-fluid {
    padding-right: 0px !important;
    padding-left: 0px !important;

}

#loader {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 100000;
    background-color: rgba(200, 200, 200, 0.7);
}

#loader>div {
    position: absolute;
    top: 400px;
    left: calc(50% - 32px);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    perspective: 800px;
}
`, "",{"version":3,"sources":["webpack://./src/Hocs/Style.css"],"names":[],"mappings":";AACA;IACI,2BAA2B;AAC/B;;AAEA;IACI,6BAA6B;IAC7B,4BAA4B;;AAEhC;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;IACb,eAAe;IACf,0CAA0C;AAC9C;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":["\n.main-container {\n    padding-top: 0px !important;\n}\n\n.container-fluid {\n    padding-right: 0px !important;\n    padding-left: 0px !important;\n\n}\n\n#loader {\n    display: block;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100vh;\n    z-index: 100000;\n    background-color: rgba(200, 200, 200, 0.7);\n}\n\n#loader>div {\n    position: absolute;\n    top: 400px;\n    left: calc(50% - 32px);\n    width: 64px;\n    height: 64px;\n    border-radius: 50%;\n    perspective: 800px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
