import React from 'react';
import { Typography, CardContent, Grid, Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { ConfirmationFields, ConfirmationFieldsAr, VoucherFieldsAr } from './ConfirmationFields';
import { titleStyle, } from './styles';

export const WaitingForm = ({ DictConf, merchant }) => {
    return (
        <>
            <CardContent style={{ padding: '40px 30px' }}>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', marginBottom: '20px' }}>
                    <Grid item xs={12} style={{marginBottom: '10px'}} >
                        <Typography variant="h3" id='textVerif' gutterBottom style={titleStyle}>Estamos Verificando el pago</Typography>
                    </Grid>
                </Box>
                <form onSubmit={DictConf.onSubmit}>
                    <Grid container spacing={isMobile ? 0 : 3}>
                        <ConfirmationFields DictConf={DictConf} merchant={merchant} />
                    </Grid>
                </form>
            </CardContent>
        </>
    )
}


export const WaitingFormAr = ({ DictConf, merchant }) => {

    return (
        <>
            <CardContent style={{ padding: '40px 30px' }}>

                {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', marginBottom: '20px' }}>
                    <Grid item xs={12} >
                        <Typography variant="h3" id='textVerif' gutterBottom style={titleStyle}>Estamos Verificando el pago</Typography>
                    </Grid>
                </Box> */}

                <form onSubmit={DictConf.onSubmit}>
                    <Grid container spacing={isMobile ? 0 : 3} style={{marginTop: '0px', justifyContent: 'center'}}>
                        <ConfirmationFieldsAr DictConf={DictConf} merchant={merchant} />
                    </Grid>
                </form>
                
            </CardContent>
        </>
    )
}

export const VoucherFormAr = ({ DictConf, merchant }) => {

    return (
        <>
            <CardContent style={{ padding: '40px 30px' }}>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', marginBottom: '20px' }} id='textVerif' style={{display: 'none'}}>
                    <Grid item xs={12} >
                        <Typography variant="h3" gutterBottom style={titleStyle}>Tu eVoucher ha sido validado</Typography>
                    </Grid>
                </Box>
                
                <form onSubmit={DictConf.onSubmit}>
                    <Grid container spacing={isMobile ? 0 : 3} style={{justifyContent: 'center'}}>
                        <VoucherFieldsAr DictConf={DictConf} merchant={merchant} />
                    </Grid>
                </form>
            </CardContent>
        </>
    )
}
